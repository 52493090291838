import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Firebase
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  arrayUnion,
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import {
  useAnalytics,
  useUser,
  useFirestore,
  useFirestoreDocDataOnce,
} from "reactfire";

// Atoms
import { Block, Modal } from "react-bulma-components";
import Header from "../../components/atoms/Header";
import Body from "../../components/atoms/Body";
import Button from "../../components/atoms/Button";
import LoadingSmall from "../../components/atoms/LoadingSmall";

// Molecules
import FormField from "../../components/molecules/FormField";
import FormTextarea from "../../components/molecules/FormTextarea";
import {GroupsCreateOnBoarding} from "../onboarding/groups/GroupCreateOnBoarding";

type Props = {
  openModal: boolean;
  onCreateGroup: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClose: () => void;
};

const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const ListModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  // Initialize Firestore
  const firestore = useFirestore();
  const analytics = useAnalytics();

  const { data } = useUser();
  const userRef = doc(firestore, "users", data!.uid);
  const groupRef = collection(firestore, "groups");

  const { data: user } = useFirestoreDocDataOnce<any>(userRef);

  useEffect(() => {
    setGroupId(uuidv4());
  }, []);

  const onCreateGroup = async () => {
    setLoading(true);

    const createdAt = new Date();

    let creatorObject = {
      id: data!.uid,
      name: user.name,
      profileUrl: user.profileUrl,
    };

    let memberObject = {
      id: data!.uid,
      joinedDate: createdAt,
    };

    try {
      // Log event for creating a new group
      logEvent(analytics, "create_group", {
        user: data!.uid,
      });

      // Set the data for the new group document
      let specificGroupRef = doc(groupRef, groupId);
      await setDoc(specificGroupRef, {
        creator: creatorObject,
        name: name,
        description: description,
        createdAt: createdAt,
        numberOfMembers: 1,
        members: [memberObject],
        sharedRecipes: [],
        numberOfPosts: 0,
      });

      // Add group ID to joined groups array for the user
      await updateDoc(userRef, {
        joinedGroups: arrayUnion(groupId),
      });

      setLoading(false);
      props.onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <GroupsCreateOnBoarding run={true}/>
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Start a new group
          </Header>
          <Body margin={"0 0 1em 0"}>
            Enter a name and description for your group
          </Body>
          <hr />
          <FormField
            label={"What's your group name?"}
            value={name}
            placeholder={"Group name"}
            onChange={(e: any) => setName(e.target.value)}
          />
          <FormTextarea
            label={"Give your group a quick description"}
            value={description}
            placeholder={"Group description"}
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            {loading === true ? (
              <LoadingSmall />
            ) : (
              <Button primary onClick={() => onCreateGroup()}>
                Save
              </Button>
            )}
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default ListModal;
