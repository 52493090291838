import styled, { keyframes } from "styled-components";
import { DARK_GREEN } from "../../styles/colors";
import Body from "./Body";

const FullPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RippleInteraction = keyframes`
0% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 1;
}
100% {
  top: 0px;
  left: 0px;
  width: 72px;
  height: 72px;
  opacity: 0;
}
`;

const Ripple = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid ${DARK_GREEN};
    opacity: 1;
    border-radius: 50%;
    animation: ${RippleInteraction} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;

const Loading = () => {
  return (
    <FullPage>
      <Ripple>
        <div></div>
        <div></div>
      </Ripple>
      <Body color={DARK_GREEN}>Loading...</Body>
    </FullPage>
  );
};

export default Loading;
