import { BrowserRouter, Switch } from "react-router-dom";
import { routes, RouteWithSubRoutes } from "./route-config";
import { createGlobalStyle } from "styled-components";
import { Toaster } from "react-hot-toast";

// Components
import TopNav from "./components/organisms/TopNav";

// Styles
import { DARK, GREEN_INPUT_BORDER } from "./styles/colors";

// Firebase import
import {
  AuthProvider,
  AnalyticsProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
  useInitPerformance,
} from "reactfire";
import { getAuth } from "@firebase/auth";
import { getAnalytics } from "@firebase/analytics";
import { getStorage } from "@firebase/storage";
import { getFirestore } from "@firebase/firestore";
import ScrollToTop from "./components/organisms/ScrollToTop";

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 16px;
    color: ${DARK};
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  hr {
    height: 1px;
    background-color: ${GREEN_INPUT_BORDER};
    margin: 1em 0;
  }
`;

function App() {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const analytics = getAnalytics(firebaseApp);
  const storage = getStorage(firebaseApp);

  // Firestore
  const firestoreInstance = getFirestore(firebaseApp);

  // Performance Logging
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import("firebase/performance");
    return getPerformance(firebaseApp);
  });

  return (
      <div id="root">
      <AuthProvider sdk={auth}>
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <FirestoreProvider sdk={firestoreInstance}>
        <AnalyticsProvider sdk={analytics}>
          <StorageProvider sdk={storage}>
            <BrowserRouter>
              <GlobalStyle />
              <TopNav />
              <ScrollToTop>
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </StorageProvider>
        </AnalyticsProvider>
      </FirestoreProvider>
    </AuthProvider>
      </div>
  );
}

export default App;
