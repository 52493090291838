import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

type Props = {
  cropWidth: number;
  imageUrl: string;
  onFinishedCropping: (croppedUrl: any) => void;
};

export default function ImageCrop({
  imageUrl,
  onFinishedCropping,
  cropWidth,
}: Props) {
  const [upImg, setUpImg] = useState<any>("");
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<any>({
    unit: "%",
    width: cropWidth,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState<any>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    setUpImg(imageUrl);
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;
    const crop: any = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx: any = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    canvas.toBlob((blob: any) => {
      if (blob != null) {
        const file: File = new File([blob], "temp.jpg", {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
        onFinishedCropping(file);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop, imageUrl]);

  return (
    <div>
      <ReactCrop
        style={{ maxHeight: 1000, minWidth: 100 + "%", borderRadius: 15 }}
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        ruleOfThirds={true}
        crossorigin={"anonymous"}
      />
      <canvas
        hidden
        ref={previewCanvasRef}
        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
        style={{
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
        }}
      />
    </div>
  );
}
