import React, {useState} from "react";
import styled from "styled-components";
import {ItemTypes, save_folder_name} from "../../global";
import { useDrop } from "react-dnd";

// Components
import {Block, Columns} from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";

import {GREY, LIGHT_RED, PRIMARY_BASE, RED, WHITE} from "../../styles/colors";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
  name: string;
  recipes: Array<any>;
  onAddToFolder: (recipe: any, folderName: string) => void;
  onClick: () => void;
  onRemoveFolder: (folderName: string) => void;
  activeFolderSelection: string;
};

interface FolderProps {
  isOver: any;
  selected: boolean;
}

const FolderContainer = styled.div<FolderProps>`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1em;
  outline: ${(props) => (props.isOver ? `2.75px solid ${PRIMARY_BASE}` : `none`)};
  border: ${(props) => (props.selected ? `2.75px solid ${PRIMARY_BASE}` : `none`)};

  cursor: pointer; 
  &:hover {
  outline: 1.75px solid ${PRIMARY_BASE};
}
`;

const HeaderFlexContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const HeaderItemRight = styled(Block)`
  align-items: flex-end;
  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;
const HeaderItemLeft = styled(Block)`
  align-items: flex-start;
`;

const Folder = (props: Props) => {
    // On drop to a folder
    const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.RECIPE,
      drop: (recipe: any) => props.onAddToFolder(recipe, props.name),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.name]
  );

  return (
    <Columns.Column size="one-quarter">
      <div onClick={props.onClick}>
        <FolderContainer ref={drop} isOver={isOver} selected={props.activeFolderSelection === props.name}>
            <HeaderFlexContainer>
          <Header margin={"0 0 0.5em 0"} size={5}>
            {props.name}
          </Header>
                {props.name !== "My Recipes" && props.name !== save_folder_name  ?
                    <HeaderItemRight>
                        <FontAwesomeIcon
                            style={{cursor: "pointer"}}
                            onClick={() => props.onRemoveFolder(props.name)}
                            icon={faWindowClose}
                            size={"sm"}
                            color={GREY}
                        />
                    </HeaderItemRight>
                : null}
            </HeaderFlexContainer>
          <Body>🍽 {props.recipes.length} recipes</Body>
        </FolderContainer>
      </div>
    </Columns.Column>
  );
};

export default Folder;
