export const RED = '#FF7777';
export const RED_BG = '#FFEFEF';
export const LIGHT_RED = '#FFDDDD';
export const RED_INPUT_BORDER = '#FFC5C5';
export const DARK_GREEN = '#1A535C';
export const TEAL = '#4ECDC4';
export const LIGHT_GREEN = '#F7FFF7';
export const YELLOW = '#FFE66D';
export const LIGHT_YELLOW = '#FFFBE5';
export const YELLOW_INPUT_BORDER = '#FFE97C';
export const DARK = '#434343';
export const GREY = '#808080';
export const LIGHT_GREY =  '#C6C6C6';
export const GREEN_INPUT_BORDER = '#D9F1D9';
export const WHITE = '#FFFFFF';
export const BLACK = '#000';
export const BLUE = '#8FBCCF';
export const BLUE_BG = "#F8FDFF";
export const PURPLE = '#DB86F0';

// Primary Color Palette
export const PRIMARY_BASE = "#4ECDC4";
export const PRIMARY_20S = "#3EA49D"
export const PRIMARY_40S = "#2F7B76"
export const PRIMARY_60S = "#1F524E"
export const PRIMARY_80S = "#102927"
export const PRIMARY_20T = "#71D7D0"
export const PRIMARY_40T = "#95E1DC"
export const PRIMARY_60T = "#B8EBE7"
export const PRIMARY_80T = "#DCF5F3"

// Secondary Color Palette
export const SECONDARY_BASE = "#8FBCCF";
export const SECONDARY_20S = "#7296A6"
export const SECONDARY_40S = "#56717C"
export const SECONDARY_60S = "#394B53"
export const SECONDARY_80S = "#1D2629"
export const SECONDARY_20T = "#A5C9D9"
export const SECONDARY_40T = "#BCD7E2"
export const SECONDARY_60T = "#D2E4EC"
export const SECONDARY_80T = "#E9F2F5"

// Tertiary Color Palette
export const TERTIARY_BASE = "#DB86F0";
export const TERTIARY_20S = "#AF6BC0"
export const TERTIARY_40S = "#835090"
export const TERTIARY_60S = "#583660"
export const TERTIARY_80S = "#2C1B30"
export const TERTIARY_20T = "#E29EF3"
export const TERTIARY_40T = "#E6AAF5"
export const TERTIARY_60T = "#F1CFF9"
export const TERTIARY_80T = "#F8E7FC"

// Error Color Palette
export const ERROR_BASE = "#FF7777";
export const ERROR_30T = "#FFA0A0"
export const ERROR_60T = "#FFC9C9"
export const ERROR_30S = "#B35353"
export const ERROR_60S = "#663030"

// Warning Color Palette
export const WARNING_BASE = "#F3D95B";
export const WARNING_30T = "#F7E48C"
export const WARNING_60T = "#FAF0BD"
export const WARNING_90T = "#FEFBEF"
export const WARNING_30S = "#AA9840"
export const WARNING_60S = "#615724"

// Success Color Palette
export const SUCCESS_BASE = "#4DB276";
export const SUCCESS_30T = "#82C99F"
export const SUCCESS_60T = "#B8E0C8"
export const SUCCESS_90T = "#FEFBEF"
export const SUCCESS_30S = "#367D53"
export const SUCCESS_60S = "#1F472F"

// Base (Greyscale) Color Palette
export const DARK_BASE = "#808080"
export const DARK_20S = "#666666"
export const DARK_40S = "#4D4D4D"
export const DARK_60S = "#333333"
export const DARK_80S = "#1A1A1A"
export const DARK_20T = "#999999"
export const DARK_40T = "#B3B3B3"
export const DARK_60T = "#CCCCCC"
export const DARK_80T = "#E6E6E6"

// Light Color Palette
export const LIGHT_BASE = "#FFFEF5"
export const LIGHT_20S = "#CCCBC4"
export const LIGHT_20T = "#FFFEF7"
export const LIGHT_40T = "#FFFEF9"
export const LIGHT_60T = "#FFFFFB"
export const LIGHT_80T = "#FFFFFD"