import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import {
  InstantSearch,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import styled from "styled-components";
import { createNullCache } from "@algolia/cache-common";
import { algoliaSearchDelay } from "../../global";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Atoms
import { Block } from "react-bulma-components";
import Header from "../../components/atoms/Header";
import Button from "../../components/atoms/Button";

// Molecules

// Organisms
import InfiniteHits from "../../components/organisms/InfiniteHits";
import AlgoliaSearch from "../../components/organisms/AlgoliaSearch";
import SaveModal from "../../components/organisms/SaveModal";
import ShareModal from "../../components/organisms/ShareModal";

// Styles
import { GREY } from "../../styles/colors";
import { useUser } from "reactfire";
import Loading from "../../components/atoms/Loading";
import {AllRecipesOnBoarding} from "../onboarding/AllRecipesOnBoarding";
import ScrapeRecipeModal from "../../components/organisms/ScrapeRecipeModal";

const SearchBarContainer = styled.div`
  margin-bottom: 2em;

  &:hover {
    border-color: ${GREY};
  }
`;

export default function AllRecipes(props: any) {
  const [index, setIndex] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [scrapeModal, setScrapeModal] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");
  const [recommendations, setRecommendations] = useState<Array<object>>([]);
  const [mode, setMode] = useState<string>("");

  //request resources
  const { data: user } = useUser();
  const userId = user!.uid;
  const raw = '{"instances": ["' + userId + '"]}';
  let getRecHeaders = new Headers();
  getRecHeaders.append("Content-Type", "application/json");
  let requestOptions: RequestInit = {
    method: "POST",
    headers: getRecHeaders,
    body: raw,
    redirect: "follow",
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log("PROD")
      setIndex("recipes_created_at_desc");
    } else {
      console.log("DEV")
      setIndex("recipes_dev");
    }
    //DO NOT REMOVE (THIS WILL BE ADDED BACK WHEN WE ARE READY TO RE IMPLEMENT RECOMMENDATIONS)
    // const getRecommendation = async () => {
    //   setLoading(true);
    //   await fetch(
    //     "https://us-central1-zesty-48258.cloudfunctions.net/getRecipePredictions",
    //     requestOptions
    //   )
    //     .then((response) => response.text())
    //     .then((result) => {
    //       const data = JSON.parse(result);
    //       const output = data.output_2;
    //       setRecommendations(output);
    //       setLoading(false);
    //     })
    //     .catch((error) => console.log("error", error));
    // };
    // getRecommendation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //DO NOT REMOVE (THIS WILL BE ADDED BACK WHEN WE ARE READY TO RE IMPLEMENT RECOMMENDATIONS)

  }, []);

  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID!,
    process.env.REACT_APP_ALGOLIA_API_KEY!,
    {
      responsesCache: createNullCache(),
      requestsCache: createNullCache(),
    }
  );

  const StateResults = ({ isSearchStalled }: any) => (
    <span
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isSearchStalled ? <Loading /> : ""}
    </span>
  );
  const CustomStateResults = connectStateResults(StateResults);

  function setAlgoliaIndex(searchState: any) {
    if (process.env.NODE_ENV === "production") {
      if (searchState.query === "" || searchState.query === undefined) {
        setIndex("recipes_created_at_desc");
        setMode("search");
      } else {
        setIndex("recipes");
        setMode("search");
      }
    } else {
      if (searchState.query === "" || searchState.query === undefined) {
        setIndex("recipes_dev");
        setMode("search");
      } else {
        setIndex("recipes_dev");
        setMode("search");
      }
    }
  }

  const onSaveClick = (id: string) => {
    setRecipeId(id);
    setOpenModal(true);
  };

  const onShareClick = (id: string) => {
    setRecipeId(id);
    setShareModal(true);
  };

  const onScrapeClick = () => {
    setScrapeModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <InstantSearch
      onSearchStateChange={(searchState) => setAlgoliaIndex(searchState)}
      searchClient={searchClient}
      indexName={index}
    >
      <AllRecipesOnBoarding run={true}/>
      <Configure
        filters="private:false AND completed:true"
        analytics={false}
        enablePersonalization={false}
        distinct
        hitsPerPage={12}
      />
      <DndProvider backend={HTML5Backend}>
        <Block>
          <Block
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Header margin={"0"} size={4}>
              All Recipes
            </Header>
              <Block
                  style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                  }}
              >
            <Link
              to={{
                pathname: "/app/create",
                state: {
                  id: uuidv4(),
                  recipe: null,
                  edit: false,
                },
              }}
            >
              <Button primary>Create</Button>
            </Link>
              <Button margin={"0 0 0 .5em"} primary onClick={onScrapeClick}>Import</Button>
          </Block>
        </Block>
          <SearchBarContainer>
            <AlgoliaSearch query={query} delay={algoliaSearchDelay} />
            <CustomStateResults />
          </SearchBarContainer>
          <InfiniteHits
            onTagClick={(tag: string) => setQuery(tag)}
            onUserClick={(userName: string) => setQuery(userName)}
            onSave={(id: string) => onSaveClick(id)}
            onShare={(id: string) => onShareClick(id)}
            mode={mode}
            recHits={recommendations}
          />
        </Block>
      </DndProvider>
      <SaveModal
        onClose={() => setOpenModal(false)}
        openModal={openModal}
        recipeId={recipeId}
      />
      <ShareModal
        onClose={() => setShareModal(false)}
        openModal={shareModal}
        recipeId={recipeId}
      />
      <ScrapeRecipeModal
          onClose={() => setScrapeModal(false)}
          openModal={scrapeModal}
      />
    </InstantSearch>
  );
}
