import styled from 'styled-components';
import {Form} from 'react-bulma-components';

import {GREY, GREEN_INPUT_BORDER, LIGHT_GREEN} from '../../styles/colors';

type Props = {
  value: any,
  onChange: React.ChangeEventHandler<HTMLSelectElement>,
  children: any,
}

const StyledSelect = styled(Form.Select)`
  > select {
    font-family: Inter;
    background-color: ${LIGHT_GREEN};
    border-radius: 10px;
    height: 55px;
    width: 100%;
    border: 1px solid ${GREEN_INPUT_BORDER};
    color: ${GREY};
  }

  ::after {
    top: 67% !important;
    border-color: ${GREY} !important;
  }
`

export default function Select(props: Props) {
  return (
    <StyledSelect
      value={props.value}
      onChange={props.onChange}
    >
      {props.children}
    </StyledSelect>
  )
}