import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Firebase
import {
  collection,
  doc,
  increment,
  setDoc,
  updateDoc,
  getDocs,
} from "@firebase/firestore";
import { useUser, useFirestore, useFirestoreDocDataOnce } from "reactfire";

// Components
import { Block, Modal } from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Button from "../atoms/Button";

// Molecules
import FormTextarea from "../molecules/FormTextarea";

// Styles
import { DARK, LIGHT_GREY, DARK_GREEN } from "../../styles/colors";
import RecipeSelect from "../molecules/RecipeSelect";
import LoadingSmall from "../atoms/LoadingSmall";
import {GroupsShareOnBoarding} from "../../pages/onboarding/groups/GroupShareOnBoarding";

type Props = {
  groupId: string;
  openModal: boolean;
  onClose: () => void;
};

const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled.a<{ active: boolean }>`
  color: ${(props) => (props.active ? DARK : LIGHT_GREY)};
  margin-right: 1em;

  &:hover {
    color: ${DARK};
  }
`;

const RecipeContainer = styled(Block)`
  display: flex;
  flex-direction: column;
`;

const SharePostModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [initializing, setInitializing] = useState<boolean>(true);
  const [comment, setComment] = useState<string>("");
  const [status, setStatus] = useState<string>("all");
  const [myRecipes, setMyRecipes] = useState<Array<any>>([]);
  const [likedRecipes, setLikedRecipes] = useState<Array<any>>([]);
  const [savedRecipes, setSavedRecipes] = useState<Array<any>>([]);
  const [shownRecipes, setShownRecipes] = useState<Array<any>>([]);
  const [selectedRecipe, setSelectedRecipe] = useState<string>("");

  // Initialize firestore
  const firestore = useFirestore();

  // Get User Data
  const { data: user } = useUser();

  let userRef = doc(firestore, "users", user!.uid);
  const { data: userData } = useFirestoreDocDataOnce<any>(userRef);

  // Get Group Reference
  const groupRef = doc(firestore, "groups", props.groupId);

  // Firestore references to created / liked / saved recipes
  const likedRecipesRef = collection(
    firestore,
    "users",
    user!.uid,
    "likedRecipes"
  );
  const savedRecipesRef = collection(
    firestore,
    "users",
    user!.uid,
    "savedRecipes"
  );
  const myRecipesRef = collection(firestore, "users", user!.uid, "recipes");

  useEffect(() => {
    let likedRecipesArray: Array<any> = [];
    let myRecipesArray: Array<any> = [];
    let savedRecipesArray: Array<any> = [];

    const getLikedRecipes = async () => {
      const likedRecipeSnap = await getDocs(likedRecipesRef);
      likedRecipeSnap.forEach((doc: any) => {
        likedRecipesArray.push(doc.id);
      });
      setLikedRecipes(likedRecipesArray);
    };

    const getSavedRecipes = async () => {
      const savedRecipeSnap = await getDocs(savedRecipesRef);
      savedRecipeSnap.forEach((doc: any) => {
        savedRecipesArray.push(doc.id);
      });
      setSavedRecipes(savedRecipesArray);
    };

    const getMyRecipes = async () => {
      const myRecipeSnap = await getDocs(myRecipesRef);
      myRecipeSnap.forEach((doc: any) => {
        myRecipesArray.push(doc.id);
      });
      setMyRecipes(myRecipesArray);
    };

    getLikedRecipes();
    getSavedRecipes();
    getMyRecipes();

    setInitializing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // Clear component state
  const clearState = () => {
    setComment("");
    setStatus("");
    setShownRecipes([]);
  };

  // Switch tab status
  const switchStatus = (currentStatus: string) => {
    if (currentStatus === "all") {
      setShownRecipes(myRecipes);
      setStatus("all");
    }

    if (currentStatus === "liked") {
      setShownRecipes(likedRecipes);
      setStatus("liked");
    }

    if (currentStatus === "saved") {
      setShownRecipes(savedRecipes);
      setStatus("saved");
    }
  };

  const onSharePost = async () => {
    setLoading(true);

    if (selectedRecipe !== "") {
      let postId = uuidv4();

      try {
        // Create a new document for post within the group
        let postRef = doc(groupRef, "posts", postId);
        await setDoc(postRef, {
          user: {
            id: user!.uid,
            name: userData.name,
            profileUrl: userData.profileUrl,
          },
          recipeId: selectedRecipe,
          comment: comment,
          postDate: new Date(),
        });

        // Update the number of posts field for the group
        await updateDoc(groupRef, {
          numberOfPosts: increment(1),
        });

        clearState();
        setLoading(false);
        props.onClose();
      } catch (e) {
        alert(e);
      }
    }
  };

  if (initializing) {
    return <Body>Loading</Body>;
  }

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <GroupsShareOnBoarding run={true}/>
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Share post
          </Header>
          <Body margin={"0 0 1em 0"}>
            Share a recipe with your group, and write a quick comment about it.
          </Body>
          <hr />
          <FormTextarea
            label={"Post a comment"}
            value={comment}
            placeholder={"Write a quick comment"}
            onChange={(e: any) => setComment(e.target.value)}
          />
          <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
            Choose the recipe
          </Body>
          <StyledLink
            onClick={(e: any) => switchStatus("all")}
            active={status === "all" ? true : false}
          >
            My Recipes
          </StyledLink>
          <StyledLink
            onClick={(e: any) => switchStatus("liked")}
            active={status === "liked" ? true : false}
          >
            Liked Recipes
          </StyledLink>
          <StyledLink
            onClick={(e: any) => switchStatus("saved")}
            active={status === "saved" ? true : false}
          >
            Saved Recipes
          </StyledLink>
          <hr />
          <RecipeContainer>
            {shownRecipes.map((recipeId: string) => {
              return (
                <RecipeSelect
                  id={recipeId}
                  active={recipeId === selectedRecipe ? true : false}
                  onClick={() => setSelectedRecipe(recipeId)}
                />
              );
            })}
          </RecipeContainer>
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            {loading === true ? (
              <LoadingSmall />
            ) : (
              <Button primary onClick={() => onSharePost()}>
                Share
              </Button>
            )}
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default SharePostModal;
