import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// Firebase
import { doc, setDoc, getDoc } from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { useFirestore, useStorage, useUser, useAnalytics } from "reactfire";

// Components
import { Modal, Block, Container, Form } from "react-bulma-components";
import Header from "../../components/atoms/Header";
import FormField from "../../components/molecules/FormField";
import SelectField from "../../components/molecules/SelectField";
import Body from "../../components/atoms/Body";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Select from "../../components/atoms/Select";
import Dropzone from "../../components/atoms/Dropzone";
import Loading from "../../components/atoms/Loading";
import IngredientRow from "../../components/molecules/IngredientRow";
import RecipeFooter from "../../components/organisms/RecipeFooter";
import ImageCrop from "../../components/organisms/ImageCrop";

//Image utils
import { optimizeImage } from "../../utilities";

// Styles
import {
  LIGHT_GREEN,
  LIGHT_YELLOW,
  DARK_GREEN,
  RED,
} from "../../styles/colors";
import {BasicCreateOnBoarding} from "../onboarding/create/BasicCreateOnBoarding";

const DeleteImageContainer = styled.div`
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  width: 100px;
  background-size: 100%;
  display: flex;
  padding: 0.25em 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const StyledIngredientsBlock = styled(Block)`
  background-color: ${LIGHT_YELLOW};
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
`;

const notifyIngredientAdded = () =>
  toast("Ingredient successfully added", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "🎉",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });

export default function Basic() {
  const [loading, setLoading] = useState<boolean>(false);

  // Basic information
  const [recipeId, setRecipeId] = useState<string>("");
  const [recipeName, setRecipeName] = useState<string>("");
  const [servingSize, setServingSize] = useState<number>(1);
  const [recipeCuisine, setRecipeCuisine] = useState<string>("");
  const [ingredientImage, setIngredientImage] = useState<any>(null);
  const [ingredientImageUrl, setIngredientImageUrl] = useState<
    string | undefined
  >("");
  const [privacy, setPrivacy] = useState<string>("private");
  const [backOpenModal, setBackOpenModal] = useState<boolean>(false);

  // Ingredients
  const [ingredientAmount, setIngredientAmount] = useState<string>("0");
  const [ingredientAmountFraction, setIngredientAmountFraction] =
    useState<string>("");
  const [ingredientMeasure, setIngredientMeasure] = useState<String>("");
  const [ingredient, setIngredient] = useState<string>("");
  const [recipeIngredients, setRecipeIngredients] = useState<Array<any>>([]);

  //Steps
  const [recipeSteps, setRecipeSteps] = useState<Array<any>>([]);

  // Get react router history
  const history = useHistory();
  const location: any = useLocation();
  const { id } = location.state;

  // Firestore Initialization
  const firestore = useFirestore();

  // React Fire User Details Record
  const { data: user } = useUser();

  // Firestore references for user details and recipe
  const userDetailsRef = doc(firestore, "users", user!.uid, "recipes", id);
  const recipeRef = doc(firestore, "recipes", id);

  // Initialize Firebase storage for image upload
  const storage = useStorage();

  // Initialize Firebase Analytics
  const analytics = useAnalytics();

  // Initialize UUID as recipeId in State
  useEffect(() => {
    async function getRecipeDetails() {
      let recipeSnapshot = await getDoc(recipeRef);
      return recipeSnapshot.data();
    }
    getRecipeDetails().then((recipe) => {
      if (recipe) {
        // Log event for editing a recipe
        logEvent(analytics, "edit_recipe_basic", {
          user: user!.uid,
        });
        setRecipeSteps(recipe.steps);
        setRecipeName(recipe.name);
        setServingSize(recipe.servingSize);
        setRecipeCuisine(recipe.cuisine);
        setIngredientImageUrl(recipe.ingredientImageUrl);
        setRecipeIngredients(recipe.ingredients);
        setPrivacy(recipe.private ? "private" : "public");
      } else {
        console.log("Recipe not found");
      }
    });

    // Log event for creating a recipe
    logEvent(analytics, "create_recipe_basic", {
      user: user!.uid,
    });
    setRecipeId(id);
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeImage = (image: any, imageUrl: string) => {
    setIngredientImage(image);
    setIngredientImageUrl(imageUrl);
  };

  // Check ingredient amount of letters or symbols. Only allow numbers to set ingredient amount
  const onChangeIngredientAmount = (e: any) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    setIngredientAmount(value);
  };

  const onChangeServingSize = (e: any) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (value === "") {
      setServingSize(0);
    } else {
      setServingSize(parseInt(value, 10));
    }
  };

  // Add ingredients to recipes and steps
  const onAddIngredient = (e: Event) => {
    e.preventDefault();
    let totalIngredientAmount =
      ingredientAmount + " " + ingredientAmountFraction;

    const ingredientObject = {
      amount: totalIngredientAmount,
      measure: ingredientMeasure,
      name: ingredient,
    };

    let recipeIngredientsArray = [...recipeIngredients];
    recipeIngredientsArray.push(ingredientObject);
    setRecipeIngredients(recipeIngredientsArray);
    setIngredientAmount("");
    setIngredientAmountFraction("");
    setIngredientMeasure("");
    setIngredient("");
    notifyIngredientAdded();
  };

  // Delete ingredient image
  const onDeleteImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIngredientImage(null);
    setIngredientImageUrl("");
    console.log("ingredientImage in delete: ");
    console.log(ingredientImage);
    console.log("ingredientImageUrl");
    console.log(ingredientImageUrl);
  };

  // BEGIN: Moving ingredient up and down
  function shiftArray(arr: Array<any>, from: number, to: number) {
    let cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
    return arr;
  }

  const onStepUp = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...recipeIngredients];
    let newArray = shiftArray(recipeStepsArray, i, i - 1);
    setRecipeIngredients(newArray);
  };

  const onStepDown = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...recipeIngredients];
    let newArray = shiftArray(recipeStepsArray, i, i + 1);
    setRecipeIngredients(newArray);
  };

  // Delete ingredients
  const onIngredientDelete = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let ingredientsArray = [...recipeIngredients];
    ingredientsArray.splice(i, 1);
    setRecipeIngredients(ingredientsArray);
  };

  // Upload ingredient image to Firebase storage
  const uploadRecipeImage = async () => {
    // Set loading to true
    setLoading(true);

    const ext = ingredientImage.type.substr(
      ingredientImage.type.lastIndexOf("/") + 1
    );

    // Initialize storage reference
    const storageRef = ref(storage, `recipes/${recipeId}/ingredient.${ext}`);

    try {
      let image: any = null;
      await optimizeImage(ingredientImage).then((result: any) => {
        image = result;
      });
      await uploadBytes(storageRef, image);
      let url = await getDownloadURL(storageRef);
      console.log("saved new image crop with url: " + url);
      return url;
    } catch (e) {
      alert(e);
    }
  };

  // Initialize back modal
  const onBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setBackOpenModal(true);
  };

  const onRecipeCreate = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let newUrl = ingredientImageUrl;

    // Check if ingredient image was uploaded. If so, upload to Firebase storage.
    console.log("ingredientImage: ");
    console.log(ingredientImage);
    if (ingredientImage != null) {
      newUrl = await uploadRecipeImage();
      console.log("saving ingredient image with download URL: " + newUrl);
    }

    let creatorObject = {
      id: user!.uid,
      name: user!.displayName,
      profileUrl: user!.photoURL,
    };

    try {
      await setDoc(recipeRef, {
        name: recipeName,
        creator: creatorObject,
        ingredientImageUrl: newUrl,
        servingSize: servingSize,
        cuisine: recipeCuisine,
        ingredients: recipeIngredients,
        private: privacy === "private",
        createdAt: new Date(),
        createdAtTimeStamp: Date.now(),
        completed: false,
        // Blank initialization
        steps: recipeSteps,
        description: "",
        tips: "",
        highlightUrl: "",
        prepTime: null,
        cookTime: null,
        tags: [],
        usersLiked: [],
      });
      await setDoc(userDetailsRef, {
        name: recipeName,
        private: privacy === "private",
        createdAt: new Date(),
        completed: false,
      });
      history.push({
        pathname: "/app/create/steps",
        state: {
          id: recipeId,
          ingredients: recipeIngredients,
        },
      });
    } catch (e) {
      alert(e);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <BasicCreateOnBoarding run={true}/>
      <Container style={{ maxWidth: 996, marginBottom: 90 }}>
        <Header size={2}>🔥 Tell us about your recipe</Header>
        <form>
          <div>
          <FormField
            label="What's the name of the recipe?"
            value={recipeName}
            placeholder="Recipe name"
            onChange={(e: any) => setRecipeName(e.target.value)}
          />
          <SelectField
            label="Is this a public or private recipe?"
            text="A public recipe will be shown to everyone on Zesty. Private recipes will only be visible to you."
            value={privacy}
            onChange={(e: any) => setPrivacy(e.target.value)}
          >
            <option value={"private"}>Private</option>
            <option value={"public"}>Public</option>
          </SelectField>
          <FormField
            label="What type of cuisine is this recipe?"
            value={recipeCuisine}
            placeholder="Cuisine"
            onChange={(e: any) => setRecipeCuisine(e.target.value)}
          />
          <FormField
            label="How many servings does this make?"
            value={servingSize}
            placeholder="Serving size"
            onChange={(e: any) => onChangeServingSize(e)}
          />
          </div>
          <div>
          <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
            Add an image of all the ingredients
          </Body>
          <Body margin={"0.5em 0"} color={RED}>
            *Optional
          </Body>
          <Body margin={"0.5em 0"}>
            Adding an image of all the ingredients will help show people what
            ingredients they should prepare before they get started cooking.
          </Body>
          <Dropzone
            onFileAccept={(image: any, imageUrl: string) => {
              changeImage(image, imageUrl);
            }}
          />
          {ingredientImageUrl ? (
            <>
              <DeleteImageContainer
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onDeleteImage(e)
                }
              >
                <FontAwesomeIcon
                  color={RED}
                  size={"1x"}
                  style={{ cursor: "pointer" }}
                  icon={faTimesCircle}
                />
                <Body color={RED}> Delete</Body>
              </DeleteImageContainer>
              <ImageCrop
                cropWidth={75}
                imageUrl={ingredientImageUrl}
                onFinishedCropping={(croppedFile: any) => {
                  setIngredientImage(croppedFile);
                }}
              />
            </>
          ) : null}
          </div>
          <Block style={{ margin: "3em 0" }}>
            <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
              Add an ingredient
            </Body>
            <Body margin={"1em 0"}>
              Make sure to add a separate ingredient for every step that needs
              it. For instance, if step 1 needs 1g of sugar, and step 2 needs 2g
              of sugar, add separate ingredients for 1g sugar and 2g sugar.
            </Body>
            <Form.Field style={{ marginTop: 8 }} kind="group">
              <Form.Control>
                <Input
                  value={ingredientAmount}
                  placeholder={"Amount"}
                  onChange={(e: any) => onChangeIngredientAmount(e)}
                />
              </Form.Control>
              <Form.Control>
                <Select
                  value={ingredientAmountFraction}
                  onChange={(e) => setIngredientAmountFraction(e.target.value)}
                >
                  <option value={""}></option>
                  <option value={"¼"}>¼</option>
                  <option value={"⅓"}>⅓</option>
                  <option value={"½"}>½</option>
                  <option value={"⅔"}>⅔</option>
                  <option value={"¾"}>¾</option>
                </Select>
              </Form.Control>
              <Form.Control>
                <Select
                  value={ingredientMeasure}
                  onChange={(e) => setIngredientMeasure(e.target.value)}
                >
                  <option value=""></option>
                  <option value="cups">Cup(s)</option>
                  <option value="tsp">Teaspoon(s)</option>
                  <option value="tbsp">Tablespoon(s)</option>
                  <option value="floz">Fluid Ounce(s)</option>
                  <option value="liter">Liter(s)</option>
                  <option value="milliliter">Milliliter(s)</option>
                  <option value="oz">Ounce(s)</option>
                  <option value="kilo">Kilo(s)</option>
                  <option value="pinch">Pinch(es)</option>
                  <option value="pounds">Pound(s)</option>
                  <option value="bunch">Bunch(es)</option>
                  <option value="grams">Gram(s)</option>
                  <option value="piece">Piece(s)</option>
                  <option value="whole">Whole(s)</option>
                  <option value="slices">Slice(s)</option>
                </Select>
              </Form.Control>
              <Form.Control>
                <Input
                  value={ingredient}
                  placeholder="Mozarella"
                  onChange={(e) => setIngredient(e.target.value)}
                />
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <Form.Control>
                <Button
                  disabled={ingredient === "" ? true : false}
                  onClick={(e: any) => onAddIngredient(e)}
                >
                  Add Ingredient
                </Button>
              </Form.Control>
            </Form.Field>
          </Block>
        </form>
        <Block style={{ marginTop: 32 }}>
          <Header size={4}>Ingredients added</Header>
          {recipeIngredients.length > 0 ? (
            <StyledIngredientsBlock>
              {recipeIngredients.map((ingredient: any, i: number) => (
                <IngredientRow
                  key={i}
                  onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                    onIngredientDelete(e, i)
                  }
                  ingredient={ingredient}
                  onStepUp={(e: React.MouseEvent<SVGSVGElement>) =>
                    onStepUp(e, i)
                  }
                  onStepDown={(e: React.MouseEvent<SVGSVGElement>) =>
                    onStepDown(e, i)
                  }
                  deletable
                  movable
                />
              ))}
            </StyledIngredientsBlock>
          ) : null}
        </Block>
      </Container>
      <RecipeFooter
        history={history}
        id={recipeId}
        onBack={(e: React.MouseEvent<HTMLButtonElement>) => onBack(e)}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onRecipeCreate(e)}
      />
      <Modal
        show={backOpenModal}
        onClose={() => {
          return setBackOpenModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body
            style={{ backgroundColor: LIGHT_GREEN, borderRadius: 20 }}
          >
            <Header margin={"0.5em 0"} size={4}>
              Are you sure?
            </Header>
            <Body margin={"1em 0"}>
              When you go back, you'll lose any progress that you made on this
              page.
            </Body>
            <Body margin={"1em 0"}>
              To save this recipe, click "Next" and then you can go back to
              edit.
            </Body>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <Button
                margin={"0 1em 0 0"}
                primary
                onClick={() => setBackOpenModal(false)}
              >
                Keep editing
              </Button>
              <Button onClick={() => history.goBack()}>Delete changes</Button>
            </Container>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
    </Container>
  );
}
