import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";
type Props = {
    run: boolean;
};

export const StepsCreateOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root > section:nth-child(3) > div > div > div > div > form > div:nth-child(2)',
                content: 'Recipes are broken down into steps, each describing a specific stage of the recipe. You can be as detailed as you like with the description, but try and be as clear as possible so others can follow along',
                disableBeacon: true,
                disableScroll: true,

            },
            {
                target: '#root > section:nth-child(3) > div > div > div > div > form > div:nth-child(3)',
                content: 'You can also add as many recipe tips as you like to each step. Simply write in the tip and then click enter',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > div > div > form > div:nth-child(4)',
                content: 'If you have a picture of the step, you can add it here, but don’t worry you can always add it later instead!',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > div.container > div > form > div:nth-child(5) > div',
                content: 'Make sure to add every ingredient that is needed in each step by checking its box. If you forget an ingredient or added one by accident, don’t worry! You can always add or delete ingredients at this stage.',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > div > div > form > button',
                content: 'Once you have written a step description, tips, and added all the ingredients needed for that step, make sure to click add a step. Repeat for every step that you have.',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > div.container > hr',
                content: 'Once a step is added you will see it here. You will be able to edit, delete, and reorder steps once added.',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > section > div > button',
                content: 'Once you have added all recipe steps, click next',
                disableBeacon: true,
                placement: 'right' as 'right',
            },
        ]
    )

    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.recipeCreateSteps;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
            window.scrollTo(0, 0);
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.recipeCreateSteps": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
