import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import Body from "./Body";

import { SECONDARY_80S, DARK_BASE, ERROR_BASE } from "../../styles/colors";

const NoteContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 1em;
`;

const NoteLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

type Props = {
  notes: Array<string>;
  deletable: boolean;
  onDelete?: (i: number) => void;
};

const StepNotes = (props: Props) => {
  return (
    <NoteContainer>
      <Body color={SECONDARY_80S} margin={"0 0 1em 0"} font="NewYork">
        📔 Step notes
      </Body>
      {props.notes.map((note: any, i: number) => {
        return (
          <NoteLineContainer>
            <Body key={i} color={DARK_BASE} margin={"0 0 0.5em 0"}>
              - {note}
            </Body>
            {props.deletable ? (
              <FontAwesomeIcon
                key={i}
                onClick={() => {
                  if (props.onDelete) {
                    return props.onDelete(i);
                  }
                }}
                color={ERROR_BASE}
                size={"lg"}
                style={{ cursor: "pointer" }}
                icon={faTimesCircle}
              />
            ) : null}
          </NoteLineContainer>
        );
      })}
    </NoteContainer>
  );
};

export default StepNotes;
