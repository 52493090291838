import React from "react";

// Components
import { Block, Form } from "react-bulma-components";
import Body from "../atoms/Body";
import Input from "../atoms/Input";

// Styles
import { RED, DARK_GREEN } from "../../styles/colors";

type Props = {
  label: string;
  value: any;
  text?: string;
  error?: boolean;
  errorText?: string;
  placeholder: string;
  type?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormField = (props: Props) => {
  return (
    <Block style={{ margin: "1.5em 0", textAlign: "left" }}>
      <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
        {props.label}
      </Body>
      {props.text ? <Body margin={"1em 0"}>{props.text}</Body> : null}
      {props.error ? <Body color={RED}>{props.errorText}</Body> : null}
      <Form.Field style={{ marginTop: 8 }} kind="group">
        <Form.Control fullwidth={true}>
          <Input
            value={props.value}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            onChange={props.onChange}
            type={props.type}
          ></Input>
        </Form.Control>
      </Form.Field>
    </Block>
  );
};

export default FormField;
