import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Firebase
import {
  doc,
  collection,
  increment,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import {
  useUser,
  useFirestore,
  useFirestoreDocData,
  useAnalytics,
} from "reactfire";

// Components
import { Block, Modal } from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Subheading from "../atoms/Subheading";
import Button from "../atoms/Button";

// Molecules
import FormTextarea from "../molecules/FormTextarea";
import GroupSelect from "../molecules/GroupSelect";

// Styles
import { DARK } from "../../styles/colors";
import FormField from "../molecules/FileField";
import Input from "../atoms/Input";

type Props = {
  modalType: string;
  openModal: boolean;
  onClose: () => void;
  onDeleteFolder: (folderName: string) => void
  folderName: string;
};

const ButtonContainer = styled(Block)`
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const ConfirmFolderDeleteModal = (props: Props) => {
  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20, textAlign: "center"}}>
          <Header size={3} margin={"0 0 0.5em 0"} color={DARK}>
            Delete {props.modalType}
          </Header>
          <Body color={DARK}>
            Are you sure you want to delete the "{props.folderName}" {props.modalType}?
          </Body>
          <ButtonContainer>
            <Button margin={"0 2em"} onClick={props.onClose}>Cancel</Button>
            <Button margin={"0 2em"} primary onClick={() => props.onDeleteFolder(props.folderName)}>
              Delete {props.modalType}
            </Button>
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default ConfirmFolderDeleteModal;
