import React from "react";
import styled from "styled-components";

// Styles
import { LIGHT_GREY, WHITE, TEAL } from "../../styles/colors";

type Props = {
  children: any;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  height?: number | string;
  margin?: string;
  disabled?: boolean;
};

const StyledButton = styled("button")<Props>`
  background-color: ${(props) => (props.active ? TEAL : WHITE)};
  border: ${(props) => (props.active ? "none" : `1px solid ${TEAL}`)};
  height: ${(props) => (props.height ? props.height : "50px")};
  padding: 0.5em 1em;
  border-radius: 10px;
  color: ${(props) => (props.active ? WHITE : TEAL)};
  font-family: "Inter";
  font-size: 16px;
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : null)};
  width: 140px;

  &:disabled {
    background-color: ${LIGHT_GREY};
    pointer-events: none;
  }
`;

export default function OutlineButton(props: Props) {
  return (
    <StyledButton
      disabled={props.disabled}
      margin={props.margin}
      height={props.height}
      active={props.active}
      onClick={props.onClick}
    >
      {props.children}
    </StyledButton>
  );
}
