import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Firebase
import {
  useUser,
  useFirestore,
  useFirestoreDocData,
  useAnalytics,
} from "reactfire";
import {
  doc,
  collection,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  setDoc,
  deleteDoc,
  getDoc,
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";

// Components
import { Block, Modal } from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Subheading from "../atoms/Subheading";
import FormField from "../molecules/FormField";
import ListRow from "../molecules/ListRow";
import Button from "../atoms/Button";

// Styles
import { BLUE, DARK } from "../../styles/colors";
import {save_folder_name} from "../../global";

type Props = {
  openModal: boolean;
  onClose: () => void;
  recipeId: string;
};

const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  font-family: Inter;
  font-size: 16px;
  color: ${BLUE};
`;

const SaveModal = (props: Props) => {
  const [userFolders, setUserFolders] = useState<Array<any>>([]);
  const [selectedFolders, setSelectedFolders] = useState<Array<string>>([]);
  const [previouslySaved, setPreviouslySaved] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");
  const [folderError, setFolderError] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");
  const [initializing, setInitializing] = useState<boolean>(true);

  const firestore = useFirestore();
  const { data: user } = useUser();
  const userRef = doc(firestore, "users", user!.uid);
  const savedRecipesRef = collection(
    firestore,
    "users",
    user!.uid,
    "savedRecipes"
  );

  const { data } = useFirestoreDocData<any>(userRef);

  const analytics = useAnalytics();

  useEffect(() => {
    let folderArray: Array<any> = [];
    let selectedFolderArray: Array<any> = [];

    // Get user lists
    if (data) {
      if (data.folders_saved) {
        if (!(data.folders_saved.length === 0)) {
          data.folders_saved.forEach((folder: any) => {
            let selected = folder.recipes.includes(props.recipeId) ? true : false;
            let folderObject = { name: folder.name, selected: selected };
            folderArray.push(folderObject);
            if (selected) {
              selectedFolderArray.push(folder.name);
              setPreviouslySaved(true);

            }
          });
        }
      }
    }
    setSelectedFolders(selectedFolderArray);
    setUserFolders(folderArray);
    setRecipeId(props.recipeId);
    setInitializing(false);
  }, [data, props]);

  const recipeRef = collection(firestore, "recipes");

  const onAddList = async () => {
    if (folderName === "") {
      setFolderError(true);
    } else {
      let folderArray = data.folders_saved ? data.folders_saved : [];
      let newFolderObject = { name: folderName, recipes: [] };
      folderArray.push(newFolderObject);
      try {
        await setDoc(
          userRef,
          {
            folders_saved: folderArray,
          },
          {
            merge: true,
          }
        );
        setFolderName("");
        setFolderError(false);
      } catch (e) {
        console.log(e);
      }
    }
    onSelectList(0);
  };

  const onSelectList = (i: number) => {
    let userFoldersArray = [...userFolders];
    let selectedFoldersArray = [...selectedFolders];
    let folderObject = userFoldersArray[i];

    //automatically select default save folder if any menu is selected. Only select All Saved when a selection is set to true
    if(folderObject.name !== save_folder_name && userFoldersArray[i].selected === false){
      if(!selectedFoldersArray.includes(save_folder_name)){
        selectedFoldersArray.push(save_folder_name);
      }

      let index = selectedFoldersArray.indexOf(save_folder_name);
      userFoldersArray[index].selected = true;
    }

    if (!selectedFoldersArray.includes(folderObject.name)) {
      selectedFoldersArray.push(folderObject.name);
    } else {
      let index = selectedFoldersArray.indexOf(folderObject.name);
      selectedFoldersArray.splice(index, 1);
    }

    userFoldersArray[i].selected = !userFoldersArray[i].selected;
    setSelectedFolders(selectedFoldersArray);
    setUserFolders(userFoldersArray);
    console.log("select array length");
    console.log(selectedFoldersArray)
  };

  const onSaveRecipe = async () => {
    const specificSavedRecipeRef = doc(savedRecipesRef, recipeId);
    const specificRecipeRef = doc(recipeRef, recipeId);

    // Pull savedRecipes from firestore, check which list doesn't have the recipe, and add it to the recipes array
    let savedRecipesArray = data.folders_saved ? data.folders_saved : [];
    let newSavedRecipesArray = [...savedRecipesArray];

    // Get all the lists that we need to save this recipe to
    let foldersToSave = savedRecipesArray.filter((folder: any) => {
      return selectedFolders.includes(folder.name);
    });
    if(foldersToSave.length > 0){
      onSelectList(0);
    }
    foldersToSave.forEach((folder: any) => {
      // Add recipe to folder if it doesn't already include it
      if (!folder.recipes.includes(recipeId)) {
        folder.recipes.push(recipeId);
      }

      let index = newSavedRecipesArray.findIndex(
        (originalFolder: any) => originalFolder.name === folder.name
      );
      newSavedRecipesArray[index].recipes = folder.recipes;
    });

    // Get all the lists that we need to remove this recipe from, and remove the recipeId from that array
    let foldersToRemove = savedRecipesArray.filter((list: any) => {
      return (
        list.recipes.includes(recipeId) && !selectedFolders.includes(list.name)
      );
    });
    foldersToRemove.forEach((folder: any) => {
      let index = folder.recipes.indexOf(recipeId);
      if (index > -1) {
        folder.recipes.splice(index, 1);
      }

      let arrayIndex = newSavedRecipesArray.findIndex(
        (originalFolder: any) => originalFolder.name === folder.name
      );

      if (newSavedRecipesArray[arrayIndex].recipes.length === 0) {
        newSavedRecipesArray.splice(arrayIndex, 1);
      } else {
        newSavedRecipesArray[arrayIndex].recipes = folder.recipes;
      }
    });

    try {
      // Update the savedRecipes array in User collection
      await setDoc(
        userRef,
        {
          folders_saved: newSavedRecipesArray,
        },
        {
          merge: true,
        }
      );

      // Check if we're completely unsaving the recipe
      if (selectedFolders.length === 0) {
        await updateDoc(specificRecipeRef, {
          numberOfSaves: increment(-1),
          usersSaved: arrayRemove(user!.uid),
        });

        await deleteDoc(
          doc(firestore, "users", user!.uid, "savedRecipes", recipeId)
        );

        // Log recipe unsaved event
        logEvent(analytics, "recipe_unsaved", {
          user: user!.uid,
          recipe: recipeId,
        });

        // If not, then check if the user has already saved this recipe.
      } else {
        // Get recipe details from Firestore
        let recipeSnapshot = await getDoc(specificRecipeRef);
        const recipe: any = recipeSnapshot.data();

        // Check if this user has already saved the recipe
        if (recipe.usersSaved) {
          if (!recipe.usersSaved.includes(user!.uid)) {
            // Increment numberOfSaves field on recipe and update usersSaved array to include User ID
            await updateDoc(specificRecipeRef, {
              numberOfSaves: increment(1),
              usersSaved: arrayUnion(user!.uid),
            });
          }
        } else {
          await updateDoc(specificRecipeRef, {
            numberOfSaves: increment(1),
            usersSaved: arrayUnion(user!.uid),
          });
        }

        // Add a document to recipe subcollection under user
        await setDoc(specificSavedRecipeRef, {
          name: recipe.name,
          savedAt: Date.now(),
          description: recipe.description,
          highlightUrl: recipe.highlightUrl,
          tags: recipe.tags,
          lists: selectedFolders,
        });

        // Log recipe saved event
        logEvent(analytics, "recipe_saved", {
          user: user!.uid,
          recipe: recipeId,
        });
      }
      props.onClose();
    } catch (e) {
      console.log(e);
    }
  };

  if (initializing) {
    return <Body>Loading</Body>;
  }

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Save recipe
          </Header>
          <Body margin={"0 0 1em 0"}>
            Save this recipe to a menu to easily find it whenever you need.
          </Body>
          <hr />
          <Subheading margin={"1.5em 0 0.5em 0"} size={2} color={DARK}>
            Add to Saved
          </Subheading>
          {userFolders.map((listObject: any, i: number) => (
              listObject.name === save_folder_name ?
                  <ListRow
                      key = {i}
                      name = {listObject.name}
                      onSelect = {() => onSelectList(i)}
                      selected={listObject.selected}
                  /> : null
          ))}
          <Subheading margin={"1.5em 0 0.5em 0"} size={2} color={DARK}>
            Add to an existing menu (optional)
          </Subheading>
          {userFolders.map((listObject: any, i: number) => (
              listObject.name !== save_folder_name ?
                <ListRow
                key = {i}
                name = {listObject.name}
                onSelect = {() => onSelectList(i)}
                selected={listObject.selected}
            /> : null
          ))}
          <FormField
              label={"Create a new menu"}
              value={folderName}
              placeholder={"Menu name"}
              error={folderError}
              errorText={"Please enter a name for the Menu"}
              onChange={(e: any) => setFolderName(e.target.value)}
          />
          <Button margin={"2em 0"} onClick={() => onAddList()}>Click here to create the new Menu</Button>
          {/*<StyledLink onClick={() => onAddList()}>Click here to create the new Menu</StyledLink>*/}
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              disabled={
                !previouslySaved && selectedFolders.length === 0 ? true : false
              }
              primary
              onClick={() => onSaveRecipe()}
            >
              Save
            </Button>
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default SaveModal;
