import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// Firebase
import { useStorage } from "reactfire";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

// Styles
import { DARK, RED, DARK_GREEN } from "../../styles/colors";
import { toast } from "react-hot-toast";

// Components
import { Block, Modal, Columns } from "react-bulma-components";
import Header from "../atoms/Header";
import Button from "../atoms/Button";

// Atoms
import Body from "../atoms/Body";
import LoadingSmall from "../atoms/LoadingSmall";
import StepNotes from "../atoms/StepNotes";
import Dropzone from "../atoms/Dropzone";

// Molecules
import FormTextarea from "../molecules/FormTextarea";
import IngredientRow from "../molecules/IngredientRow";
import FormField from "../molecules/FormField";
import ImageCrop from "./ImageCrop";
import { optimizeImage } from "../../utilities";

type Props = {
  openModal: boolean;
  recipeId: string;
  step: any;
  shownIngredients: Array<any>;
  onClose: () => void;
  saveEdit: (shownIngredients: Array<any>, step: any) => any;
};

// Custom Components
const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const StyledIngredientsBlock = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 1em;
`;

const DeleteImageContainer = styled.div`
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  width: 100px;
  background-size: 100%;
  display: flex;
  padding: 0.25em 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const notifyNoteAdded = () => {
  toast("Step note added", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "🎉",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

const notifyNoteError = () => {
  toast("Your note's empty!", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "❗",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

const EditStepModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [notes, setNotes] = useState<Array<string>>([]);
  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [newImageUrl, setNewImageUrl] = useState<string>("");
  const [imageDeleted, setImageDeleted] = useState<boolean>(false);
  const [ingredients, setIngredients] = useState<any>([]);
  const [shownIngredients, setShownIngredients] = useState<Array<any>>([]);

  useEffect(() => {
    setRecipeId(props.recipeId);
    setDescription(props.step.description);
    setImageUrl(props.step.imageUrl);
    setIngredients(props.step.ingredients ? props.step.ingredients : []);
    setShownIngredients(props.shownIngredients ? props.shownIngredients : []);
    setNotes(props.step.notes ? props.step.notes : []);
  }, [
    props.recipeId,
    props.shownIngredients,
    props.step.description,
    props.step.imageUrl,
    props.step.ingredients,
    props.step.notes,
    // image
  ]);

  // Initiatilize Firebase storage
  const storage = useStorage();

  const clearState = () => {
    setRecipeId("");
    setDescription("");
    setNote("");
    setNotes([]);
    setImage(null);
    setImageUrl("");
    setNewImageUrl("");
    setIngredients([]);
    setShownIngredients([]);
  };

  const changeImage = (image: any, imageUrl: string) => {
    setImage(image);
    setImageUrl(imageUrl);
  };

  const onAddTip = (e: any) => {
    e.preventDefault();

    if (note.trim().length > 0) {
      const notesArray = [...notes];
      notesArray.push(note);
      setNotes(notesArray);
      setNote("");
      notifyNoteAdded();
    } else {
      notifyNoteError();
    }
  };

  // Handle the tip input, add tip when the user hits "enter"
  const addTip = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onAddTip(e);
    } else {
      setNote(e.target.value);
    }
  };

  const onDeleteNote = (i: number) => {
    let notesArray = [...notes];
    notesArray.splice(i, 1);
    setNotes(notesArray);
  };

  // Upload Step Image image to Firebase storage
  const uploadRecipeImage = async () => {
    setLoading(true);

    const ext = image.type.substr(image.type.lastIndexOf("/") + 1);

    const stepImageUUID = uuidv4();

    // Initialize storage reference
    const storageRef = ref(
      storage,
      `recipes/${recipeId}/${stepImageUUID}.${ext}`
    );
    try {
      let imageTemp: any = null;
      await optimizeImage(image).then((result: any) => {
        imageTemp = result;
      });
      await uploadBytes(storageRef, imageTemp);
      let url = await getDownloadURL(storageRef);
      return url;
    } catch (e) {
      alert(e);
      return "";
    }
  };

  // Delete ingredient image
  const onDeleteImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setImage(null);
    setImageUrl("");
    setNewImageUrl("");
    setImageDeleted(true);
  };

  // Select ingredients
  const onIngredientSelect = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let ingredientsArray = [...ingredients];
    let ingredient = shownIngredients[i];
    ingredientsArray.push(ingredient);
    setIngredients(ingredientsArray);

    let shownIngredientsArray = [...shownIngredients];
    shownIngredientsArray.splice(i, 1);
    setShownIngredients(shownIngredientsArray);
  };

  const onStepIngredientDelete = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let shownIngredientsArray = [...shownIngredients];
    let ingredient = ingredients[i];
    shownIngredientsArray.push(ingredient);
    setShownIngredients(shownIngredientsArray);

    let ingredientsArray = [...ingredients];
    ingredientsArray.splice(i, 1);
    setIngredients(ingredientsArray);
  };

  const onSaveEdit = async () => {
    // Set image url as current state
    let newUrl = imageUrl;

    // If image has been deleted, delete previous step image
    if (imageDeleted) {
      if (props.step.imageUrl !== "") {
        const imageRef = ref(storage, props.step.imageUrl);
        try {
          await deleteObject(imageRef);
        } catch (error) {
          console.error(error);
        }
      }
    }

    // Check if user has changed the image for the step
    if (image != null) {
      // Delete image if there was something there before
      if (newUrl !== "") {
        const imageRef = ref(storage, newUrl);
        try {
          await deleteObject(imageRef);
        } catch (error) {
          console.error(error);
        }
      }

      newUrl = await uploadRecipeImage();
    }

    let stepObject = {
      description: description,
      imageUrl: newUrl,
      ingredients: ingredients,
      notes: notes,
    };

    clearState();
    setLoading(false);
    props.saveEdit(shownIngredients, stepObject);
  };

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Edit step
          </Header>
          <hr />
          <FormTextarea
            label="Step description"
            value={description}
            placeholder="The description of each step should be written as clearly as possible and should identify specific actions associated with the recipe. We recommend that you create separate steps based on timing and actions. For instance, here is the first step of a mushroom omelette. Step 1: heat the skillet on medium heat and then add butter. Once the butter has fully melted add chopped mushrooms and minced garlic and saute for 4-5 mins stirring frequently until cooked. Take off heat and let sit."
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <FormField
            label="Any helpful tips you want to add?"
            text={`Example tip: You can substitute tofu for the protein to make the dish vegan!`}
            value={note}
            placeholder="Add any helpful tip you want the viewer to know about"
            onKeyDown={(e: any) => addTip(e)}
            onChange={(e: any) => addTip(e)}
          />
          {notes.length > 0 ? (
            <StepNotes
              onDelete={(i: number) => onDeleteNote(i)}
              deletable
              notes={notes}
            />
          ) : null}
          <Body bold margin={"1.5em 0 1em 0"} color={DARK_GREEN}>
            Step image
          </Body>
          <Dropzone
            onFileAccept={(image: any, imageUrl: string) => {
              changeImage(image, imageUrl);
            }}
          />
          {imageUrl || newImageUrl ? (
            <div style={{ position: "relative" }}>
              <DeleteImageContainer
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onDeleteImage(e)
                }
              >
                <FontAwesomeIcon
                  color={RED}
                  size={"1x"}
                  style={{ cursor: "pointer" }}
                  icon={faTimesCircle}
                />
                <Body color={RED}> Delete</Body>
              </DeleteImageContainer>
              <ImageCrop
                cropWidth={75}
                imageUrl={newImageUrl ? newImageUrl : imageUrl}
                onFinishedCropping={(croppedFile: any) => {
                  setImage(croppedFile);
                }}
              />
            </div>
          ) : null}
          <Columns>
            <Columns.Column size="half">
              <Body margin={"0 0 1em 0"} color={DARK}>
                Available ingredients
              </Body>
              {shownIngredients.length > 0 ? (
                <StyledIngredientsBlock>
                  {shownIngredients.map((ingredient: any, i: number) => (
                    <IngredientRow
                      key={i}
                      onSelect={(e: React.MouseEvent<SVGSVGElement>) =>
                        onIngredientSelect(e, i)
                      }
                      ingredient={ingredient}
                      selectable
                    />
                  ))}
                </StyledIngredientsBlock>
              ) : null}
            </Columns.Column>
            <Columns.Column>
              <Body margin={"0 0 1em 0"} color={DARK}>
                Ingredients needed in this step
              </Body>
              {ingredients.map((ingredient: any, i: number) => (
                <IngredientRow
                  key={i}
                  onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                    onStepIngredientDelete(e, i)
                  }
                  ingredient={ingredient}
                  deletable
                />
              ))}
            </Columns.Column>
          </Columns>
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            {loading ? (
              <LoadingSmall />
            ) : (
              <Button
                disabled={description === "" ? true : false}
                primary
                onClick={() => onSaveEdit()}
              >
                Save
              </Button>
            )}
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default EditStepModal;
