import {useEffect, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faBookmark} from "@fortawesome/free-regular-svg-icons";
import {
    faHeart as solidHeart,
    faBookmark as solidBookmark,
    faShare, faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {DragPreviewImage, useDrag} from "react-dnd";
import {ItemTypes} from "../../global";

// Firestore
import {
    doc,
    updateDoc,
    setDoc,
    increment,
    arrayRemove,
    arrayUnion,
    deleteDoc,
} from "@firebase/firestore";
import {logEvent} from "@firebase/analytics";
import {
    useUser,
    useFirestore,
    useFirestoreDocData,
    useAnalytics,
} from "reactfire";

// Components
import {Columns, Block} from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Tag from "../atoms/Tag";

// Styles
import {RED, WHITE, DARK, GREY, DARK_GREEN} from "../../styles/colors";
import React from "react";
import {dragPreview} from "../../recipeDragImage";

type Props = {
    id: string;
    onTagClick?: (tag: string) => void;
    onUserClick?: (userName: string) => void;
    onShare: (id: string) => void;
    onSave: (id: string) => void;
    onRemoveFromFolder: (id: string) => void;
    folderView?: boolean;

};

const StyledImage = styled.div.attrs((props: any) => ({
    recipeHighlightUrl: props.recipeHighlightUrl || null,
}))`
  background: ${(props) =>
          `url(${props.recipeHighlightUrl}) no-repeat center center;`};
  background-size: 100%;
  height: 200px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
    profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
          `url(${props.profileHighlightUrl}) no-repeat center center;`};
  background-size: 100%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const StyledBox = styled.div`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`;

const StyledMissingBox = styled.div`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1em;
`;

const StyledCreatorBlock = styled.div`
  //position: relative;
  background-color: ${WHITE};
  border-radius: 30px;
  padding-right: 1em;
  margin-top: 1em;
  margin-left: 1em;

  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-top: 0.5em;
`;

const StyledTagContainer = styled(Block)`
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
`;

const ActionContainer = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const HeaderContainerRelative = styled(Block)`
  position: relative;
`;
const HeaderContainerAbsolute = styled(Block)`
  position: absolute;
  width: 100%;
`;

const HeaderFlexContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const HeaderItemRight = styled(Block)`
  align-items: flex-end;
  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;
const HeaderItemLeft = styled(Block)`
  align-items: flex-start;
  :hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  align-items: center;
`;

const Recipe = (props: Props) => {
    const [truncatedDescription, setTruncatedDescription] = useState<String>("");
    const [liked, setLiked] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [tags, setTags] = useState<Array<string>>([]);

    const firestore = useFirestore();
    const {data: user} = useUser();
    const userId = user!.uid;

    const likedRecipesRef = doc(
        firestore,
        "users",
        userId,
        "likedRecipes",
        props.id
    );

    const recipeRef = doc(firestore, "recipes", props.id);
    const {status, data} = useFirestoreDocData<any>(recipeRef);

    const analytics = useAnalytics();

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: ItemTypes.RECIPE,
        item: {
            recipeId: props.id,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        if (data) {
            // Truncate description length
            const descriptionLength = 140;
            let descriptionDisplayText = "";
            if (data.description) {
                descriptionDisplayText = `${data.description.substring(
                    0,
                    descriptionLength
                )}${data.description.length > descriptionLength ? "..." : ""}`;
            }
            setTruncatedDescription(descriptionDisplayText);

            // Set if user liked a recipe
            if (data.usersLiked) {
                if (data.usersLiked.includes(userId)) {
                    setLiked(true);
                }
            }

            // Set if user has saved this recipe
            if (data.usersSaved) {
                if (data.usersSaved.includes(userId)) {
                    setSaved(true);
                }
            }

            if (data.tags) {
                if (data.tags.length > 0) {
                    setTags(data.tags);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, userId]);

    const onLikeRecipe = async (
        e: React.MouseEvent<SVGSVGElement>,
        id: string
    ) => {
        e.preventDefault();
        // Update firestore documents
        if (updating === false) {
            setUpdating(true);
            try {
                // Increment numberOfLikes field on recipe
                await updateDoc(recipeRef, {
                    numberOfLikes: increment(1),
                    usersLiked: arrayUnion(userId),
                });

                // Add a document to recipe subcollection under user
                await setDoc(likedRecipesRef, {
                    name: data.name,
                    likedAt: Date.now(),
                    description: data.description,
                    highlightUrl: data.highlightUrl,
                    tags: data.tags,
                });

                // Log event to Firebase analytics
                logEvent(analytics, "recipe_liked", {
                    user: userId,
                    recipe: props.id,
                });
                setUpdating(false);
            } catch (e) {
                console.log(e);
                setUpdating(false);
            }
        }
    };

    const onDislikeRecipe = async (
        e: React.MouseEvent<SVGSVGElement>,
        id: string
    ) => {
        e.preventDefault();
        // Update firestore documents
        if (!updating) {
            setUpdating(true);
            try {
                // Decrement numberOfLikes field on recipe
                updateDoc(recipeRef, {
                    numberOfLikes: increment(-1),
                    usersLiked: arrayRemove(userId),
                });

                // Remove the document from recipe subcollection under user
                await deleteDoc(
                    doc(firestore, "users", userId, "likedRecipes", props.id)
                );

                // Setting user like as false client side
                setLiked(false);
                setUpdating(false);
            } catch (e) {
                console.log(e);
                setUpdating(false);
            }
        }
    };

    const onUserClick = (name: string) => {
        if (props.onUserClick) {
            props.onUserClick(name);
        }
    };

    const onTagClick = (tag: string) => {
        if (props.onTagClick) {
            props.onTagClick(tag);
        }
    };

    if (status === "loading") {
        return <Block/>;
    }

    if (status === "success" && data === undefined) {
        return (
            <Columns.Column size="one-third">
                <StyledMissingBox>
                    <Header margin={"0 0 0.5em 0"} size={4}>
                        😭 Sorry!
                    </Header>
                    <Body>This recipe is no longer available</Body>
                </StyledMissingBox>
            </Columns.Column>
        );
    }

    return (
        <Columns.Column size="one-third">
            {/*<DragPreviewImage connect={preview} src={dragPreview} />*/}
            <StyledBox
                ref={drag}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    cursor: "move",
                }}
            >
                <HeaderContainerRelative>
                    <HeaderContainerAbsolute>
                    <HeaderFlexContainer>
                    <HeaderItemLeft>
                {data.creator ? (
                    <StyledCreatorBlock
                        onClick={() => onUserClick(data.creator.name)}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <StyledProfileImage profileHighlightUrl={data.creator.profileUrl}/>
                        <Body color={DARK}>{data.creator.name}</Body>
                    </StyledCreatorBlock>
                ) : null}
                    </HeaderItemLeft>
                        <HeaderItemRight>
                    {props.folderView ?
                        <FontAwesomeIcon
                            style={{cursor: "pointer", marginTop:'15px', marginRight: '15px'}}
                            onClick={() => props.onRemoveFromFolder(props.id)}
                            icon={faWindowClose}
                            size={"lg"}
                            color={WHITE}
                        />
                        : null
                    }
                    </HeaderItemRight>
                    </HeaderFlexContainer>
                    </HeaderContainerAbsolute>
                </HeaderContainerRelative>

                <Link
                    to={{
                        pathname: `/recipe/${props.id}`,
                        state: {
                            id: props.id,
                        },
                    }}
                >
                    {!(data.highlightUrl === "") ? (
                        <StyledImage recipeHighlightUrl={data.highlightUrl}/>
                    ) : (
                        <StyledImage
                            recipeHighlightUrl={
                                "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/recipes%2Fplaceholder.jpg?alt=media&token=75ca0156-8e6f-49fb-9c24-c3ec6e64a56a"
                            }
                        />
                    )}
                </Link>
                    <StyledBlock>
                    <Link
                        to={{
                            pathname: `/recipe/${props.id}`,
                            state: {
                                id: props.id,
                            },
                        }}
                    >
                        <Header margin={"0.5em 0"} size={4}>
                            {data.name}
                        </Header>
                    </Link>
                    <ActionContainer>
                        <ActionItem>
                            {liked ? (
                                <FontAwesomeIcon
                                    style={{cursor: "pointer"}}
                                    onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                                        onDislikeRecipe(e, props.id)
                                    }
                                    icon={solidHeart}
                                    size={"lg"}
                                    color={RED}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{cursor: "pointer"}}
                                    onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                                        onLikeRecipe(e, props.id)
                                    }
                                    icon={faHeart}
                                    size={"lg"}
                                    color={GREY}
                                />
                            )}
                            <Body margin={"0 0 0 .5em"}>
                                {data.numberOfLikes ? data.numberOfLikes : 0} likes
                            </Body>
                        </ActionItem>
                        <ActionItem>
                            {saved ? (
                                <FontAwesomeIcon
                                    style={{cursor: "pointer"}}
                                    onClick={() => props.onSave(props.id)}
                                    icon={solidBookmark}
                                    size={"lg"}
                                    color={DARK_GREEN}
                                />
                            ) : (
                                <FontAwesomeIcon
                                    style={{cursor: "pointer"}}
                                    onClick={() => props.onSave(props.id)}
                                    icon={faBookmark}
                                    size={"lg"}
                                    color={GREY}
                                />
                            )}
                            <Body margin={"0 0 0 .5em"}>
                                {data.numberOfSaves ? data.numberOfSaves : 0}
                            </Body>
                        </ActionItem>
                        {/*<ActionItem>*/}
                        {/*    <FontAwesomeIcon*/}
                        {/*        style={{cursor: "pointer"}}*/}
                        {/*        onClick={() => props.onShare(props.id)}*/}
                        {/*        icon={faShare}*/}
                        {/*        size={"lg"}*/}
                        {/*        color={GREY}*/}
                        {/*    />*/}
                        {/*</ActionItem>*/}
                    </ActionContainer>
                        <Link
                            to={{
                                pathname: `/recipe/${props.id}`,
                                state: {
                                    id: props.id,
                                },
                            }}
                        >
                    <Body margin={"0 0 0.5em 0"}>{truncatedDescription}</Body>
                    </Link>
                <StyledTagContainer>
                        {tags.map((tag: any, i: any) => (
                            <Tag onClick={() => onTagClick(tag)} key={i}>
                                #{tag}
                            </Tag>
                        ))}
                    </StyledTagContainer>
                </StyledBlock>
            </StyledBox>
        </Columns.Column>
    );
};

export default Recipe;
