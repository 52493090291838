// Components
import { Block, Form } from "react-bulma-components";
import Textarea from "../atoms/Textarea";

// Components
import Body from "../atoms/Body";

// Styles
import { DARK_GREEN } from "../../styles/colors";

type Props = {
  label?: string;
  value: any;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const FormField = (props: Props) => {
  return (
    <Block>
      {props.label ? (
        <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
          {props.label}
        </Body>
      ) : null}
      <Form.Field style={{ marginTop: 8 }} kind="group">
        <Form.Control fullwidth={true}>
          <Textarea
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
          />
        </Form.Control>
      </Form.Field>
    </Block>
  );
};

export default FormField;
