import { doc } from "@firebase/firestore";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";

// Components
import { Columns, Block } from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";

// Styles
import { BLUE, RED, WHITE } from "../../styles/colors";

type Props = {
  id: string;
};

const StyledBox = styled.div`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 0.5em;
`;

const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-top: 0.5em;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
`;

const StatsItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
`;

const Group = (props: Props) => {
  const [truncatedDescription, setTruncatedDescription] = useState<String>("");

  const groupRef = doc(useFirestore(), "groups", props.id);
  const { status, data } = useFirestoreDocData<any>(groupRef);

  useEffect(() => {
    if (data) {
      // Truncate description length
      const descriptionLength = 140;
      let descriptionDisplayText = "";
      if (data.description) {
        descriptionDisplayText = `${data.description.substring(
          0,
          descriptionLength
        )}${data.description.length > descriptionLength ? "..." : ""}`;
      }
      setTruncatedDescription(descriptionDisplayText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (status === "loading") {
    return <Block />;
  }

  return (
    <Columns.Column size="one-third">
      <StyledBox>
        <StyledBlock>
          <Link
            to={{
              pathname: `/group/${props.id}`,
              state: {
                id: props.id,
              },
            }}
          >
            <Header margin={"0 0 0.5em 0"} size={4}>
              {data.name}
            </Header>
          </Link>
          <StatsContainer>
            <StatsItem>
              <FontAwesomeIcon
                style={{ marginRight: "0.25em" }}
                icon={faUserCircle}
                size={"lg"}
                color={BLUE}
              />
              <Body color={BLUE}>{data.numberOfMembers}</Body>
            </StatsItem>
            <StatsItem>
              <FontAwesomeIcon
                style={{ marginRight: "0.25em" }}
                icon={faComment}
                size={"lg"}
                color={RED}
              />
              <Body color={RED}>
                {data.numberOfPosts ? data.numberOfPosts : 0}
              </Body>
            </StatsItem>
          </StatsContainer>
          <Body>{truncatedDescription}</Body>
        </StyledBlock>
      </StyledBox>
    </Columns.Column>
  );
};

export default Group;
