import React from "react";
import styled from "styled-components";
import { Form } from "react-bulma-components";

import { GREY, GREEN_INPUT_BORDER, LIGHT_GREEN } from "../../styles/colors";

type Props = {
  value: any;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  type?: string;
};

const StyledInput = styled(Form.Input)`
  font-family: Inter;
  background-color: ${LIGHT_GREEN};
  border-radius: 10px;
  height: 55px;
  border: 1px solid ${GREEN_INPUT_BORDER};
  color: ${GREY};

  &:hover {
    border: 1px solid ${GREY};
  }

  &:active {
    border: 1px solid ${GREY};
  }
`;

export default function Input(props: Props) {
  return (
    <StyledInput
      onKeyDown={props.onKeyDown}
      type={props.type}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
}
