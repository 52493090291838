import { useEffect, useState } from "react";
import { Section, Container, Block, Columns } from "react-bulma-components";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";

// Firebase
import {
  doc,
  collection,
  query,
  getDocs,
  orderBy,
  updateDoc,
  setDoc,
  arrayRemove,
  increment,
  arrayUnion,
} from "@firebase/firestore";
import { useUser, useFirestore, useFirestoreDocData } from "reactfire";

// Atoms
import Body from "../../components/atoms/Body";
import Header from "../../components/atoms/Header";
import Subheader from "../../components/atoms/Subheading";
import Button from "../../components/atoms/Button";
import OutlineButton from "../../components/atoms/OutlineButton";
import Loading from "../../components/atoms/Loading";

// Molecules
import CreatorRow from "../../components/molecules/CreatorRow";
import Post from "../../components/molecules/Post";

// Organisms
import SharePostModal from "../../components/organisms/SharePostModal";

// Styles
import { WHITE, DARK, BLUE, DARK_GREEN, RED } from "../../styles/colors";
import {GroupsViewOnBoarding} from "../onboarding/groups/GroupsViewOnBoarding";

const StyledCTA = styled.a`
  color: ${BLUE};
  font-weight: bold;
  font-size: 18px;
  margin-right: 16px;

  &:hover {
    color: ${BLUE};
    opacity: 70%;
  }
`;

const TitleContainer = styled(Block)`
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionItem = styled.div`
  display: flex;
  display: column;
  justify-content: center;
  margin-right: 1em;
`;

const CreatorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-right: 1.5em;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`}
  background-size: 100%;
  height: 30px;
  min-width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const MainContent = styled(Block)`
  margin-top: 1.5em;
`;

const MemberContainer = styled(Block)`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1em 1.5em;
`;

const PostContainer = styled(Block)`
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`;

const GroupView = () => {
  const [userId, setUserId] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);
  const [joined, setJoined] = useState<boolean>(false);
  const [invited, setInvited] = useState<boolean>(false);
  const [posts, setPosts] = useState<Array<any>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  // Get react-router-dom state
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  // Firestore Initialization
  const firestore = useFirestore();

  // React Fire Groups Record
  const groupRef = doc(firestore, "groups", id);
  const { status, data } = useFirestoreDocData<any>(groupRef);

  // Get current user
  const { data: user } = useUser();
  const userCollectionRef = collection(firestore, "users");

  // React Fire Group Posts Record
  const postsCollectionRef = collection(firestore, "groups", id, "posts");
  const postsQuery = query(postsCollectionRef, orderBy("postDate", "desc"));

  useEffect(() => {
    if (data && user) {
      if (data.members.length > 0) {
        data.members.forEach((member: any) => {
          // Check if current user has already joined this group. If so, set joined state to true
          if (member.id === user.uid) {
            setJoined(true);
          }
        });
      } else {
        setJoined(false);
      }
      setUserId(user.uid);
    }

    let postsArray: Array<any> = [];

    const getPosts = async () => {
      const postsSnapshot = await getDocs(postsQuery);
      postsSnapshot.forEach((doc: any) => {
        postsArray.push({ ...doc.data(), id: doc.id });
      });
      setPosts(postsArray);
    };

    getPosts();
  }, [data, postsQuery, user]);

  const onInviteClick = () => {
    setInvited(!invited);
    navigator.clipboard.writeText(window.location.href);
  };

  const onJoinClick = async () => {
    let membersArray = data.members;
    let userRef = doc(userCollectionRef, userId);

    if (updating === false) {
      setUpdating(true);

      if (joined) {
        // Leave the group
        setJoined(!joined);
        let index = membersArray.findIndex(
          (member: any) => member.id === user!.uid
        );
        membersArray.splice(index, 1);
        try {
          // Remove group from user document
          await updateDoc(userRef, {
            joinedGroups: arrayRemove(id),
          });

          // Add user id to members array within group document
          await setDoc(
            groupRef,
            {
              members: membersArray,
            },
            {
              merge: true,
            }
          );

          // Decrement the number of members within the group document
          await updateDoc(groupRef, {
            numberOfMembers: increment(-1),
          });
        } catch (e) {
          alert(e);
        }
      } else {
        // Join the group
        membersArray.push({
          id: user!.uid,
          joinedDate: new Date(),
        });

        try {
          // Add group to user document
          await updateDoc(userRef, {
            joinedGroups: arrayUnion(id),
          });

          // Add user id to members array within group document
          await setDoc(
            groupRef,
            {
              members: membersArray,
            },
            {
              merge: true,
            }
          );

          // Increment the number of members within the group document
          await updateDoc(groupRef, {
            numberOfMembers: increment(1),
          });
        } catch (e) {
          alert(e);
        }
      }
      setUpdating(false);
    }
  };

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <Section>
      <GroupsViewOnBoarding run={true}/>
      <Block>
        <Container>
          <Block
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledCTA onClick={() => history.goBack()}>
              Back to groups
            </StyledCTA>
          </Block>
          <TitleContainer>
            <Header margin={"0.5em 0"} size={2}>
              {data.name}
            </Header>
            <ActionContainer>
              <ActionItem>
                <FontAwesomeIcon
                  style={{ marginRight: "0.25em" }}
                  icon={faUserCircle}
                  size={"lg"}
                  color={BLUE}
                />
                <Body color={BLUE}>{data.numberOfMembers} member(s)</Body>
              </ActionItem>
              <ActionItem>
                <FontAwesomeIcon
                  style={{ marginRight: "0.25em" }}
                  icon={faComment}
                  size={"lg"}
                  color={RED}
                />
                <Body color={RED}>{data.numberOfPosts} post(s)</Body>
              </ActionItem>
            </ActionContainer>
            <Subheader margin={"1em 0"} size={2}>
              {data.description}
            </Subheader>
            <Block
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CreatorBlock>
                <StyledProfileImage
                  profileHighlightUrl={data.creator.profileUrl}
                />
                <Subheader color={DARK} size={2}>
                  {data.creator.name}
                </Subheader>
              </CreatorBlock>
              <div>
              <OutlineButton
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  onJoinClick()
                }
                active={joined}
              >
                {joined ? "Joined" : "Join group"}
              </OutlineButton>
              </div>
              <div>
              <OutlineButton
                margin={"0 0 0 1em"}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  onInviteClick()
                }
                active={invited}
              >
                {invited ? "Copied" : "Invite"}
              </OutlineButton>
              </div>
            </Block>
          </TitleContainer>
          <hr />
          <MainContent>
            <Columns>
              <Columns.Column size="one-third">
                <MemberContainer>
                  <Body margin={"0 0 1em 0"} bold color={DARK_GREEN}>
                    Group members
                  </Body>
                  <hr />
                  {data.members.map((member: any) => {
                    return <CreatorRow key={member.id} id={member.id} />;
                  })}
                </MemberContainer>
              </Columns.Column>
              <Columns.Column size="two-thirds">
                <Block
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Header margin={"0"} size={4}>
                    Posts
                  </Header>
                  <Button onClick={() => setOpenModal(true)} primary>
                    Share a recipe
                  </Button>
                </Block>
                <PostContainer>
                  {posts.map((post: any) => {
                    return <Post key={post.id} post={post} />;
                  })}
                </PostContainer>
              </Columns.Column>
            </Columns>
          </MainContent>
        </Container>
      </Block>
      <SharePostModal
        groupId={id}
        openModal={openModal}
        onClose={() => setOpenModal(false)}
      />
    </Section>
  );
};

export default GroupView;
