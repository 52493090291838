import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Firebase
import {
  doc,
  collection,
  increment,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import {
  useUser,
  useFirestore,
  useFirestoreDocData,
  useAnalytics,
} from "reactfire";

// Components
import { Block, Modal } from "react-bulma-components";
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Subheading from "../atoms/Subheading";
import Button from "../atoms/Button";

// Molecules
import FormTextarea from "../molecules/FormTextarea";
import GroupSelect from "../molecules/GroupSelect";

// Styles
import { DARK } from "../../styles/colors";

type Props = {
  openModal: boolean;
  onClose: () => void;
  recipeId: string;
};

const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

const ShareModal = (props: Props) => {
  const [recipeId, setRecipeId] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [groups, setGroups] = useState<Array<string>>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [initializing, setInitializing] = useState<boolean>(true);

  const firestore = useFirestore();
  const { data: user } = useUser();

  const userRef = doc(firestore, "users", user!.uid);
  const { data: userData } = useFirestoreDocData<any>(userRef);
  const { data } = useFirestoreDocData<any>(userRef);

  const groupCollectionRef = collection(firestore, "groups");

  const analytics = useAnalytics();

  useEffect(() => {
    if (userData) {
      if (userData.joinedGroups) {
        let joinedGroups = userData.joinedGroups;
        setGroups(joinedGroups);
      }
    }

    setRecipeId(props.recipeId);
    setInitializing(false);
  }, [data, props, userData]);

  const onSharePost = async () => {
    if (selectedGroup !== "") {
      let groupRef = doc(groupCollectionRef, selectedGroup);

      let postId = uuidv4();

      let postRef = doc(groupRef, "posts", postId);

      try {
        // Set the post document within the group collection
        await setDoc(postRef, {
          user: {
            id: user!.uid,
            name: userData.name,
            profileUrl: userData.profileUrl,
          },
          recipeId: recipeId,
          comment: comment,
          postDate: new Date(),
        });

        // Update the number of posts field within the group document
        await updateDoc(groupRef, {
          numberOfPosts: increment(1),
        });

        // Log Share Post Event
        logEvent(analytics, "group_share_post", {
          user: user!.uid,
        });

        props.onClose();
      } catch (e) {
        alert(e);
      }
    }
  };

  if (initializing) {
    return <Body>Loading</Body>;
  }

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Share recipe
          </Header>
          <Body margin={"0 0 1em 0"}>
            Share this recipe with any group you're in.
          </Body>
          <hr />
          <FormTextarea
            label={"Post a comment"}
            value={comment}
            placeholder={"Write a quick comment"}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setComment(e.target.value)
            }
          />
          <Subheading margin={"1em 0 0.5em 0"} size={2} color={DARK}>
            Your groups
          </Subheading>
          <GroupContainer>
            {groups.map((groupId: any) => (
              <GroupSelect
                active={groupId === selectedGroup ? true : false}
                onClick={() => setSelectedGroup(groupId)}
                id={groupId}
                key={groupId}
              />
            ))}
          </GroupContainer>
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button primary onClick={() => onSharePost()}>
              Share
            </Button>
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default ShareModal;
