import React from "react";
import { ReactSVG } from "react-svg";
import { useHistory } from "react-router-dom";

// Components
import { Block, Columns, Section, Container } from "react-bulma-components";
import Header from "../components/atoms/Header";
import Subheading from "../components/atoms/Subheading";
import Body from "../components/atoms/Body";
import Button from "../components/atoms/Button";
import BetaSignUp from "../components/organisms/BetaSignUp";
import Footer from "../components/organisms/Footer";
import { BLUE_BG, GREY } from "../styles/colors";
import {LandingPageOnBoarding} from "./onboarding/LandingOnBoarding";

const heroUrl =
  "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Fhero-image.svg?alt=media&token=b989d72f-fb9d-4d23-9098-82acf829b20e";

export default function LandingPage() {
  const history = useHistory();

  const onSignUp = (e: Event) => {
    e.preventDefault();

    history.push("/login");
  };

  return (
    <>
      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <Block>
                <Header font={"New York"} margin={"1em 0"} size={1}>
                  All your favorite recipes, in one place
                </Header>
                <Subheading size={2} color={GREY}>
                  Easily organize and share your favorite recipes
                </Subheading>
              </Block>
              <Block>
                <Button primary onClick={(e: any) => onSignUp(e)}>
                  Join Zesty!
                </Button>
              </Block>
            </Columns.Column>
            <Columns.Column>
              <ReactSVG height={540} src={heroUrl} />
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Section style={{ backgroundColor: BLUE_BG }}>
        <Container
          style={{
            maxWidth: 940,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Section>
            <Columns>
              <Columns.Column>
                <ReactSVG
                  height={250}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Fexplore.svg?alt=media&token=37ce2ba4-ffa4-4a31-a712-2e7b24e01642"
                  }
                />
              </Columns.Column>
              <Columns.Column>
                <Header size={4}>Organize your recipes</Header>
                <Body>
                  Get all of your recipes in one place. No more loose recipe
                  pages, bookmarks, saved pdfs or text messages.
                </Body>
              </Columns.Column>
            </Columns>
          </Section>
          <Section>
            <Columns>
              <Columns.Column>
                <ReactSVG
                  height={250}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Fconfidence.svg?alt=media&token=1a178108-e1df-4a5b-aaa7-d29458c2e5ab"
                  }
                />
              </Columns.Column>
              <Columns.Column>
                <Header size={4}>Easily create and save your recipes</Header>
                <Body>
                  Use our intuitive and quick recipe creation flow to save your
                  recipes in an organized fashion: Just follow our simple
                  step-by-step walkthrough.
                </Body>
              </Columns.Column>
            </Columns>
          </Section>
          <Section>
            <Columns>
              <Columns.Column>
                <ReactSVG
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Ffollow.svg?alt=media&token=2271422d-c06c-404b-a97e-0fe84d0be38a"
                  }
                />
              </Columns.Column>
              <Columns.Column>
                <Header size={4}>
                  Find and view your recipes, whenever you need them
                </Header>
                <Body>
                  Use our search bar to quickly find your recipes, and easily
                  follow them while you’re cooking.
                </Body>
              </Columns.Column>
            </Columns>
          </Section>
        </Container>
      </Section>
      <Section>
        <Container
          style={{
            maxWidth: 940,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Section>
            <Columns>
              <Columns.Column>
                <Header size={4}>
                  Share recipes with your friends and family with ease
                </Header>
                <Body>
                  Send anybody a link to your recipe for them to view. No signup
                  required on their part.
                </Body>
              </Columns.Column>
              <Columns.Column>
                <ReactSVG
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Ffamily.svg?alt=media&token=a044716e-020f-4fec-8a43-ed1105a35320"
                  }
                />
              </Columns.Column>
            </Columns>
          </Section>
          <Section>
            <Columns>
              <Columns.Column>
                <Header size={4}>
                  Invite your friends and family to groups
                </Header>
                <Body>
                  Create private groups for your closest friends & family. Use
                  these groups to organize menus for upcoming trips or
                  celebrations, or to share personal family heirloom recipes.
                </Body>
              </Columns.Column>
              <Columns.Column>
                <ReactSVG
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/landing-page%2Fshare.svg?alt=media&token=3be87ebd-e49c-4f0a-bcb0-05045b37063f"
                  }
                />
              </Columns.Column>
            </Columns>
          </Section>
        </Container>
      </Section>
      <BetaSignUp />
      <Footer />
    </>
  );
}
