import React from "react";
import styled from "styled-components";

// Styles
import {
  LIGHT_GREY,
  BLUE,
  GREEN_INPUT_BORDER,
  RED,
  WHITE,
} from "../../styles/colors";

import Body from "./Body";

type Props = {
  children: any;
  primary?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  height?: number | string;
  margin?: string;
  disabled?: boolean;
  hidden?: boolean;
};

const StyledButton = styled("button")<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.primary ? RED : WHITE)};
  border: ${(props) => (props.primary ? "none" : GREEN_INPUT_BORDER)};
  height: ${(props) => (props.height ? props.height : "50px")};
  display: ${(props) => (props.hidden ? "none" : "inherit")};
  padding: 0.5em 1em;
  border-radius: 10px;
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : null)};
  min-width: 90px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

  &:disabled {
    background-color: ${LIGHT_GREY};
    pointer-events: none;
    color: ${WHITE};
  }
`;

export default function Button(props: Props) {
  const getTextColor = () => {
    if (props.disabled) {
      return WHITE;
    }

    if (props.primary) {
      return WHITE;
    } else {
      return BLUE;
    }
  };
  return (
    <StyledButton
      disabled={props.disabled}
      margin={props.margin}
      height={props.height}
      primary={props.primary}
      hidden={props.hidden}
      onClick={props.onClick}
    >
      <Body color={getTextColor()} bold>
        {props.children}
      </Body>
    </StyledButton>
  );
}
