import Resizer from "react-image-file-resizer";
import {imageWidth, imageHeight, format, quality} from "./global"

export const optimizeImage = (file: any) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            imageWidth,
            imageHeight,
            format,
            quality,
            0,
            (uri: any) => {
                resolve(uri);
            },
            "blob"
        );
        console.log("Image Resize Completed")
    });
