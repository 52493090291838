import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {algoliaSearchDelay, save_folder_name} from "../../global";
import {Configure, connectStateResults, InstantSearch} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import styled from "styled-components";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {toast} from "react-hot-toast";

// Firebase
import {useFirestore,useAnalytics, useFirestoreDocData, useFirestoreDocDataOnce, useUser} from "reactfire";
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    increment,
    setDoc,
    updateDoc
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";


// Components
import {Block, Columns} from "react-bulma-components";
import Button from "../../components/atoms/Button";
import Filter from "../../components/atoms/Filter";
import Header from "../../components/atoms/Header";
import Subheader from "../../components/atoms/Subheading";
import HitsPublic from "../../components/organisms/HitsPublic";
import AlgoliaSearch from "../../components/organisms/AlgoliaSearch";
import SaveModal from "../../components/organisms/SaveModal";
import ShareModal from "../../components/organisms/ShareModal";
import Loading from "../../components/atoms/Loading";
import Folder from "../../components/molecules/Folder";

// Styles
import ScrapeRecipeModal from "../../components/organisms/ScrapeRecipeModal";
import {GREY, RED} from "../../styles/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import AddFolderModal from "../../components/organisms/AddFolderModal";
import ConfirmFolderDeleteModal from "../../components/organisms/ConfirmFolderDeleteModal";
import InfiniteHits from "../../components/organisms/InfiniteHits";
import {createNullCache} from "@algolia/cache-common";
import firebase from "firebase/compat";

const SearchBarContainer = styled.div`
  &:hover {
    border-color: ${GREY};
  }
`;

const recipeAdded = () => {
    toast("Recipe added to menu", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "🎉",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};

const folderAdded = () => {
    toast("Menu added", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "🎉",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};


const recipeRemoved = () => {
    toast("Recipe removed from menu", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "❗",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};

const folderRemoved = () => {
    toast("Menu Removed", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "❗",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};

const recipeAlreadyInFolderError = () => {
    toast("This recipe's already in the menu!", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "❗",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};

const folderAlreadyExistsError = () => {
    toast("You already have a Menu with this name, please use a different name!", {
        duration: 4000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        icon: "❗",
        // Change colors of success/error/loading icon
        iconTheme: {
            primary: "#000",
            secondary: "#fff",
        },
        // Aria
        ariaProps: {
            role: "status",
            "aria-live": "polite",
        },
    });
};

export default function SavedRecipes() {
    const [query, setQuery] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openNewFolderModal, setOpenNewFolderModal] = useState<boolean>(false);
    const [deleteFolderModal, setDeleteFolderModal] = useState<boolean>(false);
    const [shareModal, setShareModal] = useState<boolean>(false);
    const [folders, setFolders] = useState<Array<any>>([]);
    const [recipeId, setRecipeId] = useState<string>("");
    const [activeFolderSelection, setActiveFolderSelection] = useState<string>(save_folder_name);
    const [folderItems, setFolderItems] = useState<Array<string>>([]);
    const [filter, setFilter] = useState<string>("All");
    const [algoliaFilter, setAlgoliaFilter] = useState<string>("private:false AND completed:true");
    const folders_mutable: any = useRef([]);
    const [scrapeModal, setScrapeModal] = useState<boolean>(false);
    const [mode, setMode] = useState<string>("");
    const [index, setIndex] = useState<string>("");




    // Firestore initialization
    const firestore = useFirestore();
    const analytics = useAnalytics();

    // Firestore user data
    const {status, data: user} = useUser();
    const userRef = doc(firestore, "users", user!.uid);
    const {data: userData} = useFirestoreDocData(userRef);
    const mySavedRecipesRef = collection(firestore, "users", user!.uid, "savedRecipes");
    const recipeRef = collection(firestore, "recipes");




    useEffect(() => {
        setQuery("");
    }, []);

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            console.log("PROD")
            setIndex("recipes_created_at_desc");
        } else {
            console.log("DEV")
            setIndex("recipes_dev");
        }

    }, []);


    useEffect(() => {
        // Set user's folders into state
        if (userData) {
            if (userData.folders_saved) {
                folders_mutable.current = userData.folders_saved;
                setFolders(userData.folders_saved);
                let index = userData.folders_saved.findIndex((folder: any) => folder.name === activeFolderSelection);
                setFolderItems(userData.folders_saved[index].recipes);
            }
        }
    }, [userData]);



    //populate My recipes folder with all the latest user recipes
    useEffect(() => {
        console.log("fetching all the user saved recipes")
        let mySavedRecipesArray: Array<any> = [];
        // setQuery("");
        const getMySavedRecipes = async () => {
            const mySavedRecipeSnap = await getDocs(mySavedRecipesRef);
            mySavedRecipeSnap.forEach((doc: any) => {
                mySavedRecipesArray.push(doc.id);
            });
        };
        getMySavedRecipes().then(async () => {
            if (folders_mutable.current.findIndex((folder: any) => folder.name === save_folder_name) === -1) {
                addFolder(save_folder_name).then(r => console.log(r))
            }
            for (let i = 0; i < mySavedRecipesArray.length; i++) {
                let index = folders_mutable.current.findIndex((folder: any) => folder.name === save_folder_name);
                let foldersArray = folders_mutable.current ? folders_mutable.current : [];
                let newFoldersArray = [...foldersArray];
                let recipeList = foldersArray[index].recipes;
                if (!recipeList.includes(mySavedRecipesArray[i])) {
                    newFoldersArray[index].recipes = [...recipeList, mySavedRecipesArray[i]];
                    try {
                        await setDoc(
                            userRef,
                            {
                                folders_saved: newFoldersArray,
                            },
                            {
                                merge: true,
                            }
                        );
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        })
    }, []);

    useEffect(() => {

    }, [filter, folders, folderItems, activeFolderSelection]);


    const searchClient = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID!,
        process.env.REACT_APP_ALGOLIA_API_KEY!,
        {
            responsesCache: createNullCache(),
            requestsCache: createNullCache(),
        }
    );

    const StateResults = ({ isSearchStalled }: any) => (
        <span
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
      {isSearchStalled ? <Loading /> : ""}
    </span>
    );
    const CustomStateResults = connectStateResults(StateResults);

    function setAlgoliaIndex(searchState: any) {
        if (process.env.NODE_ENV === "production") {
            if (searchState.query === "" || searchState.query === undefined) {
                setIndex("recipes_created_at_desc");
                setMode("search");
            } else {
                setIndex("recipes");
                setMode("search");
            }
        } else {
            if (searchState.query === "" || searchState.query === undefined) {
                setIndex("recipes_dev");
                setMode("search");
            } else {
                setIndex("recipes_dev");
                setMode("search");
            }
        }
    }

    const onSaveClick = (id: string) => {
        setRecipeId(id);
        setOpenModal(true);
    };

    const onShareClick = (id: string) => {
        setRecipeId(id);
        setShareModal(true);
    };

    const addToFolder = async (recipe: any, folderName: String) => {
        // Get index of the folder where the user dropped the recipe
        let index = folders_mutable.current.findIndex((folder: any) => folder.name === folderName);

        let foldersArray = folders_mutable.current ? folders_mutable.current : [];
        let newFoldersArray = [...foldersArray];
        let recipeList = foldersArray[index].recipes;

        // Check if the recipe is already in the folder or not.
        // If not, add it to the folders array taken from User data.
        if (!recipeList.includes(recipe.recipeId)) {
            newFoldersArray[index].recipes = [...recipeList, recipe.recipeId];
            recipeAdded();
            // setFolders(newFoldersArray);
            try {
                await setDoc(
                    userRef,
                    {
                        folders_saved: newFoldersArray,
                    },
                    {
                        merge: true,
                    }
                );
            } catch (e) {
                console.error(e);
            }
        } else {
            recipeAlreadyInFolderError();
        }
    };


    const addFolder = async (folderName: String) => {
        // Get index of the folder where the user dropped the recipe

        let foldersArray = folders_mutable.current ? folders_mutable.current : [];
        let newFoldersArray = [...foldersArray];

        const newFolder = {
            name: folderName,
            recipes: [],
        };

        let index = foldersArray.findIndex(folder => folder.name === folderName);
        // Check if the folder already exists.
        // If not, add it to the folders array taken from User data.
        if (index === -1) {
            newFoldersArray = [...newFoldersArray, newFolder];
            folderAdded();
            try {
                await setDoc(
                    userRef,
                    {
                        folders_saved: newFoldersArray,
                    },
                    {
                        merge: true,
                    }
                );
            } catch (e) {
                console.error(e);
            }
        } else {
            folderAlreadyExistsError();
        }
        setOpenNewFolderModal(false)
    };

    const removeFromFolder = async (recipeID: string, folderName: String) => {
        const specificRecipeRef = doc(recipeRef, recipeID);
        // console.log("removing recipe with id " +recipeID + " from the " + folderName + " folder");
        // Get index of the folder where the user dropped the recipe
        let index = folders_mutable.current.findIndex((folder: any) => folder.name === folderName);
        // let foldersArray = folders ? folders : [];
        let newFoldersArray = [...folders_mutable.current];
        let recipeList = newFoldersArray[index].recipes
        // Check if the recipe is already in the folder or not.
        // If yes, remove it from the folders array taken from User data.
        if (recipeList.includes(recipeID) && recipeList) {
            let recipeIndex = recipeList.findIndex((recipe: any) => recipe === recipeID);
            let newRecipeList = [...recipeList];
            newRecipeList.splice(recipeIndex, 1);
            newFoldersArray[index].recipes = newRecipeList;
            setFolderItems(newRecipeList);
                if(folderName === save_folder_name){
                    //iterate over all the folders and remove the recipe if it is present
                    for(let i = 0; i < newFoldersArray.length; i++){
                        let folder = newFoldersArray[i];
                        let recipeList = folder.recipes;
                        if(recipeList.includes(recipeID) && newFoldersArray[i].name !== save_folder_name){
                            let recipeIndex = recipeList.findIndex((recipe: any) => recipe === recipeID);
                            let newRecipeList = [...recipeList];
                            newRecipeList.splice(recipeIndex, 1);
                            newFoldersArray[i].recipes = newRecipeList;
                        }
                    }
                // Decrement numberOfSaves field on recipe and update usersSaved array to include User ID
                await updateDoc(specificRecipeRef, {
                    numberOfSaves: increment(-1),
                    usersSaved: arrayRemove(user!.uid),
                });

                await deleteDoc(
                    doc(firestore, "users", user!.uid, "savedRecipes", recipeID)
                );

                // Log recipe unsaved event
                logEvent(analytics, "recipe_unsaved", {
                    user: user!.uid,
                    recipe: recipeId,
                });
            }

            recipeRemoved();
            try {
                await setDoc(
                    userRef,
                    {
                        folders_saved: newFoldersArray,
                    },
                    {
                        merge: true,
                    }
                );
            } catch (e) {
                console.error(e);
            }
        } else {
            console.log("We failed to remove the recipe from the folder");
        }
    };

    const removeFolder = async (folderName: String) => {
        // Get index of the folder to be removed
        let index = folders_mutable.current.findIndex((folder: any) => folder.name === folderName);
        let newFoldersArray = [...folders_mutable.current];
        if (newFoldersArray) {
            //remove the folder
            newFoldersArray.splice(index, 1);
            folderRemoved();
            try {
                await setDoc(
                    userRef,
                    {
                        folders_saved: newFoldersArray,
                    },
                    {
                        merge: true,
                    }
                );
            } catch (e) {
                console.error(e);
            }
        } else {
            console.log("We failed to remove the folder");
        }
    };

    const getAlgoliaIndex = () => {
        if (process.env.NODE_ENV !== "production") {
            return "recipes_dev";
        } else {
            return "recipes_created_at_desc";
        }
    };

    function onFolderClick(folder) {
        console.log(folder.name)
        console.log(folder.recipes)
        setActiveFolderSelection(folder.name);
        setFolderItems(folder.recipes)
    }

    function onDeleteFolder(folderName) {
        setDeleteFolderModal(false)
        removeFolder(folderName);
        setActiveFolderSelection(save_folder_name)
    }

    const onScrapeClick = () => {
        setScrapeModal(true);
    };

    if (status === "loading") {
        return <Loading/>;
    }

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={getAlgoliaIndex()}
            onSearchStateChange={(searchState) => setAlgoliaIndex(searchState)}>
            <Configure
                hitsPerPage={12}
                // filters="private:false AND completed:true"
                // filters={algoliaFilter}
                analytics={false}
                enablePersonalization={false}
                distinct
            // hitsPerPage={12}
            />
            <DndProvider backend={HTML5Backend}>
                <Block>
                    <Block
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Header margin={"0"} size={4}>
                            Menus
                        </Header>
                        <Block
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                to={{
                                    pathname: "/app/create",
                                    state: {
                                        id: uuidv4(),
                                        recipe: null,
                                        edit: false,
                                    },
                                }}
                            >
                                <Button primary>Create</Button>
                            </Link>
                            <Button primary margin={"0 0 0 .5em"} onClick={onScrapeClick}>Import</Button>
                        </Block>
                    </Block>
                    <SearchBarContainer>
                        <AlgoliaSearch query={query} delay={algoliaSearchDelay}/>
                        {/*<CustomStateResults />*/}
                    </SearchBarContainer>
                    <Block style={{
                        marginTop: "1em",
                    }}>
                        <Subheader margin={"0 0 1em 0"} size={1}>
                            📁 Menus
                            <FontAwesomeIcon
                                style={{cursor: "pointer", marginLeft: '.5em'}}
                                onClick={() => setOpenNewFolderModal(true)}
                                icon={faPlusCircle}
                                size={"sm"}
                                color={RED}
                            />
                        </Subheader>
                        <Columns>
                            {folders.map((folder: any, i: number) => {
                                return (
                                    <Folder
                                        key={i}
                                        onClick={() => onFolderClick(folder)}
                                        onAddToFolder={(recipeId: string) =>
                                            addToFolder(recipeId, folder.name)
                                        }
                                        name={folder.name}
                                        recipes={folder.recipes}
                                        onRemoveFolder={(folder) => setDeleteFolderModal(true)}
                                        activeFolderSelection={activeFolderSelection}
                                    />
                                );
                            })}
                        </Columns>
                    </Block>
                    <Block>
                        {/*<Subheader margin={"0 0 1em 0"} size={1}>*/}
                        {/*    🍴{activeFolderSelection}*/}
                        {/*</Subheader>*/}
                        {/*<Block style={{*/}
                        {/*    display: "flex",*/}
                        {/*    flexDirection: "row",*/}
                        {/*    justifyContent: "space-between",*/}
                        {/*    alignItems: "center",*/}
                        {/*}}>*/}
                        {/*    <Block style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        justifyContent: "space-between",*/}
                        {/*        alignItems: "left",*/}
                        {/*    }}>*/}
                        {/*        <Filter primary={filter === "All Recipes"} height={'1.75em'} margin={"0 0 0 1em"}*/}
                        {/*                onClick={() => {setFilter("All Recipes"); setAlgoliaFilter("private:false AND completed:true");}}>All Recipes</Filter>*/}
                        {/*        <Filter primary={filter === "My Recipes"} height={'1.75em'} margin={"0 0 0 1em"}*/}
                        {/*                onClick={() => {setFilter("My Recipes"); setAlgoliaFilter("creator.id:" + user!.uid);}}>My Recipes</Filter>*/}
                        {/*        /!*<Filter primary={filter === "My Imports"} height={'1.75em'} margin={"0 0 0 1em"}*!/*/}
                        {/*        /!*        onClick={() => {setFilter("My Imports"); setAlgoliaFilter("creator.id:" + user!.uid + " AND imported:true")}}>My Imports</Filter>*!/*/}
                        {/*    </Block>*/}
                        {/*</Block>*/}
                        <HitsPublic
                            onTagClick={(tag: string) => setQuery(tag)}
                            onUserClick={(userName: string) => setQuery(userName)}
                            onSave={(id: string) => onSaveClick(id)}
                            onShare={(id: string) => onShareClick(id)}
                            folderFilterList={folderItems}
                            filter={filter}
                            folderName={activeFolderSelection}
                            onRemoveFromFolder={(id: string) => removeFromFolder(id, activeFolderSelection)}
                        />
                    </Block>
                </Block>
            </DndProvider>
            <SaveModal
                onClose={() => setOpenModal(false)}
                openModal={openModal}
                recipeId={recipeId}
            />
            <ShareModal
                onClose={() => setShareModal(false)}
                openModal={shareModal}
                recipeId={recipeId}
            />
            <AddFolderModal
                modalType={"Menu"}
                onClose={() => setOpenNewFolderModal(false)}
                openModal={openNewFolderModal}
                onAddFolder={(folderName: string) => addFolder(folderName)}
                recipeId={recipeId}
            />
            <ConfirmFolderDeleteModal
                modalType={"Menu"}
                onClose={() => setDeleteFolderModal(false)}
                openModal={deleteFolderModal}
                onDeleteFolder={(folderName: string) => onDeleteFolder(folderName)}
                folderName={activeFolderSelection}
            />
            <ScrapeRecipeModal
                onClose={() => setScrapeModal(false)}
                openModal={scrapeModal}
            />
        </InstantSearch>
    );
}
