import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";

type Props = {
    run: boolean;
};

export const AllRecipesOnBoarding = (props: Props) => {
    const [onBoarding, setOnBoarding] = useState<boolean>(false);
    const [steps, setSteps] = useState(
        [
            {
                target: '#root',
                content: 'Welcome to Zesty! We’re so happy you decided to join us. Let’s take a quick tour to show you all our cool features',
                disableBeacon: true,
                disableOverlay: false,
                placement: 'center' as 'center',
            },
            {
                target: '#root > section.section > div',
                content: 'At the top here you will find the navigation bar.',
                disableBeacon: true
            },
            {
                target: '#root > section.section > div > div > div > a',
                content: '‘All Recipes’ will bring you to all of our public recipes, ordered chronologically.',
                disableBeacon: true
            },
            {
                target: '#root > section.section > div > div > div > a:nth-child(2)',
                content: ' ‘My Recipes’ will bring you to all of the recipes you have personally created.',
                disableBeacon: true
            },
            {
                target: '#root > section.section > div > div > div > a:nth-child(3)',
                content: ' ‘Menus’ will bring you to all of the recipes you have personally saved. You can also make Menus using those recipes here!',
                disableBeacon: true
            },
            // {
            //     target: '#root > section.section > div > div > div > a:nth-child(4)',
            //     content: '‘Groups’ will bring you to all of the private groups you have joined or created.',
            //     disableBeacon: true
            // },
            {
                target: '#root > section:nth-child(3) > div > div.block > div:nth-child(2)',
                content: 'You can easily search for recipes by name, ingredient, cuisine or creator',
                disableBeacon: true,
                disableOverlay: false,
                scrollToFirstStep: false,
            },
            {

                target: '#root > section:nth-child(3) > div > div.block > div:nth-child(3) > div.columns.is-multiline > div:nth-child(1) > div > div.block > div.block:nth-child(2)',
                content: 'You can like a recipe, or save it to be added a menu!',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',
            },
            {
                target: '#root > section:nth-child(3) > div > div.block > div:nth-child(3) > div.columns.is-multiline > div:nth-child(1) > div',
                content: 'Scroll through recipes and if you want to learn more about it or cook it, click it!',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',
            },
            {
                target: '#root > section:nth-child(3) > div > div.block > div.block > a > button',
                content: 'When you’re ready to create your first recipe click this button and we will guide you through your first recipe!',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',
            },
        ]
    )

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.allRecipes;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r));
            window.scrollTo(0, 0);
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.allRecipes": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
