import React, { useEffect, useState } from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";

// Components
import { Columns } from "react-bulma-components";
import Recipe from "./Recipe";

type Props = {
  mode: string;
  recHits: Array<object>;
  hits: Array<object>;
  hasMore: boolean;
  refineNext: () => void;
  onUserClick: (username: string) => void;
  onTagClick: (tag: string) => void;
  onSave: (id: string) => void;
  onShare: (id: string) => void;
};

const InfiniteHits = (props: Props) => {
  const [hits, setHits] = useState<object[]>([]);
  const [recEngineHits, setRecEngineHits] = useState<any>([]);
  const [mode, setMode] = useState<string | undefined>("");
  let sentinel: any = null;

  useEffect(() => {
    setMode(props.mode);
    setRecEngineHits(props.recHits);
    const onSentinelIntersection = (entries: any) => {
      const hasMore = props.hasMore;
      const refineNext = props.refineNext;
      if (mode === "search") {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting && hasMore) {
            refineNext();
          }
        });
        setHits(props.hits);
      }
    };
    if (mode === "search") {
      let observer = new IntersectionObserver(onSentinelIntersection);
      observer.observe(sentinel);
      return () => {
        observer.disconnect();
      };
    }
  }, [
    props.hasMore,
    props.hits,
    props.refineNext,
    props.mode,
    props.recHits,
    sentinel,
    mode,
  ]);

  const onTagClick = (tag: string) => {
    props.onTagClick(tag);
    document.body.scrollTop = document.documentElement.scrollTop = 125;
  };

  const onUserClick = (userName: string) => {
    props.onUserClick(userName);
    document.body.scrollTop = document.documentElement.scrollTop = 125;
  };

  const onSave = (id: string) => {
    props.onSave(id);
  };

  const onShare = (id: string) => {
    props.onShare(id);
  };

  return (
    <div>
      {mode === "search" ? (
        <Columns>
          {hits.map((recipe: any) => (
            <Recipe
              onTagClick={(tag: string) => onTagClick(tag)}
              onUserClick={(userName: string) => onUserClick(userName)}
              onSave={(id: string) => onSave(id)}
              onShare={(id: string) => onShare(id)}
              id={recipe.objectID}
              key={recipe.objectID}
              onRemoveFromFolder={() => console.log()}
            />
          ))}
          <div
            className="ais-InfiniteHits-sentinel"
            ref={(c) => (sentinel = c)}
          />
        </Columns>
      ) : (
        <Columns>
          {recEngineHits.map((recommendation: any) => (
            <Recipe
              onTagClick={(tag: string) => onTagClick(tag)}
              onUserClick={(userName: string) => onUserClick(userName)}
              onSave={(id: string) => onSave(id)}
              onShare={(id: string) => onShare(id)}
              id={recommendation}
              key={recommendation.toString()}
              onRemoveFromFolder={() => console.log()}
            />
          ))}
        </Columns>
      )}
      <div className="ais-InfiniteHits-sentinel" ref={(c) => (sentinel = c)} />
    </div>
  );
};

export default connectInfiniteHits(InfiniteHits);
