import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";
type Props = {
    run: boolean;
};

export const GroupsCreateOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root',
                content: 'Hi, it looks like you want to create a group!',
                disableBeacon: true,
                placement: 'center' as 'center',
            },
            {
                target: '#root',
                content: 'Enter the name of the group, for example “family recipes”, and a quick description about what the group is about. Then hit save.',
                disableBeacon: true,
                placement: 'center' as 'center',

            },
        ]
    )
    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.groupsCreate;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.groupsCreate": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }
    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
