import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

// Firebase
import { doc } from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import { useFirestore, useFirestoreDocData, useAnalytics } from "reactfire";

// Components
import { Section, Container, Columns, Block } from "react-bulma-components";
import Header from "../../components/atoms/Header";
import Subheading from "../../components/atoms/Subheading";
import Body from "../../components/atoms/Body";
import Loading from "../../components/atoms/Loading";

// Molecules
import IngredientRow from "../../components/molecules/IngredientRow";
import InfoText from "../../components/molecules/InfoText";

// Organisms
import BetaSignUp from "../../components/organisms/BetaSignUp";

// Styles
import {
  WHITE,
  LIGHT_GREEN,
  DARK,
  RED,
  DARK_GREEN,
  GREEN_INPUT_BORDER,
  BLUE_BG,
  BLUE,
} from "../../styles/colors";
import { Anchorme } from "react-anchorme";

const StyledImage = styled.div.attrs((props: any) => ({
  recipeHighlightUrl: props.recipeHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.recipeHighlightUrl}) no-repeat center center;`};
  background-size: cover;
  height: 470px;
  width: 100%;
  border-radius: 20px;
`;

const InspirationBlock = styled(Block)`
  background-color: ${LIGHT_GREEN};
  border: 1px solid ${GREEN_INPUT_BORDER};
  border-radius: 15px;
  padding: 1em 1.5em;
`;

const WaitlistBlock = styled(Block)`
  background-color: ${BLUE_BG};
  border: 1px solid ${BLUE};
  border-radius: 15px;
  padding: 1em 1.5em;
`;

const StyledCTA = styled.a`
  color: ${BLUE};
  font-weight: bold;

  &:hover {
    color: ${BLUE};
    opacity: 70%;
  }
`;

const CreatorBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`};
  background-size: 100%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const ReadMore = styled.a`
  color: ${RED};

  &:hover {
    color: ${RED};
    opacity: 0.8;
  }
`;

const RecipeBlock = styled(Block)`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1em 1.5em;
`;

const StyledStepImage = styled.div.attrs((props: any) => ({
  imageUrl: props.imageUrl || null,
}))`
  background: ${(props) => `url(${props.imageUrl}) no-repeat center center;`};
  background-size: cover;
  height: 450px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`;

const StyledStepContainer = styled(Block)`
  &:not(:last-child) {
    margin-bottom: 3em;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  align-items: center;
  margin-bottom: 1em;
`;

const RecipeView = () => {
  const [readMore, setReadMore] = useState<boolean>(false);

  // Get react-router-dom state
  const { id } = useParams<{ id: string }>();

  // Firebase Initialization
  const firestore = useFirestore();
  const analytics = useAnalytics();

  // React Fire Recipes Record
  const recipeRef = doc(firestore, "recipes", id);
  const { status, data } = useFirestoreDocData<any>(recipeRef);

  const history = useHistory();

  useEffect(() => {
    // Log custom recipe view event
    logEvent(analytics, "recipe_view_guest", {
      recipe: id,
    });

    // Log screen view event
    logEvent(analytics, "screen_view", {
      firebase_screen: `view_recipe_${id}`,
      firebase_screen_class: "view_recipe_guest",
    });
  });

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <>
      <Section>
        <Block>
          <Container style={{ maxWidth: 996 }}>
            <WaitlistBlock>
              <Body bold color={BLUE}>
                Interested in Zesty?
              </Body>
              <Body color={DARK}>
                We’re currently in beta!{" "}
                <StyledCTA onClick={() => history.push("/login")}>
                  click here
                </StyledCTA>{" "}
                join Zesty and start posting your own recipes!
              </Body>
            </WaitlistBlock>
            <Block>
              <Columns>
                {data.highlightUrl ? (
                  <Columns.Column>
                    <StyledImage recipeHighlightUrl={data.highlightUrl} />
                  </Columns.Column>
                ) : null}
                <Columns.Column>
                  <Header size={1} margin={"0 0 0.25em 0"}>
                    {data.name}
                  </Header>
                  <ActionItem>
                    <FontAwesomeIcon icon={faHeart} size={"lg"} color={RED} />
                    <Body margin={"0 0 0 .5em"}>
                      {data.numberOfLikes ? data.numberOfLikes : 0} likes
                    </Body>
                  </ActionItem>
                  {data.creator ? (
                    <CreatorBlock>
                      <StyledProfileImage
                        profileHighlightUrl={data.creator.profileUrl}
                      />
                      <Subheading color={DARK} size={2}>
                        {data.creator.name}
                      </Subheading>
                    </CreatorBlock>
                  ) : null}
                  <hr />
                  <Body color={DARK} margin={"1em 0 1em 0"}>
                    {data.description}
                  </Body>
                  {readMore ? (
                    <div>
                      {data.cuisine ? (
                        <InfoText label="Cuisine" value={data.cuisine} />
                      ) : null}
                      <InfoText
                        label="Prep time"
                        value={data.prepTime + " minutes"}
                      />
                      <InfoText
                        label="Cook time"
                        value={data.cookTime + " minutes"}
                      />
                      <ReadMore onClick={() => setReadMore(false)}>
                        See less details
                      </ReadMore>
                    </div>
                  ) : (
                    <ReadMore onClick={() => setReadMore(true)}>
                      See more details
                    </ReadMore>
                  )}
                </Columns.Column>
              </Columns>
            </Block>
            {data.inspiration ? (
              <InspirationBlock>
                <Body bold color={DARK_GREEN}>
                  Inspired from
                </Body>
                <Body>
                  <Anchorme style={{}}>{data.inspiration}</Anchorme>
                </Body>
              </InspirationBlock>
            ) : (
              <hr />
            )}
            <Columns style={{ marginTop: "1em" }}>
              <Columns.Column size="one-third">
                <RecipeBlock>
                  {data.ingredientImageUrl ? (
                    <StyledImage recipeHighlightUrl={data.ingredientImageUrl} />
                  ) : null}
                  <Body margin={"0 0 1em 0"} bold color={DARK_GREEN}>
                    Ingredients needed
                  </Body>
                  <hr />
                  <Body margin={"1em 0 1em 0"} bold color={DARK}>
                    Servings: {data.servingSize}
                  </Body>
                  {data.ingredients.map((ingredient: any, i: number) => (
                    <IngredientRow key={i} ingredient={ingredient} />
                  ))}
                </RecipeBlock>
              </Columns.Column>
              <Columns.Column size="two-thirds">
                {data.steps.map((step: any, i: number) => {
                  return (
                    <StyledStepContainer key={i}>
                      {!(step.imageUrl === "") ? (
                        <StyledStepImage imageUrl={step.imageUrl} />
                      ) : null}
                      <Header margin={"0.5em 0 0.5em 0"} size={3} color={DARK}>
                        Step {i + 1}
                      </Header>
                      <Body margin={"0 0 1.5em 0"}>{step.description}</Body>
                      {step.ingredients.length > 0 ? (
                        <div>
                          <Body bold margin={"0 0 1em 0"} color={DARK}>
                            Ingredients needed
                          </Body>
                          {step.ingredients.map(
                            (ingredient: any, i: number) => (
                              <IngredientRow key={i} ingredient={ingredient} />
                            )
                          )}
                        </div>
                      ) : null}
                    </StyledStepContainer>
                  );
                })}
              </Columns.Column>
            </Columns>
          </Container>
        </Block>
      </Section>
      <BetaSignUp />
    </>
  );
};

export default RecipeView;
