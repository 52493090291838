import styled from "styled-components";
import { doc } from "@firebase/firestore";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Block } from "react-bulma-components";

// Atoms
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import React from "react";
import Tag from "../atoms/Tag";
import LoadingSmall from "../atoms/LoadingSmall";

// Styles
import { RED } from "../../styles/colors";

type Props = {
  recipeId: string;
};

const RecipeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1.5em;
`;

const StyledImage = styled.div.attrs((props: any) => ({
  recipeHighlightUrl: props.recipeHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.recipeHighlightUrl}) no-repeat center center;`}
  background-size: 140%;
  height: 250px;
  min-width: 250px;
  border-radius: 20px;
  margin-right: 1em;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LikeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5em 0;
`;

const StyledTagContainer = styled(Block)`
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
`;

const PostRecipe = (props: Props) => {
  const recipeRef = doc(useFirestore(), "recipes", props.recipeId);
  const { status, data } = useFirestoreDocDataOnce<any>(recipeRef);

  const onLikeRecipe = (
    e: React.MouseEvent<SVGSVGElement>,
    recipeId: string
  ) => {
    e.preventDefault();
  };

  if (status === "loading") {
    return <LoadingSmall />;
  }

  return (
    <Link
      to={{
        pathname: `/recipe/${props.recipeId}`,
        state: {
          id: props.recipeId,
        },
      }}
    >
      <RecipeContainer>
        {!(data.highlightUrl === "") ? (
          <StyledImage recipeHighlightUrl={data.highlightUrl} />
        ) : null}
        <ContentContainer>
          <Header margin={"0"} size={3}>
            {data.name}
          </Header>
          <LikeContainer>
            <FontAwesomeIcon
              onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                onLikeRecipe(e, props.recipeId)
              }
              icon={faHeart}
              size={"lg"}
              color={RED}
            />
            <Body margin={"0 0.5em"}>
              {data.numberOfLikes ? data.numberOfLikes : 0} likes
            </Body>
          </LikeContainer>
          <Body>{data.description}</Body>
          <StyledTagContainer>
            {data.tags.map((tag: any, i: any) => (
              <Tag key={i}>#{tag}</Tag>
            ))}
          </StyledTagContainer>
        </ContentContainer>
      </RecipeContainer>
    </Link>
  );
};

export default PostRecipe;
