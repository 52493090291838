import styled from "styled-components";

import Body from "../atoms/Body";

// Molecules
import CreatorRow from "./CreatorRow";
import PostRecipe from "./PostRecipe";

import { DARK, WHITE } from "../../styles/colors";

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.5em 2em;
  width: 100%;
  margin-bottom: 1.5em;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type Props = {
  post: any;
};

const Post = (props: Props) => {
  const postDate = props.post.postDate.toDate().toDateString();

  return (
    <PostContainer>
      <TitleContainer>
        <CreatorRow id={props.post.user.id} />
        <Body>{postDate}</Body>
      </TitleContainer>
      <hr />
      <Body color={DARK}>{props.post.comment}</Body>
      <PostRecipe recipeId={props.post.recipeId} />
    </PostContainer>
  );
};

export default Post;
