import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/free-regular-svg-icons";

// Components
import Body from "../atoms/Body";

// Styles
import { DARK, GREY, TEAL } from "../../styles/colors";
import React from "react";

type Props = {
  name: string;
  onSelect: (e: React.MouseEvent<SVGSVGElement>) => void;
  selected: boolean;
};

const IngredientContainer = styled.div`
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }
`;

const LeftIndicator = styled.div`
  background-color: ${TEAL};
  min-width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 1em;
`;

const ListRow = (props: Props) => {
  return (
    <IngredientContainer>
      <div style={{ display: "flex", alignItems: "center", marginRight: 16 }}>
        <LeftIndicator />
        <div>
          <Body color={DARK}>{props.name}</Body>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 16,
        }}
      >
        {props.selected ? (
          <FontAwesomeIcon
            onClick={props.onSelect}
            color={GREY}
            size={"lg"}
            style={{ cursor: "pointer" }}
            icon={faCheckSquare}
          />
        ) : (
          <FontAwesomeIcon
            onClick={props.onSelect}
            color={GREY}
            size={"lg"}
            style={{ cursor: "pointer" }}
            icon={faSquare}
          />
        )}
      </div>
    </IngredientContainer>
  );
};

export default ListRow;
