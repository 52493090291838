import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {faSquare} from '@fortawesome/free-regular-svg-icons'

// Components
import Body from '../atoms/Body';

// Styles
import {DARK, YELLOW, RED, GREY, DARK_GREEN} from '../../styles/colors';
import React from 'react';

type Props = {
  ingredient: {
    amount: number,
    measure: string,
    name: string,
  },
  onDelete?: (e: React.MouseEvent<SVGSVGElement>) => void,
  onSelect?: (e: React.MouseEvent<SVGSVGElement>) => void,
  onStepUp?: (e: React.MouseEvent<SVGSVGElement>) => void,
  onStepDown?: (e: React.MouseEvent<SVGSVGElement>) => void,
  selectable?: boolean,
  deletable?: boolean,
  movable?: boolean,
}

const IngredientContainer = styled.div`
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
  justify-content: space-between;

  :last-child {
    margin-bottom: 0;
  }
`

const LeftIndicator = styled.div`
  background-color: ${YELLOW};
  min-width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 1em;
`

const IngredientRow = (props: Props) => {
  return (
    <IngredientContainer>
      <div style={{display: 'flex', alignItems: 'center', marginRight: 16}}>
        <LeftIndicator />
        <div>
          <Body color={DARK}>{props.ingredient.amount} {props.ingredient.measure} {props.ingredient.name}</Body>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 16}}>
        {props.movable ? (
          <div>
            <span style={{marginRight: 16}}>
              <FontAwesomeIcon onClick={props.onStepUp} color={DARK_GREEN} size={'lg'} style={{cursor: 'pointer'}} icon={faAngleUp} />
            </span>
            <span style={{marginRight: 16}}>
              <FontAwesomeIcon onClick={props.onStepDown} color={DARK_GREEN} size={'lg'} style={{cursor: 'pointer'}} icon={faAngleDown} />
            </span>
          </div>
        ) : null}
        {props.selectable ? (
          <FontAwesomeIcon onClick={props.onSelect} color={GREY} size={'lg'} style={{cursor: 'pointer'}} icon={faSquare} />
        ) : null}
        {props.deletable ? (
          <FontAwesomeIcon onClick={props.onDelete} color={RED} size={'lg'} style={{cursor: 'pointer'}} icon={faTimesCircle} />
        ) : null}
      </div>
    </IngredientContainer>
  )
}

export default IngredientRow;
