import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";

// Firebase
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  increment,
} from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { logEvent } from "@firebase/analytics";
import {
  useStorage,
  useUser,
  useFirestore,
  useAnalytics,
  useFirestoreDocDataOnce,
} from "reactfire";

// Styles
import {
  LIGHT_YELLOW,
  YELLOW,
  GREY,
  DARK,
  DARK_GREEN,
  RED,
} from "../../styles/colors";

// Components
import { Block, Container } from "react-bulma-components";

// Atoms
import Header from "../../components/atoms/Header";
import Button from "../../components/atoms/Button";
import Subheading from "../../components/atoms/Subheading";
import Loading from "../../components/atoms/Loading";
import Dropzone from "../../components/atoms/Dropzone";

// Molecules
import FormTextarea from "../../components/molecules/FormTextarea";
import FormField from "../../components/molecules/FormField";
import GroupSelect from "../../components/molecules/GroupSelect";

// Organisms
import RecipeFooter from "../../components/organisms/RecipeFooter";
import Body from "../../components/atoms/Body";
import { optimizeImage } from "../../utilities";
import ImageCrop from "../../components/organisms/ImageCrop";
import {DescriptionCreateOnBoarding} from "../onboarding/create/DescriptionCreateOnBoarding";

const StyledTagContainer = styled(Block)`
  margin-top: 1em;
  display: flex;
`;

const StyledTag = styled.div`
  background-color: ${LIGHT_YELLOW};
  border: 1px solid ${YELLOW};
  border-radius: 5px;
  color: ${GREY};
  font-family: "Inter";
  padding: 4px 8px;
  margin-right: 0.5em;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteImageContainer = styled.div`
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  width: 100px;
  background-size: 100%;
  display: flex;
  padding: 0.25em 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

export default function Description() {
  const [loading, setLoading] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");
  const [recipeDescription, setRecipeDescription] = useState<string>("");
  const [recipeInspiration, setRecipeInspiration] = useState<string>("");
  const [recipeImage, setRecipeImage] = useState<any>(null);
  const [recipeImageUrl, setRecipeImageUrl] = useState<string | undefined>("");
  const [prepTimeHour, setPrepTimeHour] = useState<string>("0");
  const [prepTimeMin, setPrepTimeMin] = useState<string>("0");
  const [cookTimeHour, setCookTimeHour] = useState<string>("0");
  const [cookTimeMin, setCookTimeMin] = useState<string>("0");
  const [tag, setTag] = useState<string>("");
  const [recipeTags, setRecipeTags] = useState<Array<string>>([]);
  const [groups, setGroups] = useState<Array<any>>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [comment, setComment] = useState<string>("");

  // Get react router history
  const history = useHistory();
  const location: any = useLocation();
  const { id } = location.state;

  // Firestore
  const firestore = useFirestore();

  // React Fire User Details Record
  const { data: user } = useUser();
  const userDetailsRef = doc(firestore, "users", user!.uid);
  const { data: userData } = useFirestoreDocDataOnce<any>(userDetailsRef);

  const groupCollectionRef = collection(firestore, "groups");

  // React Fire Recipes Record
  const recipeRef = doc(firestore, "recipes", id);
  const userRecipeRef = doc(firestore, "users", user!.uid, "recipes", id);

  // Initialize Firebase storage for image upload
  const storage = useStorage();

  // Initialize Firebase Analytics
  const analytics = useAnalytics();

  useEffect(() => {
    if (userData) {
      if (userData.joinedGroups) {
        let joinedGroups = userData.joinedGroups;
        setGroups(joinedGroups);
      }
    }

    // Log event for creating a recipe description
    logEvent(analytics, "create_recipe_description", {
      user: user!.uid,
    });

    setRecipeId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeImage = (image: any, imageUrl: string) => {
    setRecipeImage(image);
    setRecipeImageUrl(imageUrl);
  };

  // Add tags to a recipe
  const createTag = (e: any) => {
    e.preventDefault();
    if (e.target.value[e.target.value.length - 1] === " ") {
      onAddTag(e);
    } else {
      setTag(e.target.value);
    }
  };

  // Add tags to a recipe if space bar is hit
  const onAddTag = (e: any) => {
    e.preventDefault();
    const tagsArray = [...recipeTags];

    if (!recipeTags.includes(tag.toLowerCase())) {
      tagsArray.push(tag.toLowerCase());
      setRecipeTags(tagsArray);
    }
    setTag("");
  };

  const onRemoveTag = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let tagsArray = [...recipeTags];
    tagsArray.splice(i, 1);
    setRecipeTags(tagsArray);
  };

  const onChangeTime = (e: any, type: string) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (type === "cook_hour") {
      setCookTimeHour(value);
    } else if (type === "cook_min") {
      setCookTimeMin(value);
    } else if (type === "prep_hour") {
      setPrepTimeHour(value);
    } else if (type === "prep_min") {
      setPrepTimeMin(value);
    }
  };

  const onAddInspiration = (e: any) => {
    e.preventDefault();
    let value = e.target.value;
    setRecipeInspiration(value);
  };

  // Delete image for step
  const onDeleteImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setRecipeImage(null);
    setRecipeImageUrl("");
  };

  // Upload recipe image to Firebase storage
  const uploadRecipeImage = async () => {
    // Set loading to true
    setLoading(true);

    const ext = recipeImage.type.substr(recipeImage.type.lastIndexOf("/") + 1);

    // Initialize storage reference
    const storageRef = ref(storage, `recipes/${recipeId}/highlight.${ext}`);
    try {
      let image: any = null;
      await optimizeImage(recipeImage).then((result: any) => {
        image = result;
      });
      await uploadBytes(storageRef, image);
      let url = await getDownloadURL(storageRef);
      return url;
    } catch (e) {
      alert(e);
    }
  };

  const onAddDescription = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let intPrepTimeHour = parseInt(prepTimeHour, 10);
    let intPrepTimeMin = parseInt(prepTimeMin, 10);
    let intCookTimeHour = parseInt(cookTimeHour, 10);
    let intCookTimeMin = parseInt(cookTimeMin, 10);

    let newUrl = recipeImageUrl;

    // Check if highlight image was uploaded. If so, upload to Firebase storage.
    if (recipeImage != null) {
      newUrl = await uploadRecipeImage();
      console.log("saving highlight image with download URL: " + newUrl);
    }

    try {
      // Update recipe document
      updateDoc(recipeRef, {
        description: recipeDescription,
        inspiration: recipeInspiration,
        highlightUrl: newUrl,
        prepTime: 60 * intPrepTimeHour + intPrepTimeMin,
        cookTime: 60 * intCookTimeHour + intCookTimeMin,
        tags: recipeTags,
        completed: true,
      });

      // Update user's recipe subcollection
      updateDoc(userRecipeRef, {
        description: recipeDescription,
        highlightUrl: recipeImageUrl,
        tags: recipeTags,
        completed: true,
      });

      // Go back to main page of app
      history.push({
        pathname: "/app",
      });
    } catch (e) {
      alert(e);
    }

    if (selectedGroup !== "") {
      let groupRef = doc(groupCollectionRef, selectedGroup);
      let postId = uuidv4();
      let postRef = doc(groupRef, "posts", postId);

      let postObject = {
        user: {
          id: user!.uid,
          name: userData.name,
          profileUrl: userData.profileUrl,
        },
        recipeId: recipeId,
        comment: comment,
        postDate: new Date(),
      };

      try {
        await setDoc(postRef, {
          postObject,
        });
        await updateDoc(groupRef, {
          numberOfPosts: increment(1),
        });
        logEvent(analytics, "group_share_post", {
          user: user!.uid,
        });
      } catch (e) {
        alert(e);
      }
    }
  };

  if (loading === true) {
    return <Loading />;
  }

  return (
    <Container>
      <DescriptionCreateOnBoarding run={true}/>
      <Container style={{ maxWidth: 996, marginBottom: 90 }}>
        <Header size={2}>Looks awesome!</Header>
        <form>
          <div>
          <FormTextarea
            label="Add a note to the recipe"
            value={recipeDescription}
            placeholder="Tell us the story behind your dish"
            onChange={(e: any) => setRecipeDescription(e.target.value)}
          />
          <FormField
            label="Add an inspiration source to the recipe"
            text="This can be a web link or the name of the book you found it in."
            value={recipeInspiration}
            placeholder="Add a link or book title here"
            onChange={(e: any) => onAddInspiration(e)}
          />
        </div>
          <div>
          <Subheading margin={"1.5em 0 0.5em 0"} size={2} color={DARK}>
            Add an image of the final dish
          </Subheading>
          <Body margin={"0.5em 0"} color={RED}>
            *Optional
          </Body>
          <Dropzone
            onFileAccept={(image: any, imageUrl: string) => {
              changeImage(image, imageUrl);
            }}
          />
          {recipeImageUrl ? (
            <>
              <DeleteImageContainer
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onDeleteImage(e)
                }
              >
                <FontAwesomeIcon
                  color={RED}
                  size={"1x"}
                  style={{ cursor: "pointer" }}
                  icon={faTimesCircle}
                />
                <Body color={RED}> Delete</Body>
              </DeleteImageContainer>
              <ImageCrop
                cropWidth={75}
                imageUrl={recipeImageUrl}
                onFinishedCropping={(croppedFile: any) => {
                  setRecipeImage(croppedFile);
                }}
              />
            </>
          ) : null}
          </div>
          <div>
          <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
            How long does the prep take?
          </Body>
          <Body margin={"0 0 0px 0"} color={GREY}>
            Prep time is when you'll be busy in the kitchen. You'll be mixing,
            mashing, stirring, and doing whatever else you need to do before
            cooking.
          </Body>
          <span style={{ display: "flex", justifyContent: "flex-start" }}>
            <span style={{ marginRight: 10 }}>
              <FormField
                label="Hours"
                value={prepTimeHour}
                placeholder="Preparation time"
                onChange={(e: any) => onChangeTime(e, "prep_hour")}
              />
            </span>
            <FormField
              label="Minutes"
              value={prepTimeMin}
              placeholder="Preparation time"
              onChange={(e: any) => onChangeTime(e, "prep_min")}
            />
          </span>

          <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
            How long does the cooking take?
          </Body>
          <Body margin={"0 0 0px 0"} color={GREY}>
            Cooking time is when the food is actually in the oven or on the
            stove top.
          </Body>
          <span style={{ display: "flex", justifyContent: "flex-start" }}>
            <span style={{ marginRight: 10 }}>
              <FormField
                label="Hours"
                value={cookTimeHour}
                placeholder="Cooking time"
                onChange={(e: any) => onChangeTime(e, "cook_hour")}
              />
            </span>
            <FormField
              label="Minutes"
              value={cookTimeMin}
              placeholder="Cooking time"
              onChange={(e: any) => onChangeTime(e, "cook_min")}
            />
          </span>
          </div>
          <FormField
            label="What are some tags to describe your recipe?"
            text={`Example tags: dinner, lunch, whole30, etc. Make sure to click the "Add tag" button after entering in each tag.`}
            value={tag}
            placeholder="dinner"
            onChange={(e: any) => createTag(e)}
          />
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => onAddTag(e)}
            primary
          >
            Add tag
          </Button>
          <StyledTagContainer>
            {recipeTags.map((tag: string, i: number) => (
              <StyledTag key={i}>
                {tag}
                <span style={{ marginLeft: 16 }}>
                  <FontAwesomeIcon
                    onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                      onRemoveTag(e, i)
                    }
                    color={GREY}
                    size={"lg"}
                    style={{ cursor: "pointer" }}
                    icon={faTimesCircle}
                  />
                </span>
              </StyledTag>
            ))}
          </StyledTagContainer>
          {
            <Block>
              <Body bold margin={"1.5em 0"} color={DARK_GREEN}>
                Share your awesome recipe with your groups!
              </Body>
              {selectedGroup !== "" ? (
                <FormTextarea
                  value={comment}
                  placeholder="Add a comment to your post!"
                  onChange={(e: any) => setComment(e.target.value)}
                />
              ) : null}
              <GroupContainer>
                {groups.map((groupId: any) => (
                  <GroupSelect
                    active={groupId === selectedGroup ? true : false}
                    onClick={() => setSelectedGroup(groupId)}
                    id={groupId}
                    key={groupId}
                  />
                ))}
              </GroupContainer>
            </Block>
          }
        </form>
      </Container>
      <RecipeFooter
        history={history}
        id={recipeId}
        buttonLabel={"Finish recipe"}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          onAddDescription(e)
        }
      />
    </Container>
  );
}
