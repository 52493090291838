import React from "react";
import styled from "styled-components";

import { DARK_40S } from "../../styles/colors";

type Props = {
  children: any;
  size: number;
  font?: string;
  color?: string;
  margin?: string;
};

function getHeaderSize(size: number) {
  switch (size) {
    case 1:
      return "2.5625rem";
    case 2:
      return "2.125rem";
    case 3:
      return "1.75rem";
    case 4:
      return "1.5rem";
    case 5:
      return "1.25rem";
    default:
      return "1rem";
  }
}

const StyledH1 = styled("h1")<Props>`
  font-size: ${(props) => getHeaderSize(props.size)};
  color: ${(props) => (props.color ? props.color : DARK_40S)};
  line-height: 1.25;
  font-family: ${(props) =>
    props.font === "Inter" ? "Inter" : "NewYorkExtraLarge-Bold"};
  font-weight: 500;
  margin: ${(props) => (props.margin ? props.margin : "1em 0 1.5em 0")};
`;

const StyledH2 = styled("h2")<Props>`
  font-size: ${(props) => getHeaderSize(props.size)};
  color: ${(props) => (props.color ? props.color : DARK_40S)};
  line-height: 1.25;
  font-family: ${(props) =>
    props.font === "New York" ? "Inter" : "NewYorkExtraLarge-Bold"};
  font-weight: 500;
  margin: ${(props) => (props.margin ? props.margin : "1em 0 1.5em 0")};
`;

const StyledH3 = styled("h3")<Props>`
  font-size: ${(props) => getHeaderSize(props.size)};
  color: ${(props) => (props.color ? props.color : DARK_40S)};
  line-height: 1.25;
  font-family: ${(props) =>
    props.font === "New York" ? "Inter" : "NewYorkExtraLarge-Bold"};
  font-weight: 500;
  margin: ${(props) => (props.margin ? props.margin : "1em 0 1.5em 0")};
`;

const StyledH4 = styled("h4")<Props>`
  font-size: ${(props) => getHeaderSize(props.size)};
  color: ${(props) => (props.color ? props.color : DARK_40S)};
  line-height: 1.25;
  font-family: ${(props) =>
    props.font === "New York" ? "Inter" : "NewYorkExtraLarge-Bold"};
  font-weight: 500;
  margin: ${(props) => (props.margin ? props.margin : "1em 0 1.5em 0")};
`;

const StyledH5 = styled("h5")<Props>`
  font-size: ${(props) => getHeaderSize(props.size)};
  color: ${(props) => (props.color ? props.color : DARK_40S)};
  line-height: 1.25;
  font-family: ${(props) =>
    props.font === "New York" ? "Inter" : "NewYorkExtraLarge-Bold"};
  font-weight: 500;
  margin: ${(props) => (props.margin ? props.margin : "1em 0 1.5em 0")};
`;

export default function Header(props: Props) {
  switch (props.size) {
    case 1:
      return (
        <StyledH1
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH1>
      );
    case 2:
      return (
        <StyledH2
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH2>
      );
    case 3:
      return (
        <StyledH3
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH3>
      );
    case 4:
      return (
        <StyledH4
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH4>
      );
    case 5:
      return (
        <StyledH5
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH5>
      );
    default:
      return (
        <StyledH1
          font={props.font}
          margin={props.margin}
          color={props.color}
          size={props.size}
        >
          {props.children}
        </StyledH1>
      );
  }
}
