import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";
type Props = {
    run: boolean;
};

export const GroupsViewOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root',
                content: 'Welcome to your first group! We will run through a few things you can do in this group! Follow along or click "skip" if you\'d like!',
                disableBeacon: true,
                placement: 'center' as 'center',
            },
            {
                target: '#root > section:nth-child(3) > div.block > div > div.block > div.block > div:nth-child(2) > button',
                content: 'You can leave the group anytime by clicking the ‘leave group button. Be careful though because you won’t be able to access the group again unless you are invited or have the url',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',
            },
            {
                target: '#root > section:nth-child(3) > div.block > div > div.block > div.block > div:nth-child(3) > button',
                content: 'You can easily invite someone to the group by clicking the ‘Invite’ button. Doing so will copy the unique group url to your clipboard which you can then paste in an email, text or other form of communication. Once the person you invited joins, you should see them added to the group members list',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',

            },
            {
                target: '#root > section:nth-child(3) > div.block > div > div.block > div > div.column.is-two-thirds > div:nth-child(1) > button',
                content: 'Click here when you are ready to share a recipe to the group',
                disableBeacon: true,
                placement: 'bottom' as 'bottom',
            },
        ]
    )

    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.groupsView;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.groupsView": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
