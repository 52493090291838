import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { toast } from "react-hot-toast";

// Styles
import { GREEN_INPUT_BORDER, LIGHT_GREEN, GREY } from "../../styles/colors";

type Props = {
  onFileAccept: (image: any, imageUrl: string) => void;
};

// Custom Components
const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return `${GREEN_INPUT_BORDER}`;
};

const DragContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${LIGHT_GREEN};
  color: ${GREY};
  outline: none;
  transition: border .24s ease-in-out;
  margin-bottom: 1em;

  &:hover {
    border-color ${GREY};
  }
`;

const notifyImageReject = () => {
  toast("Please upload a jpeg or png!", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "❗",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

const Dropzone = (props: Props) => {
  // Image drop
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) {
        notifyImageReject();
      }
      let objectUrl = "";
      acceptedFiles.forEach((file: any) => {
        objectUrl = URL.createObjectURL(file);
      });

      props.onFileAccept(acceptedFiles[0], objectUrl);
    },
    [props]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/png, image/jpg, image/jpeg" });

  return (
    <DragContainer
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
    >
      <input {...getInputProps()} />
      <p>Drag 'n' drop your image here, or click to select a file</p>
    </DragContainer>
  );
};

export default Dropzone;
