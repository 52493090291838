// Components
import { Block, Form } from "react-bulma-components";
import Select from "../atoms/Select";
import Body from "../atoms/Body";

import { DARK_GREEN } from "../../styles/colors";

type Props = {
  label: string;
  value: any;
  margin?: string;
  text?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  children: any;
};

const SelectField = (props: Props) => {
  return (
    <Block style={{ margin: "1.5em 0" }}>
      <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
        {props.label}
      </Body>
      {props.text ? <Body margin={"1em 0"}>{props.text}</Body> : null}
      <Form.Field style={{ marginTop: 8 }}>
        <Form.Control>
          <Select value={props.value} onChange={props.onChange}>
            {props.children}
          </Select>
        </Form.Control>
      </Form.Field>
    </Block>
  );
};

export default SelectField;
