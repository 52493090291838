import React, {useState, useEffect, forwardRef} from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as solidHeart,
  faBookmark as solidBookmark,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { Anchorme } from "react-anchorme";

// Firebase
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  increment,
  arrayUnion,
  arrayRemove,
} from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import {
  useFirestore,
  useUser,
  useStorage,
  useAnalytics,
  useFirestoreDocData,
} from "reactfire";
import { deleteObject, listAll, ref } from "firebase/storage";

// Components
import {
  Section,
  Modal,
  Container,
  Columns,
  Block,
} from "react-bulma-components";
import Header from "../../components/atoms/Header";
import Subheading from "../../components/atoms/Subheading";
import Body from "../../components/atoms/Body";
import Button from "../../components/atoms/Button";
import Loading from "../../components/atoms/Loading";

// Molecules
import IngredientRow from "../../components/molecules/IngredientRow";
import InfoText from "../../components/molecules/InfoText";

// Organisms
import SaveModal from "../../components/organisms/SaveModal";
import ShareModal from "../../components/organisms/ShareModal";

// Styles
import {
  WHITE,
  LIGHT_GREEN,
  DARK,
  BLUE,
  RED,
  DARK_GREEN,
  GREEN_INPUT_BORDER,
  GREY,
  SECONDARY_80S,
} from "../../styles/colors";
import StepNotes from "../../components/atoms/StepNotes";

const StyledImage = styled.div.attrs((props: any) => ({
  recipeHighlightUrl: props.recipeHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.recipeHighlightUrl}) no-repeat center center;`}
  background-size: cover;
  height: 470px;
  width: 100%;
  border-radius: 20px;
`;

const StyledCTA = styled.a`
  color: ${BLUE};
  font-weight: bold;
  font-size: 18px;
  margin-right: 16px;

  &:hover {
    color: ${BLUE};
    opacity: 70%;
  }
`;

const InspirationBlock = styled(Block)`
  background-color: ${LIGHT_GREEN};
  border: 1px solid ${GREEN_INPUT_BORDER};
  border-radius: 15px;
  padding: 1em 1.5em;
`;

const CreatorBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`}
  background-size: 100%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const ReadMore = styled.a`
  color: ${RED};

  &:hover {
    color: ${RED};
    opacity: 0.8;
  }
`;

const RecipeBlock = styled(Block)`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1em 1.5em;
`;

const StyledStepImage = styled.div.attrs((props: any) => ({
  imageUrl: props.imageUrl || null,
}))`
  background: ${(props) => `url(${props.imageUrl}) no-repeat center center;`}
  background-size: cover;
  height: 450px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
`;

const StyledStepContainer = styled(Block)`
  &:not(:last-child) {
    margin-bottom: 1.5em;
  }
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 1em;

`;

const ActionBarContainer = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
`;

const ActionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1em;
  align-items: center;
`;

const ShareContainer = styled.div`
  margin: 1em 0 0 0;
`;

const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const RecipeView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);
  const [owner, setOwner] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [listOpenModal, setListOpenModal] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const [folders, setFolders] = useState<Array<any>>([]);


  // Get react-router-dom state
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  // Firebase Initialization
  const firestore = useFirestore();

  // React Fire Recipes Record
  const recipeRef = doc(firestore, "recipes", id);
  const { status, data } = useFirestoreDocData<any>(recipeRef);

  // React Fire User Details Record
  const { data: user } = useUser();
  const userRecipeRef = doc(firestore, "users", user!.uid, "recipes", id);
  const userRef = doc(firestore, "users", user!.uid);
  const {data: userData} = useFirestoreDocData(userRef);

  // React Fire User Liked Recipes Record
  const likedRecipesRef = doc(
    firestore,
    "users",
    user!.uid,
    "likedRecipes",
    id
  );

  // Initialize Firebase storage for image upload
  const storage = useStorage();

  // Initialize Firebase analytics
  const analytics = useAnalytics();

  useEffect(() => {
    if (data && user) {
      if (data.creator.id === user.uid) {
        // Log event for viewing a user's own recipe
        logEvent(analytics, "view_recipe_owner", {
          user: user.uid,
          recipe: id,
        });

        // Log event for screen view on recipe
        logEvent(analytics, "screen_view", {
          firebase_screen: `view_recipe_${id}`,
          firebase_screen_class: "view_recipe_owner",
        });

        // Set recipe owner state
        setOwner(true);
      } else {
        // Log event for viewing another user's recipe
        logEvent(analytics, "view_recipe_other", {
          recipe: id,
          user: user.uid,
        });

        // Log event for screen view on recipe
        logEvent(analytics, "screen_view", {
          firebase_screen: `view_recipe_${id}`,
          firebase_screen_class: "view_recipe_other",
        });
      }

      // Detect whether user has already liked this recipe. If so, set liked state to true
      if (data.usersLiked) {
        if (data.usersLiked.includes(user.uid)) {
          setLiked(true);
        }
      }

      // Set if user has saved this recipe
      if (data.usersSaved) {
        if (data.usersSaved.includes(user.uid)) {
          setSaved(true);
        }
      }
    }
  }, [analytics, data, id, user]);

  useEffect(() => {
    // Set user's folders into state
    if (userData) {
      if (userData.folders) {
        setFolders(userData.folders);
      }
    }
  }, [userData]);

  // Utilities to delete recipe
  async function deleteFolderContents(path: string, storage: any) {
    const storageRef = ref(storage, path);

    listAll(storageRef)
      .then((listResult: any) => {
        listResult.items.forEach((fileRef: any) => {
          deleteObject(fileRef);
        });
        listResult.prefixes.forEach((folderRef: any) => {
          deleteFolderContents(folderRef.fullPath, storage);
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  // Delete recipe from Firebase
  async function deleteRecipe(
    recipeId: string,
    recipeRef: any,
    userRecipeRef: any,
    storage: any
  ) {
    const recipeStoragePath = `recipes/${recipeId}`;
    try {
      await removeFromFolders(recipeId)
      await deleteFolderContents(recipeStoragePath, storage);
      await deleteDoc(recipeRef);
      await deleteDoc(userRecipeRef);
    } catch (e) {
      alert(e);
    }
  }

//delete recipe from any folder its contained in
  const removeFromFolders = async (recipeID: string) => {
    // Check for the recipe in all folders then remove it
    console.log("removing from all folder after delete")
    console.table(folders)
    let newFoldersArray = [...folders];
    for (let i = 0; i < newFoldersArray.length ; i++) {
      let index = newFoldersArray[i].recipes.findIndex((recipe: any) => recipe === recipeID);
      if(index !== -1){
        removeFromFolder(recipeID, newFoldersArray[i].name)
      }
    }

  };

//delete recipe from the folder its contained in
  const removeFromFolder = async (recipeID: string, folderName: String) => {
    // console.log("removing recipe with id " +recipeID + " from the " + folderName + " folder");
    // Get index of the folder where the user dropped the recipe
    let index = folders.findIndex((folder: any) => folder.name === folderName);
    // let foldersArray = folders ? folders : [];
    let newFoldersArray = [...folders];
    let recipeList = newFoldersArray[index].recipes
    // Check if the recipe is already in the folder or not.
    // If yes, remove it from the folders array taken from User data.
    if (recipeList.includes(recipeID) && recipeList) {
      let recipeIndex = recipeList.findIndex((recipe: any) => recipe === recipeID);
      let newRecipeList = [...recipeList];
      newRecipeList.splice(recipeIndex, 1);
      newFoldersArray[index].recipes = newRecipeList;
      try {
        await setDoc(
            userRef,
            {
              folders: newFoldersArray,
            },
            {
              merge: true,
            }
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log("We failed to remove the recipe from the folder");
    }
  };

  const onDeleteRecipe = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      deleteRecipe(id, recipeRef, userRecipeRef, storage);
      setTimeout(function () {
        history.push({
          pathname: "/app/account",
        });
        setLoading(false);
      }, 1000);
    } catch (e) {
      alert(e);
    }
  };

  const onLikeRecipe = async (
    e: React.MouseEvent<SVGSVGElement>,
    id: string
  ) => {
    e.preventDefault();
    // Update firestore documents
    try {
      // Increment numberOfLikes field on recipe
      await updateDoc(recipeRef, {
        numberOfLikes: increment(1),
        usersLiked: arrayUnion(user!.uid),
      });

      // Add a document to recipe subcollection under user
      await setDoc(likedRecipesRef, {
        name: data.name,
        likedAt: Date.now(),
        description: data.description,
        highlightUrl: data.highlightUrl,
        tags: data.tags,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDislikeRecipe = async (
    e: React.MouseEvent<SVGSVGElement>,
    id: string
  ) => {
    e.preventDefault();

    // Update firestore documents
    try {
      setLiked(false);
      // Decrement numberOfLikes field on recipe
      await updateDoc(recipeRef, {
        numberOfLikes: increment(-1),
        usersLiked: arrayRemove(user?.uid),
      });

      // Remove the document from recipe subcollection under user
      await deleteDoc(likedRecipesRef);
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveClick = () => {
    setListOpenModal(true);
  };

  const onShareClick = () => {
    setShareModal(true);
  };

  // Log event for sharing with social networks
  const onSocialShare = (platform: string) => {
    console.log("Logging share event");
    logEvent(analytics, "recipe_social_share", {
      platform: platform,
    });
  };

  if (status === "loading" || loading === true) {
    return <Loading />;
  }

  return (
    <Section>
      <Block>
        <Container style={{ maxWidth: 996 }}>
          <Block
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledCTA onClick={() => history.goBack()}>Go back</StyledCTA>
            {owner ? (
              <div style={{ display: "flex" }}>
                <Link
                  to={{
                    pathname: "/app/edit",
                    state: {
                      id: id,
                    },
                  }}
                >
                  <Button>Edit recipe</Button>
                </Link>
                <Button
                  onClick={() => setOpenModal(true)}
                  margin={"0 0 0 1em"}
                  primary
                >
                  Delete recipe
                </Button>
              </div>
            ) : null}
          </Block>
          <Block>
            <Columns>
              {data.highlightUrl ? (
                <Columns.Column>
                  <StyledImage recipeHighlightUrl={data.highlightUrl} />
                </Columns.Column>
              ) : null}
              <Columns.Column>
                <Header size={1} margin={"0 0 0.25em 0"}>
                  {data.name}
                </Header>
                <ActionBarContainer>
                  <ActionItem>
                    {liked ? (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                          onDislikeRecipe(e, id)
                        }
                        icon={solidHeart}
                        size={"lg"}
                        color={RED}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                          onLikeRecipe(e, id)
                        }
                        icon={faHeart}
                        size={"lg"}
                        color={GREY}
                      />
                    )}
                    <Body margin={"0 0 0 .5em"}>
                      {data.numberOfLikes ? data.numberOfLikes : 0} likes
                    </Body>
                  </ActionItem>
                  <ActionItem>
                    {saved ? (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => onSaveClick()}
                        icon={solidBookmark}
                        size={"lg"}
                        color={DARK_GREEN}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => onSaveClick()}
                        icon={faBookmark}
                        size={"lg"}
                        color={GREY}
                      />
                    )}
                    <Body margin={"0 0 0 .5em"}>
                      {data.numberOfSaves ? data.numberOfSaves : 0} saved
                    </Body>
                  </ActionItem>
                  <ActionItem>
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => onShareClick()}
                      icon={faShare}
                      size={"lg"}
                      color={GREY}
                    />
                  </ActionItem>
                </ActionBarContainer>
                {data.creator ? (
                  <CreatorBlock>
                    <StyledProfileImage
                      profileHighlightUrl={data.creator.profileUrl}
                    />
                    <Subheading color={DARK} size={2}>
                      {data.creator.name}
                    </Subheading>
                  </CreatorBlock>
                ) : null}
                <hr />
                <ShareContainer>
                  <FacebookShareButton
                    beforeOnClick={() => onSocialShare("facebook")}
                    url={`https://getzesty.app${location.pathname}`}
                    quote={`Check out this recipe: ${data.name}`}
                  >
                    <FacebookIcon
                      size={32}
                      style={{ marginRight: "0.5em" }}
                      round
                    />
                  </FacebookShareButton>
                  <EmailShareButton
                    beforeOnClick={() => onSocialShare("email")}
                    url={`https://getzesty.app${location.pathname}`}
                    subject={`Check out this recipe: ${data.name}`}
                    body={"Hello"}
                    style={{ marginRight: "0.5em" }}
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <TwitterShareButton
                    beforeOnClick={() => onSocialShare("twitter")}
                    url={`https://getzesty.app${location.pathname}`}
                    title={`Check out this recipe: ${data.name}`}
                    style={{ marginRight: "0.5em" }}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </ShareContainer>
                <Body color={DARK} margin={"0.5em 0 1em 0"}>
                  {data.description}
                </Body>
                {readMore ? (
                  <div>
                    {data.cuisine ? (
                      <InfoText label="Cuisine" value={data.cuisine} />
                    ) : null}
                    <InfoText
                      label="Prep time"
                      value={
                        data.prepTime >= 60
                          ? Math.trunc(data.prepTime / 60) +
                            " hours " +
                            (data.prepTime % 60) +
                            " minutes"
                          : "0 hours " + data.prepTime + " minutes"
                      }
                    />
                    <InfoText
                      label="Cook time"
                      value={
                        data.cookTime >= 60
                          ? Math.trunc(data.cookTime / 60) +
                            " hours " +
                            (data.cookTime % 60) +
                            " minutes"
                          : "0 hours " + data.cookTime + " minutes"
                      }
                    />
                    <ReadMore onClick={() => setReadMore(false)}>
                      See less details
                    </ReadMore>
                  </div>
                ) : (
                  <ReadMore onClick={() => setReadMore(true)}>
                    See more details
                  </ReadMore>
                )}
              </Columns.Column>
            </Columns>
          </Block>
          {data.inspiration ? (
            <InspirationBlock>
              <Body bold color={DARK_GREEN}>
                Inspired from
              </Body>
              <Body>
                <Anchorme style={{}}>{data.inspiration}</Anchorme>
              </Body>
            </InspirationBlock>
          ) : (
            <hr />
          )}
          <Columns style={{ marginTop: "1em" }}>
            <Columns.Column size="one-third">
              <RecipeBlock>
                {data.ingredientImageUrl ? (
                  <StyledImage recipeHighlightUrl={data.ingredientImageUrl} />
                ) : null}
                <Body margin={"0 0 1em 0"} font="NewYork" color={SECONDARY_80S}>
                  🍴 Ingredients needed
                </Body>
                <hr />
                <Body margin={"1em 0 1em 0"} bold color={DARK}>
                  Servings: {data.servingSize}
                </Body>
                {data.ingredients.map((ingredient: any, i: number) => (
                  <IngredientRow key={i} ingredient={ingredient} />
                ))}
              </RecipeBlock>
            </Columns.Column>
            <Columns.Column size="two-thirds">
              {data.steps.map((step: any, i: number) => {
                return (
                  <StyledStepContainer key={i}>
                    <Header margin={"0em 0 .5em 0"} size={3} color={DARK}>
                      Step {i + 1}
                    </Header>
                    <Columns>
                      <Columns.Column>
                        <Body margin={"0 0 1.5em 0"}>{step.description}</Body>
                        {step.ingredients.length > 0 ? (
                          <div>
                            <Body bold margin={"0 0 1em 0"} color={DARK}>
                              Ingredients needed
                            </Body>
                            {step.ingredients.map(
                              (ingredient: any, i: number) => (
                                <IngredientRow
                                  key={i}
                                  ingredient={ingredient}
                                />
                              )
                            )}
                          </div>
                        ) : null}
                      </Columns.Column>
                      {step.notes && step.notes.length > 0 ? (
                        <Columns.Column>
                          <StepNotes deletable={false} notes={step.notes} />
                        </Columns.Column>
                      ) : null}
                    </Columns>
                    {!(step.imageUrl === "") ? (
                        <StyledStepImage imageUrl={step.imageUrl} />
                    ) : null}
                  </StyledStepContainer>
                );
              })}
            </Columns.Column>
          </Columns>
        </Container>
        <Modal
          show={openModal}
          onClose={() => {
            return setOpenModal(false);
          }}
        >
          <Modal.Card>
            <Modal.Card.Body
              style={{ backgroundColor: LIGHT_GREEN, borderRadius: 20 }}
            >
              <Header margin={"0.5em 0"} size={4}>
                Are you absolutely sure?
              </Header>
              <Body margin={"1em 0"}>
                Once you delete this recipe, there's no going back! It will be
                completely deleted from your account
              </Body>
              <ButtonContainer>
                <Button
                  margin={"0 1em 0 0"}
                  primary
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    onDeleteRecipe(e)
                  }
                >
                  Delete recipe
                </Button>
                <Button onClick={() => setOpenModal(false)}>
                  No, don't delete
                </Button>
              </ButtonContainer>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal>
        <SaveModal
          onClose={() => setListOpenModal(false)}
          openModal={listOpenModal}
          recipeId={id}
        />
        <ShareModal
          onClose={() => setShareModal(false)}
          openModal={shareModal}
          recipeId={id}
        />
      </Block>
    </Section>
  );
};

export default RecipeView;
