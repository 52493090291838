import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faPencilAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Firestore
import { doc, updateDoc } from "@firebase/firestore";
import { logEvent } from "@firebase/analytics";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "@firebase/storage";
import {
  useStorage,
  useFirestore,
  useUser,
  useAnalytics,
  useFirestoreDocData,
} from "reactfire";

// Components
import { Form, Modal, Block, Container, Columns } from "react-bulma-components";

// Atoms
import Body from "../../components/atoms/Body";
import Button from "../../components/atoms/Button";
import Select from "../../components/atoms/Select";
import Input from "../../components/atoms/Input";
import Dropzone from "../../components/atoms/Dropzone";
import Loading from "../../components/atoms/Loading";
import Header from "../../components/atoms/Header";
import Subheading from "../../components/atoms/Subheading";

// Molecules
import FormField from "../../components/molecules/FormField";
import FormTextarea from "../../components/molecules/FormTextarea";
import SelectField from "../../components/molecules/SelectField";
import IngredientRow from "../../components/molecules/IngredientRow";

// Organisms
import AddStepModal from "../../components/organisms/AddStepModal";
import RecipeFooter from "../../components/organisms/RecipeFooter";
import EditStepModal from "../../components/organisms/EditStepModal";

// Styles
import {
  BLUE,
  DARK,
  DARK_GREEN,
  GREY,
  LIGHT_YELLOW,
  RED,
  SECONDARY_80S,
  YELLOW,
} from "../../styles/colors";
import ImageCrop from "../../components/organisms/ImageCrop";
import { optimizeImage } from "../../utilities";

// Custom Components
const StyledIngredientsBlock = styled(Block)`
  background-color: ${LIGHT_YELLOW};
  padding: 1em 1.5em;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.a`
  font-family: Inter;
  font-size: 16px;
  color: ${BLUE};
`;

const StyledStepContainer = styled(Block)`
  background-color: ${LIGHT_YELLOW};
  padding: 1em 1.5em;
  border-radius: 1em;
`;

const StyledStepImage = styled.div.attrs((props: any) => ({
  imageUrl: props.imageUrl || null,
}))`
  background: ${(props) => `url(${props.imageUrl}) no-repeat center center;`};
  background-size: 100%;
  min-width: 150px;
  height: 150px;
  border-radius: 10%;
  margin-right: 16px;
  margin-bottom: 8px;
  flex: 1;
`;

const StyledTagContainer = styled(Block)`
  margin-top: 1em;
  display: flex;
`;

const StyledTag = styled.div`
  background-color: ${LIGHT_YELLOW};
  border: 1px solid ${YELLOW};
  border-radius: 5px;
  color: ${GREY};
  font-family: "Inter";
  padding: 4px 8px;
  margin-right: 0.5em;
`;

const DeleteImageContainer = styled.div`
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  width: 100px;
  background-size: 100%;
  display: flex;
  padding: 0.25em 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

const RecipeEdit = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [privacy, setPrivacy] = useState<string>("private");
  const [description, setDescription] = useState<string>("");
  const [inspiration, setInspiration] = useState<string>("");
  const [cuisine, setCuisine] = useState<string>("");
  const [prepTime, setPrepTime] = useState<string>("");
  const [cookTime, setCookTime] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [tags, setTags] = useState<Array<string>>([]);
  const [ingredients, setIngredients] = useState<Array<any>>([]);
  const [ingredientImage, setIngredientImage] = useState<any>(null);
  const [ingredientImageUrl, setIngredientImageUrl] = useState<string>("");
  const [imageDeleted, setImageDeleted] = useState<boolean>(false);
  const [ingredientImageDeleted, setIngredientImageDeleted] =
    useState<boolean>(false);
  const [steps, setSteps] = useState<Array<any>>([]);
  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [servingSize, setServingSize] = useState<number>();

  // Edit Step Modal
  const [editStep, setEditStep] = useState<any>({});
  const [editStepNumber, setEditStepNumber] = useState<number>(0);
  const [editModal, setEditModal] = useState<boolean>(false);

  // Ingredient Modal
  const [ingredientOpenModal, setIngredientOpenModal] =
    useState<boolean>(false);
  const [ingredientAmount, setIngredientAmount] = useState<string>("0");
  const [ingredientAmountFraction, setIngredientAmountFraction] =
    useState<string>("");
  const [ingredientMeasure, setIngredientMeasure] = useState<String>("");
  const [ingredient, setIngredient] = useState<string>("");
  const [unusedIngredients, setUnusedIngredients] = useState<Array<any>>([]);

  // Step Modal
  const [addStepModal, setAddStepModal] = useState<boolean>(false);

  // Get User ID
  const { data: user } = useUser();

  // Get recipe ID from react-router-dom
  const history: any = useHistory();
  const location: any = useLocation();
  const { id } = location.state;

  // Firebase Initialization
  const storage = useStorage();
  const analytics = useAnalytics();
  const firestore = useFirestore();
  const recipeRef = doc(firestore, "recipes", id);

  const { status, data: recipeData } = useFirestoreDocData(recipeRef);

  useEffect(() => {
    // Log an event for editing a recipe. TODO: Uncomment code for production
    logEvent(analytics, "recipe_edit", {
      recipe: id,
      user: user!.uid,
    });

    function findUnusedIngredients() {
      let unusedIngredientsArray = [...unusedIngredients];
      for (let i = 0; i < recipeData.ingredients.length; i++) {
        let isFound = false;
        for (let j = 0; j < recipeData.steps.length; j++) {
          if(recipeData.steps[j].ingredients.length > 0 && !isFound){
            for (let k = 0; k < recipeData.steps[j].ingredients.length; k++) {
              if(recipeData.ingredients[i].name === recipeData.steps[j].ingredients[k].name){
                isFound = true;
                break;
              }
            }
          }
        }
        if(!isFound){
          unusedIngredientsArray.push(recipeData.ingredients[i]);
        }
      }
      setUnusedIngredients(unusedIngredientsArray);
    }

    if (recipeData) {
      setName(recipeData.name);
      setDescription(recipeData.description);
      setPrivacy(recipeData.private ? "private" : "public");
      setInspiration(recipeData.inspiration ? recipeData.inspiration : "");
      setCuisine(recipeData.cuisine);
      setPrepTime(recipeData.prepTime);
      setCookTime(recipeData.cookTime);
      setTags(recipeData.tags);
      setIngredients(recipeData.ingredients);
      setIngredientImageUrl(recipeData.ingredientImageUrl);
      setSteps(recipeData.steps);
      setImageUrl(recipeData.highlightUrl);
      setServingSize(recipeData.servingSize);
      findUnusedIngredients();
    }
  }, [user, id, analytics, recipeData]);

  // Change prep and cook time
  const onChangeTime = (e: any, type: string) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (type === "cook") {
      setCookTime(value);
    } else {
      setPrepTime(value);
    }
  };

  // Change serving size
  const onChangeServingSize = (e: any) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (value === "") {
      setServingSize(0);
    } else {
      setServingSize(parseInt(value, 10));
    }
  };

  // Check ingredient amount of letters or symbols. Only allow numbers to set ingredient amount
  const onChangeIngredientAmount = (e: any) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    setIngredientAmount(value);
  };

  // Add tags to a recipe
  const createTag = (e: any) => {
    e.preventDefault();
    if (e.target.value[e.target.value.length - 1] === " ") {
      onAddTag(e);
    } else {
      setTag(e.target.value);
    }
  };

  // Add tags to a recipe if space bar is hit
  const onAddTag = (e: any) => {
    e.preventDefault();
    const tagsArray = [...tags];

    if (!tags.includes(tag.toLowerCase())) {
      tagsArray.push(tag.toLowerCase());
      setTags(tagsArray);
    }
    setTag("");
  };

  // Remove tags
  const onRemoveTag = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let tagsArray = [...tags];
    tagsArray.splice(i, 1);
    setTags(tagsArray);
  };

  // Add additional ingredient to master ingredient list and unused ingredient list
  const onAddIngredient = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let totalIngredientAmount =
      ingredientAmount + " " + ingredientAmountFraction;

    const ingredientObject = {
      amount: totalIngredientAmount,
      measure: ingredientMeasure,
      name: ingredient,
    };

    // Add ingredient to master ingredient list
    let ingredientsArray = [...ingredients];
    ingredientsArray.push(ingredientObject);
    setIngredients(ingredientsArray);

    // Add ingredient to unused ingredients
    let unusedIngredientsArray = [...unusedIngredients];
    unusedIngredientsArray.push(ingredientObject);
    setUnusedIngredients(unusedIngredientsArray);

    setIngredientAmount("");
    setIngredientAmountFraction("");
    setIngredientMeasure("");
    setIngredient("");
    setIngredientOpenModal(false);
  };

  // Delete ingredient from both master ingredient list and unused ingredient list
  const onIngredientDelete = (
    e: React.MouseEvent<SVGSVGElement>,
    ingredient: any
  ) => {
    e.preventDefault();

    let ingredientsArray = [...ingredients];
    let unusedIngredientsArray = [...unusedIngredients];

    // Remove ingredient from master ingredient list
    let filteredIngredientsArray = ingredientsArray.filter(
      (item) =>
        item.name !== ingredient.name || item.amount !== ingredient.amount
    );
    setIngredients(filteredIngredientsArray);

    // Remove ingredient from unused ingredients list
    let filteredUnusedIngredientsArray = unusedIngredientsArray.filter(
      (item) =>
        item.name !== ingredient.name || item.amount !== ingredient.amount
    );
    setUnusedIngredients(filteredUnusedIngredientsArray);

    // Delete ingredients from the steps that use those ingredients when users delete the ingredient
    let stepsArray = [...steps];

    stepsArray.forEach((step: any, i: number) => {
      let filteredStepIngredientsArray = step.ingredients.filter(
        (itemObject: any) =>
          itemObject.name !== ingredient.name ||
          itemObject.amount !== ingredient.amount
      );
      stepsArray[i].ingredients = filteredStepIngredientsArray;
    });

    setSteps(stepsArray);
  };

  // Add step to the total step list
  const onAddStep = (shownIngredients: Array<string>, stepObject: any) => {
    // Close Modal
    setAddStepModal(false);

    // Set unused ingredients as remaining ingredients after add step modal
    setUnusedIngredients(shownIngredients);

    // Add step to Steps array state
    let stepsArray = [...steps];
    stepsArray.push(stepObject);
    setSteps(stepsArray);
  };

  // Delete ingredient image
  const onDeleteImage = (e: React.MouseEvent<HTMLDivElement>, type: String) => {
    e.preventDefault();

    if (type === "image") {
      setImage(null);
      setImageUrl("");
      setImageDeleted(true);
    } else {
      setIngredientImage(null);
      setIngredientImageUrl("");
      setIngredientImageDeleted(true);
    }
  };

  // Change image from accepted files
  const changeImage = (type: string, image: any, imageUrl: string) => {
    switch (type) {
      case "highlight":
        setImage(image);
        setImageUrl(imageUrl);
        break;
      case "ingredient":
        setIngredientImage(image);
        setIngredientImageUrl(imageUrl);
        break;
      default:
        console.log("No image found");
    }
  };

  // Shift array up and down for steps and ingredients
  function shiftArray(arr: Array<any>, from: number, to: number) {
    let cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
    return arr;
  }

  // Move step up
  const onStepUp = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...steps];
    let newArray = shiftArray(recipeStepsArray, i, i - 1);
    setSteps(newArray);
  };

  // Move step down
  const onStepDown = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...steps];
    let newArray = shiftArray(recipeStepsArray, i, i + 1);
    setSteps(newArray);
  };

  // Move step up
  const onIngredientStepUp = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let recipeIngredientsArray = [...ingredients];
    let newArray = shiftArray(recipeIngredientsArray, i, i - 1);
    setIngredients(newArray);
  };

  // Move step down
  const onIngredientStepDown = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let recipeIngredientsArray = [...ingredients];
    let newArray = shiftArray(recipeIngredientsArray, i, i + 1);
    setIngredients(newArray);
  };

  // Delete steps
  const onStepDelete = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    let stepsArray = [...steps];
    let ingredientsArray = [...unusedIngredients];

    // Add step ingredients back to master recipe ingredients list
    setUnusedIngredients(ingredientsArray.concat(stepsArray[i].ingredients));

    stepsArray.splice(i, 1);
    setSteps(stepsArray);
  };

  // Edit a specific step
  const onStepEdit = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let stepsArray = [...steps];
    let step = stepsArray[i];
    setEditStep(step);
    setEditStepNumber(i);
    setEditModal(true);
  };

  // Upload ingredient image to Firebase storage
  const uploadRecipeImage = async (type: string) => {
    setLoading(true);

    // Upload ingredient image
    if (type === "ingredient") {
      const ext = ingredientImage.type.substr(
        ingredientImage.type.lastIndexOf("/") + 1
      );

      // Initialize storage reference
      const storageRef = ref(storage, `recipes/${id}/ingredient.${ext}`);
      try {
        let imageTemp: any = null;
        await optimizeImage(ingredientImage).then((result: any) => {
          imageTemp = result;
        });
        await uploadBytes(storageRef, imageTemp);
        let url = await getDownloadURL(storageRef);
        return url;
      } catch (e) {
        let url = "";
        return url;
      }
    } else {
      // Upload highlight image
      const ext = image.type.substr(image.type.lastIndexOf("/") + 1);

      // Initialize storage reference
      const storageRef = ref(storage, `recipes/${id}/highlight.${ext}`);
      try {
        let imageTemp: any = null;
        await optimizeImage(image).then((result: any) => {
          imageTemp = result;
        });
        await uploadBytes(storageRef, imageTemp);
        let url = await getDownloadURL(storageRef);
        return url;
      } catch (e) {
        let url = "";
        return url;
      }
    }
  };

  // Save the edited step
  const onSaveStepEdit = (unusedIngredients: Array<any>, stepObject: any) => {
    // Replace step with the edited step
    let stepsArray = [...steps];
    stepsArray[editStepNumber] = stepObject;
    setSteps(stepsArray);

    // Set unused ingredients as remaining ingredients after editing the step
    setUnusedIngredients(unusedIngredients);

    // Close modal
    setEditModal(false);
  };

  // Save edited recipe
  const onSaveEdit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let newIngredientImageUrl = ingredientImageUrl;
    let newImageUrl = imageUrl;

    // Check if an image has been deleted
    if (imageDeleted) {
      const imageRef = ref(storage, recipeData.highlightUrl);
      await deleteObject(imageRef);
    }

    if (ingredientImageDeleted) {
      const imageRef = ref(storage, recipeData.ingredientImageUrl);
      await deleteObject(imageRef);
    }

    // Check if the ingredient image is empty, whether the user has changed the ingredient image
    if (ingredientImage != null) {
      newIngredientImageUrl = await uploadRecipeImage("ingredient");
    }

    // Check if the ingredient image is empty, whether the user has changed the ingredient image
    if (image != null) {
      newImageUrl = await uploadRecipeImage("highlight");
    }

    try {
      await updateDoc(recipeRef, {
        name: name,
        description: description,
        private: privacy === "private" ? true : false,
        inspiration: inspiration,
        cuisine: cuisine,
        prepTime: prepTime,
        cookTime: cookTime,
        tags: tags,
        ingredients: ingredients,
        highlightUrl: newImageUrl,
        ingredientImageUrl: newIngredientImageUrl,
        servingSize: servingSize,
        steps: steps,
      });

      history.goBack();
    } catch (e) {
      alert(e);
    }
  };

  // Go back to previous screen
  const onBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    history.goBack();
  };

  if (status === "loading" || loading === true) {
    return <Loading />;
  }

  return (
    <Block>
      <Container style={{ maxWidth: 996, marginBottom: 90 }}>
        <Columns>
          {imageUrl ? (
            <Columns.Column style={{ marginTop: "1.5em" }}>
              <DeleteImageContainer
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onDeleteImage(e, "image")
                }
              >
                <FontAwesomeIcon
                  color={RED}
                  size={"1x"}
                  style={{ cursor: "pointer" }}
                  icon={faTimesCircle}
                />
                <Body color={RED}> Delete</Body>
              </DeleteImageContainer>
              <ImageCrop
                cropWidth={100}
                imageUrl={imageUrl}
                onFinishedCropping={(croppedFile: any) => {
                  setImage(croppedFile);
                }}
              />
              <Body bold margin={"1em 0 0.5em 0"} color={DARK_GREEN}>
                Change image of the final dish
              </Body>
              <Dropzone
                onFileAccept={(image: any, imageUrl: string) => {
                  changeImage("highlight", image, imageUrl);
                }}
              />
            </Columns.Column>
          ) : (
            <Columns.Column style={{ marginTop: "1.5em" }}>
              <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
                Add an image of the final dish
              </Body>
              <Body margin={"0.5em 0"} color={RED}>
                *Optional
              </Body>
              <Dropzone
                onFileAccept={(image: any, imageUrl: string) => {
                  changeImage("highlight", image, imageUrl);
                }}
              />
            </Columns.Column>
          )}
          <Columns.Column>
            <form>
              <FormField
                label="Recipe Name"
                value={name}
                placeholder="Recipe name"
                onChange={(e: any) => setName(e.target.value)}
              />
              <SelectField
                label="Is this a public or private recipe?"
                text="A public recipe will be shown to everyone on Zesty. Private recipes will only be visible to you."
                value={privacy}
                onChange={(e: any) => setPrivacy(e.target.value)}
              >
                <option value={"private"}>Private</option>
                <option value={"public"}>Public</option>
              </SelectField>
              <FormField
                label="Recipe Cuisine"
                value={cuisine}
                placeholder="Cuisine"
                onChange={(e: any) => setCuisine(e.target.value)}
              />
              <FormTextarea
                label="Recipe Description"
                value={description}
                placeholder="Tell us the story behind your dish"
                onChange={(e: any) => setDescription(e.target.value)}
              />
              <FormField
                label="Serving Size"
                value={servingSize}
                placeholder="Serving size"
                onChange={(e: any) => onChangeServingSize(e)}
              />
              <FormField
                label="How long does the prep take? (minutes)"
                value={prepTime}
                placeholder="Preparation time"
                onChange={(e: any) => onChangeTime(e, "prep")}
              />
              <FormField
                label="How long does the cooking take? (minutes)"
                value={cookTime}
                placeholder="Cooking time"
                onChange={(e: any) => onChangeTime(e, "cook")}
              />
              <FormField
                label="Recipe Inspiration"
                value={inspiration}
                placeholder="Add a link or book title here"
                onChange={(e: any) => setInspiration(e.target.value)}
              />
              <FormField
                label="What are some tags to describe your recipe?"
                text={`Example tags: dinner, lunch, whole30, etc.`}
                value={tag}
                placeholder="dinner"
                onChange={(e: any) => createTag(e)}
              />
              <Button
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  onAddTag(e)
                }
                primary
              >
                Add Tag
              </Button>
              <StyledTagContainer>
                {tags.map((tag: string, i: number) => (
                  <StyledTag key={i}>
                    {tag}
                    <span style={{ marginLeft: 16 }}>
                      <FontAwesomeIcon
                        onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                          onRemoveTag(e, i)
                        }
                        color={GREY}
                        size={"lg"}
                        style={{ cursor: "pointer" }}
                        icon={faTimesCircle}
                      />
                    </span>
                  </StyledTag>
                ))}
              </StyledTagContainer>
            </form>
          </Columns.Column>
        </Columns>
        <hr />
        <Header size={4}>Ingredients</Header>
        <Columns>
          {ingredientImageUrl ? (
            <Columns.Column>
              <DeleteImageContainer
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onDeleteImage(e, "ingredient")
                }
              >
                <FontAwesomeIcon
                  color={RED}
                  size={"1x"}
                  style={{ cursor: "pointer" }}
                  icon={faTimesCircle}
                />
                <Body color={RED}> Delete</Body>
              </DeleteImageContainer>
              <ImageCrop
                cropWidth={100}
                imageUrl={ingredientImageUrl}
                onFinishedCropping={(croppedFile: any) => {
                  setIngredientImage(croppedFile);
                }}
              />

              <Body bold margin={"1em 0 0.5em 0"} color={DARK_GREEN}>
                Change image of all the ingredients
              </Body>
              <Dropzone
                onFileAccept={(image: any, imageUrl: string) => {
                  changeImage("ingredient", image, imageUrl);
                }}
              />
            </Columns.Column>
          ) : (
            <Columns.Column>
              <Body bold margin={"0 0 8px 0"} color={DARK_GREEN}>
                Add an image of all the ingredients
              </Body>
              <Body margin={"0.5em 0"} color={RED}>
                *Optional
              </Body>
              <Dropzone
                onFileAccept={(image: any, imageUrl: string) => {
                  changeImage("ingredient", image, imageUrl);
                }}
              />
            </Columns.Column>
          )}
          <Columns.Column>
            {ingredients.length > 0 ? (
              <StyledIngredientsBlock>
                {ingredients.map((ingredient: any, i: number) => (
                  <IngredientRow
                    key={i}
                    ingredient={ingredient}
                    onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                      onIngredientDelete(e, ingredient)
                    }
                    onStepUp={(e: React.MouseEvent<SVGSVGElement>) =>
                      onIngredientStepUp(e, i)
                    }
                    onStepDown={(e: React.MouseEvent<SVGSVGElement>) =>
                      onIngredientStepDown(e, i)
                    }
                    deletable
                    movable
                  />
                ))}
              </StyledIngredientsBlock>
            ) : null}
            <StyledLink onClick={() => setIngredientOpenModal(true)}>
              Add new ingredient
            </StyledLink>
          </Columns.Column>
        </Columns>
        <hr />
        <Header size={4}>Steps</Header>
        <Columns>
          <Columns.Column size="one-third">
            <Body bold margin={"1em 0"}>
              Unused Ingredients
            </Body>
            {!(unusedIngredients.length === 0) ? (
              <Block>
                <StyledIngredientsBlock>
                  {unusedIngredients.map((ingredient: any, i: number) => (
                    <IngredientRow
                      key={i}
                      ingredient={ingredient}
                      onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                        onIngredientDelete(e, ingredient)
                      }
                      deletable
                    />
                  ))}
                </StyledIngredientsBlock>
              </Block>
            ) : null}
          </Columns.Column>
          <Columns.Column size="two-thirds">
            {steps.map((step: any, i: number) => {
              return (
                <StyledStepContainer key={i}>
                  <Block
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: 8,
                      flexWrap: "wrap",
                    }}
                  >
                    <Subheading size={2} color={DARK}>
                      Step {i + 1}
                    </Subheading>
                    <Block>
                      <FontAwesomeIcon
                        onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                          onStepUp(e, i)
                        }
                        color={DARK_GREEN}
                        size={"lg"}
                        style={{ cursor: "pointer" }}
                        icon={faAngleUp}
                      />
                      <span style={{ marginLeft: 16 }}>
                        <FontAwesomeIcon
                          onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                            onStepDown(e, i)
                          }
                          color={DARK_GREEN}
                          size={"lg"}
                          style={{ cursor: "pointer" }}
                          icon={faAngleDown}
                        />
                      </span>
                      <span style={{ marginLeft: 16 }}>
                        <FontAwesomeIcon
                          onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                            onStepEdit(e, i)
                          }
                          color={DARK_GREEN}
                          size={"lg"}
                          style={{ cursor: "pointer" }}
                          icon={faPencilAlt}
                        />
                      </span>
                      <span style={{ marginLeft: 16 }}>
                        <FontAwesomeIcon
                          onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                            onStepDelete(e, i)
                          }
                          color={RED}
                          size={"lg"}
                          style={{ cursor: "pointer" }}
                          icon={faTimesCircle}
                        />
                      </span>
                    </Block>
                  </Block>
                  <Block
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {step.imageUrl ? (
                      <StyledStepImage imageUrl={step.imageUrl} />
                    ) : null}
                    <span style={{ flex: 2 }}>
                      <Body>{step.description}</Body>
                    </span>
                  </Block>
                  <Block>
                    {step.ingredients.length > 0 ? (
                      <div>
                        <Body
                          margin={"0 0 1em 0"}
                          font="NewYork"
                          color={SECONDARY_80S}
                        >
                          Ingredients needed
                        </Body>
                        {step.ingredients.map((ingredient: any, l: number) => (
                          <Body key={l} margin={"1em 0 0 0"}>
                            {ingredient.amount} {ingredient.measure}{" "}
                            {ingredient.name}
                          </Body>
                        ))}
                      </div>
                    ) : null}
                  </Block>
                  {step.notes ? (
                    <Block>
                      {step.notes.length > 0 ? (
                        <div>
                          <Body
                            margin={"0 0 1em 0"}
                            font="NewYork"
                            color={SECONDARY_80S}
                          >
                            Step notes
                          </Body>
                          {step.notes.map((note: string, i: number) => (
                            <Body key={i}>{note}</Body>
                          ))}
                        </div>
                      ) : null}
                    </Block>
                  ) : null}
                </StyledStepContainer>
              );
            })}
            <StyledLink onClick={() => setAddStepModal(true)}>
              Add a step
            </StyledLink>
          </Columns.Column>
        </Columns>
        <RecipeFooter
          history={history}
          id={id}
          // disabled={unusedIngredients.length > 0 ? true : false}
          disabled={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSaveEdit(e)}
          onBack={(e: React.MouseEvent<HTMLButtonElement>) => onBack(e)}
          buttonLabel={"Save"}
        />
      </Container>
      <Modal
        show={ingredientOpenModal}
        onClose={() => {
          return setIngredientOpenModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body style={{ borderRadius: 20 }}>
            <Container>
              <Header margin={"0 0 0.5em 0"} size={4}>
                Add a new ingredient
              </Header>
              <Body margin={"1em 0"}>
                Adding an ingredient will add it to your overall ingredient
                list, and be available for you to use in your recipe steps.
              </Body>
              <form>
                <Form.Field style={{ marginTop: 8 }} kind="group">
                  <Form.Control>
                    <Input
                      value={ingredientAmount}
                      placeholder={"Amount"}
                      onChange={(e: any) => onChangeIngredientAmount(e)}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Select
                      value={ingredientAmountFraction}
                      onChange={(e) =>
                        setIngredientAmountFraction(e.target.value)
                      }
                    >
                      <option value={""}></option>
                      <option value={"¼"}>¼</option>
                      <option value={"⅓"}>⅓</option>
                      <option value={"½"}>½</option>
                      <option value={"⅔"}>⅔</option>
                      <option value={"¾"}>¾</option>
                    </Select>
                  </Form.Control>
                  <Form.Control>
                    <Select
                      value={ingredientMeasure}
                      onChange={(e) => setIngredientMeasure(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="cup">Cup(s)</option>
                      <option value="tsp">Teaspoon(s)</option>
                      <option value="tbsp">Tablespoon(s)</option>
                      <option value="floz">Fl Oz</option>
                      <option value="oz">Ounce</option>
                      <option value="pinch">Pinch(es)</option>
                      <option value="lbs">Pound(s)</option>
                      <option value="bunch">Bunch(es)</option>
                      <option value="grams">Gram(s)</option>
                      <option value="piece">Piece(s)</option>
                    </Select>
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Form.Control>
                    <Input
                      value={ingredient}
                      placeholder="Mozarella"
                      onChange={(e) => setIngredient(e.target.value)}
                    />
                  </Form.Control>
                </Form.Field>
              </form>
              <Block style={{ flexDirection: "row" }} display="flex">
                <Button
                  margin={"1em 1em 0 0"}
                  primary
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    onAddIngredient(e)
                  }
                >
                  Add ingredient
                </Button>
                <Button
                  margin={"1em 0"}
                  onClick={() => setIngredientOpenModal(false)}
                >
                  Cancel
                </Button>
              </Block>
            </Container>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <AddStepModal
        openModal={addStepModal}
        recipeId={id}
        ingredients={unusedIngredients}
        onClose={() => {
          return setAddStepModal(false);
        }}
        addStep={(shownIngredients: Array<any>, stepObject: any) =>
          onAddStep(shownIngredients, stepObject)
        }
      />
      <EditStepModal
        openModal={editModal}
        recipeId={id}
        step={editStep}
        shownIngredients={unusedIngredients}
        saveEdit={(unusedIngredients: Array<any>, stepObject: any) =>
          onSaveStepEdit(unusedIngredients, stepObject)
        }
        onClose={() => {
          return setEditModal(false);
        }}
      />
    </Block>
  );
};

export default RecipeEdit;
