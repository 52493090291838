import React, { useEffect, useState } from "react";
import Input from "../atoms/Input";
import { connectSearchBox } from "react-instantsearch-dom";

type Props = {
  currentRefinement: string;
  refine: any;
  query: string;
  delay: any;
};

const AlgoliaSearch = (props: Props) => {
  const [query, setQuery] = useState<string>("");

  let timerId: any = null;

  useEffect(() => {
    setQuery(props.query);
    props.refine(props.query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    refine: any
  ) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => refine(e.target.value), props.delay);
    setQuery(e.target.value);
    // refine(e.target.value)
  };
  return (
    <Input
      type="search"
      value={query}
      onChange={(e) => handleChange(e, props.refine)}
      placeholder={"Search recipes"}
    />
  );
};
export default connectSearchBox(AlgoliaSearch);
