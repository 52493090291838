import { Route } from "react-router-dom";

import LandingPage from "./pages/landing";
import Account from "./pages/account";
import Login from "./pages/login";

import RecipeList from "./pages/recipes/RecipeList";
import RecipeViewSwitch from "./pages/recipes/RecipeViewSwitch";
import AllRecipes from "./pages/recipes/AllRecipes";
import SavedRecipes from "./pages/recipes/SavedRecipes";

import RecipeCreateSwitch from "./pages/create/RecipeCreateSwitch";
import Basic from "./pages/create/Basic";
import Steps from "./pages/create/Steps";
import Description from "./pages/create/Description";

import RecipeEdit from "./pages/edit/RecipeEdit";

import MyGroups from "./pages/groups/MyGroups";
import GroupView from "./pages/groups/GroupView";

export const routes = [
  {
    path: "/",
    component: LandingPage,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/app",
    component: Account,
    routes: [
      {
        path: "/app",
        component: AllRecipes,
        exact: true,
      },
      {
        path: "/app/account",
        component: RecipeList,
      },
      {
        path: "/app/saved",
        component: SavedRecipes,
      },
      {
        path: "/app/groups",
        component: MyGroups,
      },
      {
        path: "/app/create",
        component: RecipeCreateSwitch,
        routes: [
          {
            path: "/app/create",
            component: Basic,
            exact: true,
          },
          {
            path: "/app/create/steps",
            component: Steps,
          },
          {
            path: "/app/create/description",
            component: Description,
          },
        ],
      },
      {
        path: "/app/edit",
        component: RecipeEdit,
      },
    ],
  },
  {
    path: "/recipe/:id",
    component: RecipeViewSwitch,
  },
  {
    path: "/group/:id",
    component: GroupView,
  },
];

export function RouteWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props: any) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}
