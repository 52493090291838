import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {v4 as uuidv4} from "uuid";
//Data
import data from "../../data/supportedScrapperSites.json";

// import * as base64js from 'base64-js';


// Firebase
import {doc, setDoc, getDoc} from "@firebase/firestore";
import {useFirestore, useStorage, useUser, useAnalytics} from "reactfire";
import {ref, uploadBytes, getDownloadURL} from "@firebase/storage";
// Components
import {Block, Modal, Columns} from "react-bulma-components";
// Atoms
import Header from "../atoms/Header";
import Button from "../atoms/Button";
import LoadingSmall from "../atoms/LoadingSmall";
// Molecules
import FormField from "../molecules/FormField";
// Styles
import {optimizeImage} from "../../utilities";
import {useHistory, useLocation} from "react-router-dom";
import Body from "../atoms/Body";
import {GREY, RED} from "../../styles/colors";
import firebase from "firebase/compat";
import {getAuth} from "@firebase/auth";

type Props = {
    openModal: boolean;
    onClose: () => void;
};

// Custom Components
const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;


const ScrapeRecipeModal = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recipeId, setRecipeId] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [simplUrl, setSimpleUrl] = useState<string>("");
    const [image, setImage] = useState<any>(null);
    const [imageUrl, setImageUrl] = useState<string>("");
    const [ingredients, setIngredients] = useState<Array<any>>([]);
    const [scrapperResults, setScrapperResults] = useState<string>("");
    const [recipeName, setRecipeName] = useState<string>("");
    const [cookTime, setCookTime] = useState<string>("");
    const [servings, setServings] = useState<string>("");
    const [recipeSteps, setRecipeSteps] = useState<Array<any>>([]);
    const [supportedURL, setSupportedURL] = useState<boolean>(true);
    const [validURL, setValidUrl] = useState<boolean>(true);
    const [scrapeError, setScrapeError] = useState<boolean>(false);


    //uuid used for the new recipe db entry
    const id = uuidv4();

    // Firestore Initialization
    const firestore = useFirestore();

    // React Fire User Details Record
    const {data: user} = useUser();

    // Get react router history
    const history = useHistory();
    const location: any = useLocation();

    // Firestore references for user details and recipe
    const userDetailsRef = doc(firestore, "users", user!.uid, "recipes", id);
    const recipeRef = doc(firestore, "recipes", id);

    // Initialize Firebase storage for image upload
    const storage = useStorage();
    const auth = getAuth();
    const idToken = auth.currentUser?.getIdToken()


    const raw = '{"url":"' + url + '"}';

    let getScrapperHeaders = new Headers();
    getScrapperHeaders.append("Content-Type", "application/json");
    // getScrapperHeaders.append("Authorization", "Bearer " + idToken);
    let requestOptions: RequestInit = {
        method: "POST",
        headers: getScrapperHeaders,
        body: raw,
        redirect: "follow",
    };

    useEffect(() => {
        if (url === "") {
            setValidUrl(true)
            setSupportedURL(true)
            setScrapeError(false)
        }

    }, [url])

    useEffect(() => {
        if (scrapperResults && ingredients) {
            parseResult()
        }
    }, [ingredients]);

    useEffect(() => {
        if (scrapperResults) {
            parseResultIngredients()
        }
    }, [scrapperResults]);

    useEffect(() => {
        if (scrapperResults && recipeSteps && recipeName && image) {
            onRecipeCreate().then(r => "");
        }
    }, [recipeSteps]);

    const scrapeRecipe = async () => {
        setLoading(true);
        setScrapeError(false);
        await fetch(
            "https://us-central1-zesty-48258.cloudfunctions.net/importRecipeAI",
            requestOptions
        )
            .then((response) => response.text())
            .then((result) => {
                setScrapperResults(result);
            })
            .then(() => {
                setRecipeId(id)
            })
            .catch((error) => {
                console.log("error", error);
                setLoading(false);
                setScrapeError(true);
                setSupportedURL(true);
            });
    };

    function validateScraperURL(url_param: any) {
        let simple_url = "";
        if (url_param.includes("www")) {
            simple_url = url_param.substring(12, url_param.length).split("/")[0];
            setSimpleUrl(url_param.substring(12, url_param.length).split("/")[0]);

        } else {
            simple_url = url_param.substring(8, url_param.length).split("/")[0];
            setSimpleUrl(url_param.substring(8, url_param.length).split("/")[0]);
        }
        if (!JSON.stringify(data).includes(simple_url)) {
            setSupportedURL(false);
        } else {
            setSupportedURL(true);
        }

        if (!isValidHttpUrl(url_param)) {
            setValidUrl(false);
            setSupportedURL(true);
        } else {
            setValidUrl(true);
        }
    }

    function isValidHttpUrl(url_param: any) {
        try {
            new URL(url_param);
            return true;
        } catch (e: any) {
            return false;
        }
    }

    const parseResult = () => {
        const resultJson = JSON.parse(scrapperResults);
        setRecipeName(resultJson.title);
        setImage(resultJson.image);
        setImageUrl(resultJson.image);
        setCookTime(resultJson.cookTime);
        setRecipeSteps(parseSteps(resultJson.steps));
        setServings(resultJson.recipeYield);
    }

    const parseResultIngredients = () => {
        const resultJson = JSON.parse(scrapperResults);
        setIngredients(parseIngredients(resultJson.ingredients));
    }

    function parseSteps(steps: any) {
        const stepsArray = steps.split("\n");
        let recipeStepsArray = [...recipeSteps];
        for (let i = 0; i < stepsArray.length; i++) {
            let stepObject;
            stepObject = {
                description: stepsArray[i],
                imageUrl: "",
                ingredients: [],
                notes: null,
            };
            recipeStepsArray.push(stepObject);
        }
        return recipeStepsArray;
    }

    function parseMeasurementAmount(ingredient: any) {
        let measurementAmount = ingredient.match(/\d+([\/.]\d+)?/g); //get measurement
        // const measurement = Array.isArray(measurementAmount);
        let measurementAmountFraction = ingredient.match(/[^\x00-\x7F]+/); //get measurement
        // const measurementFraction = Array.isArray(measurementAmountFraction);
        if (measurementAmount && !measurementAmountFraction) {
            return measurementAmount[0];
        } else if (measurementAmountFraction && !measurementAmount) {
            return measurementAmountFraction[0];
        } else if (measurementAmountFraction && measurementAmount) {
            let amount_index = ingredient.indexOf(measurementAmount);
            let amount_fraction_index = ingredient.indexOf(measurementAmountFraction);
            if (amount_index > amount_fraction_index) {
                return measurementAmountFraction[0];
            } else {
                return measurementAmount[0] + " " + measurementAmountFraction[0];
            }

        }
        return ""
    }

    function parseMeasurementType(ingredient: any) {
        let measurementAmount =
            ingredient.match(/\b(pound|pounds|ounces|ounce|floz|teaspoons|teaspoon|tsp|tsps|cups|cup|quart|quarts|tablesspoons|tablespoon|tbsp|tbsps|liter|liters|a pinch|whole|slice|gram|grams|bunch|bunches|small|big|)\b/); //get measurement
        const measurement = Array.isArray(measurementAmount);
        if (measurement) {
            return measurementAmount.map((measurement: any) => {
                return measurement;
            });
        }
        return ""
    }

    function parseIngredients(ingredients: any) {
        let recipeIngredientsArray: any = [];
        for (let i = 0; i < ingredients.length; i++) {
            //parse ingredient into the needed pieces for our ingredient object
            let measurementAmount = parseMeasurementAmount(ingredients[i]); //get measurement amount from string
            let measurementType = parseMeasurementType(ingredients[i]); //get measurement type from string
            if (measurementType.length > 1) {
                measurementType = measurementType[0]; // we only want the first measurement type provided
            }
            let name = ingredients[i].replace(measurementAmount, '').replace(measurementType, "").replace("((", "(").replace("))", ")")
            let stepObject = {
                name: name,
                measure: measurementType,
                amount: measurementAmount,
            };
            recipeIngredientsArray.push(stepObject);
        }
        return recipeIngredientsArray;
    }

    function onUrlInput(url: any) {
        setUrl(url);
        setScrapeError(false);
        if (url !== "") {
            validateScraperURL(url);
        }
    }

    function onCancel(url: any) {
        setUrl("");
        setLoading(false);
        props.onClose();
    }


    const onRecipeCreate = async () => {
        let newUrl = image;
        // Check if highlight image was found. If so, upload to Firebase storage.
        if (image != null) {
            newUrl = await uploadRecipeImage();
        }

        let creatorObject = {
            id: user!.uid,
            name: user!.displayName,
            profileUrl: user!.photoURL,
        };
        try {
            await setDoc(recipeRef, {
                name: recipeName,
                creator: creatorObject,
                servingSize: servings,
                ingredients: ingredients,
                private: false,
                createdAt: new Date(),
                createdAtTimeStamp: Date.now(),
                completed: true,
                imported: true,
                steps: recipeSteps,
                description: recipeName + ". A delicious recipe imported from " + url.substring(8, url.length).split("/")[0],
                inspiration: url,
                tips: "",
                highlightUrl: newUrl,
                ingredientImageUrl: "",
                prepTime: "",
                cookTime: "",
                tags: [],
                usersLiked: [],
                cuisine: "",
            });

            await setDoc(userDetailsRef, {
                name: recipeName,
                private: false,
                createdAt: new Date(),
                completed: true,
                imported: true,
            });
            history.push({
                pathname: "/app/edit",
                state: {
                    id: id,
                },
            });
            setLoading(false);
        } catch (e) {
            alert(e);
        }
    };

    function b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/png' });
    }

    // Upload Step Image image to Firebase storage
    const uploadRecipeImage = async () => {
        if (image) {
            let blob: any;
            try {
                // console.log("base64: " + image)
                // blob = b64toBlob("data:image/jpeg;base64,"+image);
                // console.log("blob = " + blob);
                blob = await fetch(" https://pure-everglades-19810.herokuapp.com/"+image).then(r => r.blob());
                // blob = await fetch(image).then(r => r.blob());
            } catch (e) {
                return "We encountered an issue fetching the image";
            }
            // Set loading to true
            // setLoading(true);
            if (blob != null) {
                console.log("blob type = " + blob.type)
                const ext = blob.type.substr(blob.type.lastIndexOf("/") + 1);
                console.log("extension = " + ext);

                // Initialize storage reference
                const storageRef = ref(storage, `recipes/${recipeId}/highlight.${ext}`);
                try {
                    let image: any = null;
                    await optimizeImage(blob).then((result: any) => {
                        image = result;
                        console.log("finished resizing the image")
                    });
                    console.log("storage ref: " + storageRef)
                    await uploadBytes(storageRef, image);
                    let url = await getDownloadURL(storageRef);
                    return url;
                } catch (e) {
                    alert(e);
                }
            }
        } else {
            return "";
        }
    };


    return (
        <Modal
            show={props.openModal}
            onClose={() => {
                props.onClose();
            }}
        >
            <Modal.Card>
                <Modal.Card.Body style={{padding: "1.5em", borderRadius: 20}}>
                    <Header margin={"0 0 0.5em 0"} size={4}>
                        Recipe Import
                    </Header>
                    {supportedURL ? (
                        <></>
                    ) : (
                        <Body margin={"0 0 0px 0"} color={RED}>
                            {simplUrl} is not a supported site, we can still import
                            the recipe but please carefully review before saving.
                        </Body>
                    )}
                    {validURL ? (
                        <></>
                    ) : (
                        <Body margin={"0 0 0px 0"} color={RED}>
                            "{url}" is not a valid url.
                        </Body>
                    )}
                    {!scrapeError ? (
                        <></>
                    ) : (
                        <Body margin={"0 0 0px 0"} color={RED}>
                            "We're sorry, we were unable to scrape the recipe from {simplUrl}".
                        </Body>
                    )}

                    <FormField
                        text={'AI-powered Recipe Import! Zesty now rewrites the recipes you import, giving them a unique touch while still preserving the full content of the original recipe. Plus, get an AI-generated image to visualize your dish!'}
                        label={`Please enter the URL of the recipe you would like to import`}
                        value={url}
                        placeholder="Recipe URL"
                        onChange={(e: any) => onUrlInput(e.target.value)}
                    />
                    <ButtonContainer>
                        <Button onClick={onCancel}>Cancel</Button>
                        {
                            loading ? (
                                <LoadingSmall/>
                            ) : (
                                validURL ? (
                                    <Button primary={true} disabled={false} onClick={scrapeRecipe}>Import
                                        Recipe</Button>
                                ) : (
                                    <Button primary={true} disabled={true} onClick={scrapeRecipe}>Import
                                        Recipe</Button>)
                            )}
                    </ButtonContainer>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    );
};

export default ScrapeRecipeModal;
