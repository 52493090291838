import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import Header from "../atoms/Header";
import Body from "../atoms/Body";
import Button from "../atoms/Button";

// Colors
import { WHITE, DARK_GREEN, LIGHT_GREEN } from "../../styles/colors";

const SectionHeader = styled.span`
  color: ${LIGHT_GREEN};
  font-family: "Inter";
  font-size: 1.15rem;
  letter-spacing: 5px;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${DARK_GREEN};
  padding: 3em;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${DARK_GREEN};
  justify-content: center;
  text-align: center;
  max-width: 640px;
  color: ${LIGHT_GREEN};
`;

const BetaSignUp = () => {
  const history = useHistory();

  const onSignUp = (e: Event) => {
    e.preventDefault();

    history.push("/login");
  };

  return (
    <SectionContainer>
      <ContentContainer>
        <SectionHeader>EXCITED?</SectionHeader>
        <Header margin={"1em"} color={WHITE} size={4}>
          Sign up beta access and try out Zesty!
        </Header>
        <Body margin={"0 0 2em 0"} color={LIGHT_GREEN}>
          We’re working hard to develop all the features we know you’ll love.
          Sign up for early access and be one of the first to try it out!
        </Body>
        <Button margin={"0 15em"} primary onClick={(e: any) => onSignUp(e)}>
          Join Zesty!
        </Button>
      </ContentContainer>
    </SectionContainer>
  );
};

export default BetaSignUp;
