// Styles
import styled from "styled-components";
import { BLUE_BG, BLUE } from "../../styles/colors";

// Components
import { Section, Container } from "react-bulma-components";
import Button from "../atoms/Button";
import React from "react";

type Props = {
  history: any;
  id: string;
  ingredients?: Array<any>;
  buttonLabel?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onBack?: any;
  disabled?: boolean;
};

const StyledLink = styled.a`
  font-family: Inter;
  font-size: 16px;
  color: ${BLUE};
`;

const StyledSection = styled(Section)`
  background-color: ${BLUE_BG};
  position: fixed;
  width: 100vw;
  padding-top: 16px;
  padding-bottom: 16px;
  bottom: 0;
  left: 0;
`;

const RecipeFooter = (props: Props) => {
  return (
    <StyledSection>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 996,
        }}
      >
        <StyledLink
          onClick={props.onBack ? props.onBack : () => props.history.goBack()}
        >
          Go back
        </StyledLink>
        <Button disabled={props.disabled} onClick={props.onClick} primary>
          {props.buttonLabel ? props.buttonLabel : "Next"}
        </Button>
      </Container>
    </StyledSection>
  );
};

export default RecipeFooter;
