import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";
type Props = {
    run: boolean;
};

export const GroupsOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root',
                content: 'Welcome to groups! Here is where you can easily share private recipes with your closest friends and family. These groups are only accessible if someone personally invites you to the group',
                disableBeacon: true,
                placement: 'center' as 'center',
            },
            {
                target: '#root > section:nth-child(3) > div > div > div.block > button',
                content: 'When you are ready to start your first group, click here',
                disableBeacon: true,
            },
        ]
    )

    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.groups;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.groups": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }


    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
