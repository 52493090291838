import styled from 'styled-components';

// Components
import { DARK_GREEN, GREY } from '../../styles/colors';
import Body from '../atoms/Body';

const InfoContainer = styled.div`
  margin-bottom: 1em;
`

type Props = {
  label: string,
  value: string,
}

const InfoText = (props: Props) => {
  return (
    <InfoContainer>
      <Body bold color={DARK_GREEN} margin={'0 0 0.5em 0'}>{props.label}</Body>
      <Body color={GREY}>{props.value}</Body>
    </InfoContainer>
  )
}

export default InfoText;