import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faTimesCircle,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";

// Firebase
import { doc, updateDoc, getDoc } from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { logEvent } from "@firebase/analytics";
import { useStorage, useFirestore, useAnalytics } from "reactfire";

// Styles
import {
  BLUE,
  LIGHT_GREEN,
  DARK_GREEN,
  RED,
  LIGHT_YELLOW,
  SECONDARY_80S,
} from "../../styles/colors";

// Components
import { Modal, Container, Block, Columns, Form } from "react-bulma-components";

// Atoms
import Header from "../../components/atoms/Header";
import Button from "../../components/atoms/Button";
import Body from "../../components/atoms/Body";
import Select from "../../components/atoms/Select";
import Input from "../../components/atoms/Input";
import StepNotes from "../../components/atoms/StepNotes";
import Dropzone from "../../components/atoms/Dropzone";

// Molecules
import FormField from "../../components/molecules/FormField";
import FormTextarea from "../../components/molecules/FormTextarea";
import IngredientRow from "../../components/molecules/IngredientRow";

// Organisms
import RecipeFooter from "../../components/organisms/RecipeFooter";
import EditStepModal from "../../components/organisms/EditStepModal";
import LoadingSmall from "../../components/atoms/LoadingSmall";
import { optimizeImage } from "../../utilities";
import ImageCrop from "../../components/organisms/ImageCrop";
import {StepsCreateOnBoarding} from "../onboarding/create/StepsCreateOnBoarding";

// Custom Components
const StyledIngredientsBlock = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 1em;
  margin-bottom: 1em;
`;

const StyledLink = styled.a`
  font-family: Inter;
  font-size: 16px;
  color: ${BLUE};
`;

const StyledStepContainer = styled(Block)`
  background-color: ${LIGHT_YELLOW};
  padding: 1em 1.5em;
  border-radius: 1em;
`;

const StyledStepImage = styled.div.attrs((props: any) => ({
  imageUrl: props.imageUrl || null,
}))`
  background: ${(props) => `url(${props.imageUrl}) no-repeat center center;`};
  background-size: 100%;
  min-width: 150px;
  height: 150px;
  border-radius: 10%;
  margin-right: 16px;
  margin-bottom: 8px;
  flex: 1;
`;

const DeleteImageContainer = styled.div`
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  width: 100px;
  background-size: 100%;
  display: flex;
  padding: 0.25em 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
`;

// Toast notifications
const notifyStepAdded = () =>
  toast("Step successfully added", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "🎉",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });

const notifyNoteAdded = () => {
  toast("Step note added", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "🎉",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

const notifyNoteError = () => {
  toast("Your note's empty!", {
    duration: 4000,
    position: "top-center",
    // Styling
    style: {},
    className: "",
    // Custom Icon
    icon: "❗",
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: "#000",
      secondary: "#fff",
    },
    // Aria
    ariaProps: {
      role: "status",
      "aria-live": "polite",
    },
  });
};

export default function Steps() {
  const [loading, setLoading] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");

  // Modals
  const [ingredientOpenModal, setIngredientOpenModal] =
    useState<boolean>(false);
  const [nextOpenModal, setNextOpenModal] = useState<boolean>(false);
  const [backOpenModal, setBackOpenModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [stepReminderModal, setStepReminderModal] = useState<boolean>(false);

  // Ingredients
  const [ingredientAmount, setIngredientAmount] = useState<string>("0");
  const [ingredientAmountFraction, setIngredientAmountFraction] =
    useState<string>("");
  const [ingredientMeasure, setIngredientMeasure] = useState<String>("");
  const [ingredient, setIngredient] = useState<string>("");
  const [recipeIngredients, setRecipeIngredients] = useState<Array<any>>([]);
  const [shownIngredients, setShownIngredients] = useState<Array<any>>([]);

  // Steps
  const [stepDescription, setStepDescription] = useState<string>("");
  const [stepNote, setStepNote] = useState<string>("");
  const [stepNotes, setStepNotes] = useState<Array<string>>([]);
  const [stepImage, setStepImage] = useState<any>(null);
  const [stepImageUrl, setStepImageUrl] = useState<string>("");
  const [stepIngredients, setStepIngredients] = useState<Array<any>>([]);
  const [recipeSteps, setRecipeSteps] = useState<Array<any>>([]);
  const [editStep, setEditStep] = useState<any>({});
  const [editStepNumber, setEditStepNumber] = useState<number>(0);

  // Get react router history
  const history = useHistory();
  const location: any = useLocation();
  const { id, ingredients } = location.state;

  // Firestore
  const firestore = useFirestore();

  // React Fire Recipes Record
  const recipeRef = doc(firestore, "recipes", id);

  // Initialize Firebase storage for image upload
  const storage = useStorage();

  // Initialize Firebase Analytics
  const analytics = useAnalytics();

  useEffect(() => {
    const checkIngredients = (steps: Array<any>) => {
      let usedIngredients: Array<any> = [];
      steps.forEach((step: any) => {
        step.ingredients.forEach((ingredient: any) => {
          usedIngredients.push(ingredient);
        });
      });
      let result = ingredients.filter(
        (o1: any) =>
          !usedIngredients.some(
            (o2: any) => o1.name === o2.name && o1.amount === o2.amount
          )
      );
      return result;
    };

    async function getRecipeDetails() {
      let recipeSnapshot = await getDoc(recipeRef);
      return recipeSnapshot.data();
    }
    getRecipeDetails().then((recipe: any) => {
      setRecipeSteps(recipe.steps);
      setRecipeIngredients(ingredients);
      setShownIngredients(checkIngredients(recipe.steps));
    });
    // Log an event for creating recipe steps
    logEvent(analytics, "create_recipe_steps");

    // Set Recipe ID
    setRecipeId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear state
  const clearState = () => {
    setLoading(false);
    setStepDescription("");
    setStepImage(null);
    setStepImageUrl("");
    setStepIngredients([]);
    setStepNotes([]);
  };

  // On image drop to drop zone
  const changeImage = (image: any, imageUrl: string) => {
    setStepImage(image);
    setStepImageUrl(imageUrl);
  };

  // Select ingredients
  const onIngredientSelect = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let stepIngredientsArray = [...stepIngredients];
    let ingredient = shownIngredients[i];
    stepIngredientsArray.push(ingredient);
    setStepIngredients(stepIngredientsArray);

    let ingredientsArray = [...shownIngredients];
    ingredientsArray.splice(i, 1);
    setShownIngredients(ingredientsArray);
  };

  const onStepIngredientDelete = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let ingredientsArray = [...shownIngredients];
    let ingredient = stepIngredients[i];
    ingredientsArray.push(ingredient);
    setShownIngredients(ingredientsArray);

    let stepIngredientsArray = [...stepIngredients];
    stepIngredientsArray.splice(i, 1);
    setStepIngredients(stepIngredientsArray);
  };

  // Add tip for a step
  const onAddTip = (e: any) => {
    e.preventDefault();

    if (stepNote.trim().length > 0) {
      const notesArray = [...stepNotes];
      notesArray.push(stepNote);
      setStepNotes(notesArray);
      setStepNote("");
      notifyNoteAdded();
    } else {
      notifyNoteError();
    }
  };

  const onDeleteNote = (i: number) => {
    let notesArray = [...stepNotes];
    notesArray.splice(i, 1);
    setStepNotes(notesArray);
  };

  // Handle the tip input, add tip when the user hits "enter"
  const addTip = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onAddTip(e);
    } else {
      setStepNote(e.target.value);
    }
  };

  // Upload Step Image image to Firebase storage
  const uploadRecipeImage = async () => {
    // set loading to true
    setLoading(true);

    const ext = stepImage.type.substr(stepImage.type.lastIndexOf("/") + 1);

    const stepImageUUID = uuidv4();

    // Initialize storage reference
    const storageRef = ref(
      storage,
      `recipes/${recipeId}/${stepImageUUID}.${ext}`
    );
    try {
      let image: any = null;
      await optimizeImage(stepImage).then((result: any) => {
        image = result;
      });
      await uploadBytes(storageRef, image);
      let url = await getDownloadURL(storageRef);
      return url;
    } catch (e) {
      alert(e);
      return "";
    }
  };

  // Delete image for step
  const onDeleteImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setStepImage(null);
    setStepImageUrl("");
  };

  // Add step
  const onAddStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let newUrl = stepImageUrl;

    // Check if the stepImage is empty, whether the user has changed the ingredient image
    if (stepImage != null) {
      newUrl = await uploadRecipeImage();
      console.log("saving step image with download URL: " + newUrl);
    }

    let recipeStepsArray = [...recipeSteps];
    let stepObject = {
      description: stepDescription,
      imageUrl: newUrl,
      ingredients: stepIngredients,
      notes: stepNotes,
    };

    recipeStepsArray.push(stepObject);
    setRecipeSteps(recipeStepsArray);
    notifyStepAdded();
    clearState();
  };

  // Check ingredient amount of letters or symbols. Only allow numbers to set ingredient amount
  const onChangeIngredientAmount = (e: any) => {
    e.preventDefault();
    let value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    setIngredientAmount(value);
  };

  // Add additional ingredient to master ingredient list
  const onAddIngredient = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    let totalIngredientAmount =
      ingredientAmount + " " + ingredientAmountFraction;

    const ingredientObject = {
      amount: totalIngredientAmount,
      measure: ingredientMeasure,
      name: ingredient,
    };

    let recipeIngredientsArray = [...recipeIngredients];
    recipeIngredientsArray.push(ingredientObject);
    setRecipeIngredients(recipeIngredientsArray);

    let shownIngredientsArray = [...shownIngredients];
    shownIngredientsArray.push(ingredientObject);
    setShownIngredients(shownIngredientsArray);

    setIngredientAmount("");
    setIngredientAmountFraction("");
    setIngredientMeasure("");
    setIngredient("");
    setIngredientOpenModal(false);
  };

  // Set modal before going back
  const onBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setBackOpenModal(true);
  };

  // Add Steps to firebase storage
  const onAddSteps = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (shownIngredients.length > 0) {
      setNextOpenModal(true);
    } else if (stepDescription.trim().length > 0) {
      setStepReminderModal(true);
    } else {
      try {
        updateDoc(recipeRef, {
          ingredients: recipeIngredients,
          steps: recipeSteps,
        });
        history.push({
          pathname: "/app/create/description",
          state: {
            id: id,
          },
        });
      } catch (e) {
        alert(e);
      }
    }
  };

  // Moving steps up and down
  function shiftArray(arr: Array<any>, from: number, to: number) {
    let cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);
    return arr;
  }

  const onStepUp = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...recipeSteps];
    let newArray = shiftArray(recipeStepsArray, i, i - 1);
    setRecipeSteps(newArray);
  };

  const onStepDown = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let recipeStepsArray = [...recipeSteps];
    let newArray = shiftArray(recipeStepsArray, i, i + 1);
    setRecipeSteps(newArray);
  };

  // Delete steps
  const onStepDelete = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    let stepsArray = [...recipeSteps];
    let ingredientsArray = [...shownIngredients];

    // Add step ingredients back to master recipe ingredients list
    setShownIngredients(ingredientsArray.concat(stepsArray[i].ingredients));

    stepsArray.splice(i, 1);
    setRecipeSteps(stepsArray);
  };

  // Edit a specific step
  const onStepEdit = (e: React.MouseEvent<SVGSVGElement>, i: number) => {
    e.preventDefault();

    let stepsArray = [...recipeSteps];
    let step = stepsArray[i];
    setEditStep(step);
    setEditStepNumber(i);
    setEditModal(true);
  };

  // Save the edited step
  const onSaveEdit = (shownIngredients: Array<any>, stepObject: any) => {
    let stepsArray = [...recipeSteps];
    stepsArray[editStepNumber] = stepObject;

    setRecipeSteps(stepsArray);
    setShownIngredients(shownIngredients);
    setEditModal(false);
  };

  return (
    <Container>
      <StepsCreateOnBoarding run={true}/>
      <Container style={{ maxWidth: 996, marginBottom: 90 }}>
        <Block>
          <Header size={2}>📖 What are the steps?</Header>
          <form>
            <Header size={4}>Add a step</Header>
            <FormTextarea
              label="Step description"
              value={stepDescription}
              placeholder="The description of each step should be written as clearly as possible and should identify specific actions associated with the recipe. We recommend that you create separate steps based on timing and actions. For instance, here is the first step of a mushroom omelette. Step 1: heat the skillet on medium heat and then add butter. Once the butter has fully melted add chopped mushrooms and minced garlic and saute for 4-5 mins stirring frequently until cooked. Take off heat and let sit."
              onChange={(e: any) => setStepDescription(e.target.value)}
            />
            <FormField
              label="Any helpful tips you want to add?"
              text={`Make sure to press enter to add the tip.`}
              value={stepNote}
              placeholder="Add any helpful tip you want the viewer to know about"
              onKeyDown={(e: any) => addTip(e)}
              onChange={(e: any) => addTip(e)}
            />
            {stepNotes.length > 0 ? (
              <StepNotes
                onDelete={(i: number) => onDeleteNote(i)}
                deletable
                notes={stepNotes}
              />
            ) : null}
            <div>
            <Body bold margin={"1.5em 0 1em 0"} color={DARK_GREEN}>
              Add an image for the step
            </Body>
            <Body margin={"0.5em 0"} color={RED}>
              *Optional
            </Body>
            <Dropzone
              onFileAccept={(image: any, imageUrl: string) => {
                changeImage(image, imageUrl);
              }}
            />
            {stepImageUrl ? (
              <>
                <DeleteImageContainer
                  onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                    onDeleteImage(e)
                  }
                >
                  <FontAwesomeIcon
                    color={RED}
                    size={"1x"}
                    style={{ cursor: "pointer" }}
                    icon={faTimesCircle}
                  />
                  <Body color={RED}> Delete</Body>
                </DeleteImageContainer>
                <ImageCrop
                  cropWidth={75}
                  imageUrl={stepImageUrl}
                  onFinishedCropping={(croppedFile: any) => {
                    setStepImage(croppedFile);
                  }}
                />
              </>
            ) : null}
            </div>
            <Block>
              <Columns>
                <Columns.Column size="half">
                  <Body
                    margin={"0 0 1em 0"}
                    font="NewYork"
                    color={SECONDARY_80S}
                  >
                    🍴 Available ingredients
                  </Body>
                  {shownIngredients.length > 0 ? (
                    <StyledIngredientsBlock>
                      {shownIngredients.map((ingredient: any, i: number) => (
                        <IngredientRow
                          key={i}
                          onSelect={(e: React.MouseEvent<SVGSVGElement>) =>
                            onIngredientSelect(e, i)
                          }
                          ingredient={ingredient}
                          selectable
                        />
                      ))}
                    </StyledIngredientsBlock>
                  ) : null}
                  <StyledLink onClick={() => setIngredientOpenModal(true)}>
                    Add new ingredient
                  </StyledLink>
                </Columns.Column>
                <Columns.Column>
                  <Body
                    margin={"0 0 1em 0"}
                    font="NewYork"
                    color={SECONDARY_80S}
                  >
                    🍴 Ingredients needed in this step
                  </Body>
                  {stepIngredients.map((ingredient: any, i: number) => (
                    <IngredientRow
                      key={i}
                      onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                        onStepIngredientDelete(e, i)
                      }
                      ingredient={ingredient}
                      deletable
                    />
                  ))}
                </Columns.Column>
              </Columns>
            </Block>
            {loading ? (
              <LoadingSmall />
            ) : (
              <Button
                disabled={stepDescription === "" ? true : false}
                onClick={(e) => onAddStep(e)}
                primary
              >
                Add recipe step
              </Button>
            )}
          </form>
        </Block>
        <hr />
        {recipeSteps.length > 0 ? <Header size={4}>Steps added</Header> : null}
        {recipeSteps.map((step: any, i: number) => {
          return (
            <StyledStepContainer key={i}>
              <Block
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 8,
                  flexWrap: "wrap",
                }}
              >
                <Header size={5} margin={"0 0 1em 0"} color={SECONDARY_80S}>
                  Step {i + 1}
                </Header>
                <Block>
                  <FontAwesomeIcon
                    onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                      onStepUp(e, i)
                    }
                    color={DARK_GREEN}
                    size={"lg"}
                    style={{ cursor: "pointer" }}
                    icon={faAngleUp}
                  />
                  <span style={{ marginLeft: 16 }}>
                    <FontAwesomeIcon
                      onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                        onStepDown(e, i)
                      }
                      color={DARK_GREEN}
                      size={"lg"}
                      style={{ cursor: "pointer" }}
                      icon={faAngleDown}
                    />
                  </span>
                  <span style={{ marginLeft: 16 }}>
                    <FontAwesomeIcon
                      onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                        onStepEdit(e, i)
                      }
                      color={DARK_GREEN}
                      size={"lg"}
                      style={{ cursor: "pointer" }}
                      icon={faPencilAlt}
                    />
                  </span>
                  <span style={{ marginLeft: 16 }}>
                    <FontAwesomeIcon
                      onClick={(e: React.MouseEvent<SVGSVGElement>) =>
                        onStepDelete(e, i)
                      }
                      color={RED}
                      size={"lg"}
                      style={{ cursor: "pointer" }}
                      icon={faTimesCircle}
                    />
                  </span>
                </Block>
              </Block>
              <Block
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {step.imageUrl ? (
                  <StyledStepImage imageUrl={step.imageUrl} />
                ) : null}
                <span style={{ flex: 2 }}>
                  <Body>{step.description}</Body>
                </span>
              </Block>
              <Block>
                {step.ingredients.length > 0 ? (
                  <div>
                    <Body
                      margin={"0 0 1em 0"}
                      font="NewYork"
                      color={SECONDARY_80S}
                    >
                      🍴 Ingredients needed
                    </Body>
                    {step.ingredients.map((ingredient: any, l: number) => (
                      <Body key={l} margin={"1em 0 0 0"}>
                        {ingredient.amount} {ingredient.measure}{" "}
                        {ingredient.name}
                      </Body>
                    ))}
                  </div>
                ) : null}
              </Block>
              <Block>
                {step.notes.length > 0 ? (
                  <div>
                    <Body
                      margin={"0 0 1em 0"}
                      font="NewYork"
                      color={SECONDARY_80S}
                    >
                      Step notes
                    </Body>
                    {step.notes.map((note: string, i: number) => (
                      <Body key={i}>{note}</Body>
                    ))}
                  </div>
                ) : null}
              </Block>
            </StyledStepContainer>
          );
        })}
      </Container>
      <RecipeFooter
        history={history}
        id={id}
        disabled={recipeSteps.length > 0 ? false : true}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => onAddSteps(e)}
        onBack={(e: React.MouseEvent<HTMLButtonElement>) => onBack(e)}
      />
      <Modal
        show={nextOpenModal}
        onClose={() => {
          return setNextOpenModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body
            style={{ backgroundColor: LIGHT_GREEN, borderRadius: 20 }}
          >
            <Header margin={"0.5em 0"} size={4}>
              Did you forget something?
            </Header>
            <Body margin={"1em 0"}>
              There's still some ingredients left that you haven't used. Make
              sure to add in all the necessary steps for this recipe.
            </Body>
            <Button primary onClick={() => setNextOpenModal(false)}>
              Got it
            </Button>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <Modal
        show={stepReminderModal}
        onClose={() => {
          return setStepReminderModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body
            style={{ backgroundColor: LIGHT_GREEN, borderRadius: 20 }}
          >
            <Header margin={"0.5em 0"} size={4}>
              Did you forget to finish adding your step?
            </Header>
            <Body margin={"1em 0"}>
              It looks like you've added a step description. Make sure to click
              the "Add recipe step" button beneath the ingredients picker to
              finish adding the step to the recipe
            </Body>
            <Button primary onClick={() => setStepReminderModal(false)}>
              Got it
            </Button>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <Modal
        show={backOpenModal}
        onClose={() => {
          return setBackOpenModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body
            style={{ backgroundColor: LIGHT_GREEN, borderRadius: 20 }}
          >
            <Header margin={"0.5em 0"} size={4}>
              Are you sure?
            </Header>
            <Body margin={"1em 0"}>
              When you go back, you'll lose any progress that you made on this
              page.
            </Body>
            <Body margin={"1em 0"}>
              To save your steps, click "Next" and then you can go back to edit.
            </Body>
            <Container style={{ display: "flex", flexDirection: "row" }}>
              <Button
                margin={"0 1em 0 0"}
                primary
                onClick={() => setBackOpenModal(false)}
              >
                Finish steps
              </Button>
              <Button onClick={() => history.goBack()}>Go back</Button>
            </Container>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <Modal
        show={ingredientOpenModal}
        onClose={() => {
          return setIngredientOpenModal(false);
        }}
      >
        <Modal.Card>
          <Modal.Card.Body style={{ borderRadius: 20 }}>
            <Container>
              <Header margin={"0 0 0.5em 0"} size={4}>
                Add a new ingredient
              </Header>
              <Body margin={"1em 0"}>
                Adding an ingredient will add it to your overall ingredient
                list, and be available for you to use in your recipe steps.
              </Body>
              <form>
                <Form.Field style={{ marginTop: 8 }} kind="group">
                  <Form.Control>
                    <Input
                      value={ingredientAmount}
                      placeholder={"Amount"}
                      onChange={(e: any) => onChangeIngredientAmount(e)}
                    />
                  </Form.Control>
                  <Form.Control>
                    <Select
                      value={ingredientAmountFraction}
                      onChange={(e) =>
                        setIngredientAmountFraction(e.target.value)
                      }
                    >
                      <option value={""}></option>
                      <option value={"¼"}>¼</option>
                      <option value={"⅓"}>⅓</option>
                      <option value={"½"}>½</option>
                      <option value={"⅔"}>⅔</option>
                      <option value={"¾"}>¾</option>
                    </Select>
                  </Form.Control>
                  <Form.Control>
                    <Select
                      value={ingredientMeasure}
                      onChange={(e) => setIngredientMeasure(e.target.value)}
                    >
                      <option value=""></option>
                      <option value="cup">Cup(s)</option>
                      <option value="tsp">Teaspoon(s)</option>
                      <option value="tbsp">Tablespoon(s)</option>
                      <option value="floz">Fl Oz</option>
                      <option value="oz">Ounce</option>
                      <option value="pinch">Pinch(es)</option>
                      <option value="lbs">Pound(s)</option>
                      <option value="bunch">Bunch(es)</option>
                      <option value="grams">Gram(s)</option>
                      <option value="piece">Piece(s)</option>
                    </Select>
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Form.Control>
                    <Input
                      value={ingredient}
                      placeholder="Mozarella"
                      onChange={(e) => setIngredient(e.target.value)}
                    />
                  </Form.Control>
                </Form.Field>
              </form>
              <Block display="flex" style={{ flexDirection: "row" }}>
                <Button
                  margin={"1em 1em 0 0"}
                  primary
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    onAddIngredient(e)
                  }
                >
                  Add ingredient
                </Button>
                <Button
                  margin={"1em 0"}
                  onClick={() => setIngredientOpenModal(false)}
                >
                  Cancel
                </Button>
              </Block>
            </Container>
          </Modal.Card.Body>
        </Modal.Card>
      </Modal>
      <EditStepModal
        openModal={editModal}
        recipeId={recipeId}
        step={editStep}
        shownIngredients={shownIngredients}
        saveEdit={(shownIngredients: Array<any>, stepObject: any) =>
          onSaveEdit(shownIngredients, stepObject)
        }
        onClose={() => {
          return setEditModal(false);
        }}
      />
    </Container>
  );
}
