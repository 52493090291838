import styled from "styled-components";
import { doc } from "@firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";

// Components
import Body from "../atoms/Body";
import LoadingSmall from "../atoms/LoadingSmall";

// Styles
import { GREY, DARK, DARK_GREEN } from "../../styles/colors";

type Props = {
  id: string;
  active: boolean;
  onClick: () => void;
};

const RecipeRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  justify-content: space-between;
  align-items: center;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledCreatorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`}
  background-size: 100%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const RecipeSelect = (props: Props) => {
  const recipeRef = doc(useFirestore(), "recipes", props.id);
  const { status, data } = useFirestoreDocDataOnce<any>(recipeRef);

  if (status === "loading") {
    return <LoadingSmall />;
  }

  return (
    <RecipeRow>
      <ContentBlock>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Body>{data.name}</Body>
          <StyledCreatorBlock>
            <StyledProfileImage profileHighlightUrl={data.creator.profileUrl} />
            <Body color={DARK}>{data.creator.name}</Body>
          </StyledCreatorBlock>
        </div>
      </ContentBlock>
      {props.active ? (
        <FontAwesomeIcon
          onClick={props.onClick}
          color={DARK_GREEN}
          size={"lg"}
          icon={faDotCircle}
        />
      ) : (
        <FontAwesomeIcon
          onClick={props.onClick}
          color={GREY}
          size={"lg"}
          icon={faCircle}
        />
      )}
    </RecipeRow>
  );
};

export default RecipeSelect;
