import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";

type Props = {
    run: boolean;
};

export const BasicCreateOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root > section:nth-child(3) > div > div > div.container > form > div:nth-child(1)',
                content: 'We’ll begin by filling out some basic information, including the name, whether you want the recipe to be public or private, cuisine type, and how many people it serves',
                disableBeacon: true,
                disableScroll: true,
                placement: 'top' as 'top',

            },
            {
                target: '#root > section:nth-child(3) > div > div > div.container > form > div:nth-child(2)',
                content: 'If you have it, add a picture of all of the recipe ingredients to help others when they cook it',
                disableBeacon: true,
                placement: 'top' as 'top',
            },
            {
                target: '#root > section:nth-child(3) > div > div > div.container > form > div.block',
                content: 'Next, we’ll add all of the ingredients needed for the recipe one at a time. Write in the amount and the name of the ingredient, and then use the dropdown bar to specify the metric and any necessary fractions.',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > div.container > div > h4',
                content: 'When an ingredient is created, it will be added under this list heading! You can then edit, delete or reorder the ingredients as you please.',
                disableBeacon: true,
            },
            {
                target: '#root > section:nth-child(3) > div > div > section > div > button',
                content: ' Now you give it a try! When those steps are all completed, click next to proceed to the next step',
                disableBeacon: true,
                placement: 'right' as 'right',

            },
        ]
    )

    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.recipeCreateBasic;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
            window.scrollTo(0, 0);
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.recipeCreateBasic": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
