import React, {useState} from "react";
import {useHistory} from "react-router";
import styled from "styled-components";
import {PRIMARY_BASE, WHITE} from "../styles/colors";

// Firebase
import {doc, collection, setDoc, getDoc} from "firebase/firestore";
import {
    getAuth, sendPasswordResetEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider,
} from "@firebase/auth";
import {logEvent} from "@firebase/analytics";
import {useFirestore, useAnalytics} from "reactfire";

// Components
import {Container} from "react-bulma-components";
import Body from "../components/atoms/Body";
import Header from "../components/atoms/Header";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import Subheading from "../components/atoms/Subheading";

// Styles
import {GREY} from "../styles/colors";

import googleSignIn from "../assets/google-sign-in.png";

const StyledBox = styled.div`
  background-color: ${WHITE};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 2em;
  margin: 2em;
  text-align: left;
`;

const Login = () => {
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [register, setRegister] = useState<boolean>(false);
    const [passwordReset, setPasswordReset] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const history = useHistory();

    const firestore = useFirestore();
    const analytics = useAnalytics();
    const auth = getAuth();

    const userRef = collection(firestore, "users");

    let email = "theoquimby@gmail.com";
    sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });

    const addUser = async (user: any) => {
        try {
            let specificUserRef = doc(userRef, user.uid);
            await setDoc(specificUserRef, {
                name: user.displayName,
                email: user.email,
                profileUrl: user.photoURL,
                newUser: true,
                userLists: ["My saved recipes"],
            });
            history.push({
                pathname: "/app",
            });
        } catch (e: any) {
            alert("Error: " + e.message);
        }
    };

    const signInWithGoogle = async (e: React.MouseEvent<HTMLImageElement>) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();

        await signInWithPopup(auth, provider)
            .then(async (result: any) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential?.accessToken;
                // Get the signed-in user info
                const user = result.user;
                if (user) {
                    let specificUserRef = doc(userRef, user.uid);
                    let userRecord = await getDoc(specificUserRef);

                    if (!userRecord.exists()) {
                        logEvent(analytics, "new_account_create", {
                            user: user.uid,
                        });
                        addUser(user);
                    } else {
                        // Log an event for an existing login
                        logEvent(analytics, "existing_login_received", {
                            user: user.uid,
                        });
                        history.push({
                            pathname: "/app",
                        });
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    async function signInWithTestEmail(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        let email = "test@getzesty.app";
        let password = "testpassword";

        try {
            await signInWithEmailAndPassword(auth, email, password).then(
                async (userCredential) => {
                    const user = userCredential.user;
                    if (user) {
                        let specificUserRef = doc(userRef, user.uid);
                        let userRecord = await getDoc(specificUserRef);

                        if (!userRecord.exists) {
                            addUser(user);
                        } else {
                            history.push({
                                pathname: "/app",
                            });
                        }
                    }
                }
            );
        } catch (e) {
            console.log(e);
        }
    }

    const logInWithEmailAndPassword = async (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();

        let trimmedEmail = emailAddress.trim();
        let trimmedPassword = password.trim();

        try {
            await signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
                .then((userCredential) => {
                    const user = userCredential.user;
                    if (user) {
                        history.push({
                            pathname: "/app",
                        });
                    }
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("Error code:", errorCode);
                    console.log("Error Message:", errorMessage);
                    if (errorCode === "auth/wrong-password") {
                        setErrorMessage("Wrong password. Please try again.");
                    }
                    if (errorCode === "auth/email-already-in-use") {
                        setErrorMessage(
                            "You already have an account with us! Try logging in."
                        );
                    } else if (errorCode === "auth/user-not-found") {
                        setErrorMessage(
                            "This email is not registered. Try registering first!"
                        );
                    } else {
                        setErrorMessage(
                            "Sorry, we're having trouble logging you in. Please try again or create a new account"
                        );
                    }
                });
        } catch (e) {
            console.log(e);
        }
    };


    const signUpWithEmailAndPassword = async (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();

        let trimmedEmail = emailAddress.trim();
        let trimmedPassword = password.trim();

        try {
            createUserWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    if (user) {
                        addUser(userCredential.user);
                    }
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log("Error code:", errorCode);
                    console.log("Error Message:", errorMessage);
                    if (errorCode === "auth/email-already-in-use") {
                        setErrorMessage(
                            "You already have an account with us! Try logging in."
                        );
                    } else if (errorCode === "auth/user-not-found") {
                        setErrorMessage(
                            "This email is not registered. Try registering first!"
                        );
                    } else {
                        setErrorMessage(
                            "Sorry, we're having trouble logging you in. Please try again or create a new account"
                        );
                    }
                });
        } catch (err) {
            console.error("Failed creating user:", err);
        }
    };

    const renderRegisterContainer = () => (
        <StyledBox>
            <img
                style={{cursor: "pointer"}}
                onClick={(e: React.MouseEvent<HTMLImageElement>) => signInWithGoogle(e)}
                src={googleSignIn}
            />
            <FormField
                label="Email"
                value={emailAddress}
                placeholder="Email Address"
                onChange={(e: any) => setEmailAddress(e.target.value)}
                error={errorMessage === "" ? false : true}
                errorText={errorMessage === "" ? "" : errorMessage}
            />
            <FormField
                label="Password"
                value={password}
                placeholder="Password"
                type="password"
                onChange={(e: any) => setPassword(e.target.value)}
            />
            <Button
                primary
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    signUpWithEmailAndPassword(e)
                }
            >
                Register
            </Button>
            <Body margin={"1em 0"}>
                Already have an account with us? Click{" "}
                <span
                    onClick={() => {
                        setEmailAddress("");
                        setPassword("");
                        setErrorMessage("");
                        setRegister(false);
                        setPasswordReset(false);
                        setSent(false);
                    }}
                    style={{cursor: "pointer", color: PRIMARY_BASE}}
                >
          here
        </span>{" "}
                to login
            </Body>
        </StyledBox>
    );

    const renderLoginContainer = () => (
        <StyledBox>
            <img
                style={{cursor: "pointer"}}
                onClick={(e: React.MouseEvent<HTMLImageElement>) => signInWithGoogle(e)}
                src={googleSignIn}
            />
            <FormField
                label="Email"
                value={emailAddress}
                error={errorMessage === "" ? false : true}
                errorText={errorMessage === "" ? "" : errorMessage}
                placeholder="Email Address"
                onChange={(e: any) => setEmailAddress(e.target.value)}
            />
            <FormField
                label="Password"
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e: any) => setPassword(e.target.value)}
            />
            <Body margin={"1em 0"}>
                <span
                    style={{cursor: "pointer", color: PRIMARY_BASE}}
                    onClick={() => {
                        setPasswordReset(true);
                        setSent(false);
                    }}
                >
          Forgot your password?
        </span>
            </Body>
            <Button
                primary
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    logInWithEmailAndPassword(e)
                }
            >
                Login
            </Button>
            <Body margin={"1em 0"}>
                Need to create a new account? Click{" "}
                <span
                    style={{cursor: "pointer", color: PRIMARY_BASE}}
                    onClick={() => {
                        setEmailAddress("");
                        setPassword("");
                        setErrorMessage("");
                        setRegister(true);
                        setPasswordReset(false);
                        setSent(false);
                    }}
                >
          here
        </span>{" "}
                to register
            </Body>
        </StyledBox>
    );

    const renderPasswordResetContainer = () => (
        <StyledBox>
            <FormField
                label="Email"
                value={emailAddress}
                placeholder="Email Address"
                onChange={(e: any) => setEmailAddress(e.target.value)}
                error={errorMessage === "" ? false : true}
                errorText={errorMessage === "" ? "" : errorMessage}
            />
            <Button
                primary
                onClick={() => {
                    setSent(true)
                    setPasswordReset(false)
                    sendPasswordResetEmail(auth, emailAddress)
                }}
            >
                Reset Password
            </Button>
        </StyledBox>
    );

    const renderPasswordResetMessage = () => (
        <StyledBox>
            <span>
                Please check your inbox for a password reset email.
            </span>
        </StyledBox>
    );

    return (
        <Container style={{maxWidth: 760, textAlign: "center"}}>
            <Header margin={"1em 0 0.5em 0"} size={1}>
                Welcome to Zesty
            </Header>
            <Subheading size={2} color={GREY}>
                Thanks for your interest in our platform!
                <br/>
                <br/>
                We're currently in beta, so you may see some areas for improvement. Feel
                free to email us at info@getzesty.app if you see anything that we can do
                better!
            </Subheading>
            <Button
                hidden
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    signInWithTestEmail(e)
                }
            >
                Test sign in
            </Button>
            {sent && renderPasswordResetMessage()}
            {register && !passwordReset && renderRegisterContainer()}
            {!register && !passwordReset && renderLoginContainer()}
            {passwordReset && renderPasswordResetContainer()}


        </Container>
    );
};

export default Login;
