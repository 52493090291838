import React from "react";
import styled from "styled-components";

// Styles
import { DARK_BASE } from "../../styles/colors";

type Props = {
  children: any;
  color?: string;
  margin?: string;
  bold?: boolean;
  font?: string;
};

const StyledBody = styled("p")<Props>`
  font-size: 1rem;
  font-family: ${(props) =>
    props.font === "NewYork" ? "NewYorkExtraLarge-Regular" : "Inter"};
  color: ${(props) => (props.color ? props.color : DARK_BASE)};
  margin: ${(props) => (props.margin ? props.margin : "none")};
  font-weight: ${(props) => (props.bold ? 700 : 400)};
`;

export default function Header(props: Props) {
  return (
    <StyledBody
      font={props.font}
      bold={props.bold}
      margin={props.margin}
      color={props.color}
    >
      {props.children}
    </StyledBody>
  );
}
