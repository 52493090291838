import React from "react";
import styled from "styled-components";

// Colors
import {GREY} from "../../styles/colors";

type Props = {
  children: any;
  color?: string;
  size: number;
  margin?: string;
};

const StyledSubheader = styled("h5") <Props>`
  font-size: ${(props) => (props.size === 1 ? "1.35em" : "1.2em")};
  font-family: Inter;
  color: ${(props) => (props.color ? props.color : GREY)};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => (props.size === 1 ? 700 : 400)};
`;

export default function Subheader(props: Props) {
  return (
    <StyledSubheader
      margin={props.margin}
      size={props.size}
      color={props.color}
    >
      {props.children}
    </StyledSubheader>
  );
}
