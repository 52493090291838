import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";

// Firebase
import { useSigninCheck, useAuth } from "reactfire";

// Components
import { Section, Container } from "react-bulma-components";
import LoadingSmall from "../atoms/LoadingSmall";

// Styles
import {
  LIGHT_GREEN,
  BLUE,
  DARK,
  LIGHT_GREY,
  DARK_GREEN,
  WHITE,
} from "../../styles/colors";

const StyledHeader = styled(Section)`
  background-color: ${LIGHT_GREEN};
  height: 80px;
  display: flex;
`;

const StyledLink = styled(Link)`
  color: ${BLUE};
  font-weight: bold;
  font-size: 18px;
  margin-right: 2em;

  &:hover {
    color: ${BLUE};
    opacity: 70%;
  }
`;

const StyledCTA = styled.a`
  color: ${BLUE};
  font-weight: bold;
  font-size: 18px;
  margin-right: 16px;

  &:hover {
    color: ${BLUE};
    opacity: 70%;
  }
`;

const StyledNavLink = styled(Link)<{ $active: boolean }>`
  color: ${(props) => (props.$active ? DARK : LIGHT_GREY)};
  margin-right: 2em;

  &:hover {
    color: ${DARK};
  }
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`};
  background-size: 100%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  margin-right: 8px;
`;

const TopContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SideNav = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 3em;
    position: fixed;
    z-index: 9999;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: ${DARK_GREEN};
  `,

  StyledLink: styled(Link)<{ $active: boolean }>`
    color: ${(props) => (props.$active === true ? WHITE : LIGHT_GREY)};
    margin-bottom: 1em;

    &:hover {
      color: ${WHITE};
    }
  `,
};

const logoUrl =
  "https://firebasestorage.googleapis.com/v0/b/zesty-48258.appspot.com/o/Logo.svg?alt=media&token=2d926a0b-cc72-4244-a480-e0494ef0123e";

const TopNav = () => {
  const [windowDimension, setWindowDimension] = useState<number>(
    window.innerWidth
  );
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);

  const drawerRef = useRef<any>(null);

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = (event: any) => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }

      setMobileMenu(false);
    };

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, []);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setMobileMenu(false);
      }
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 640;

  const { status, data: signinResult } = useSigninCheck();
  const auth = useAuth();

  const history = useHistory();
  const { pathname } = useLocation();

  const signOut = async () => {
    history.push({
      pathname: "/",
    });
    await auth.signOut();
  };

  if (status === "loading") {
    return <LoadingSmall />;
  }

  const { signedIn, user } = signinResult;

  if (signedIn === true) {
    return (
      <StyledHeader>
        {mobileMenu ? (
          <SideNav.Wrapper ref={drawerRef}>
            <SideNav.StyledLink
              $active={pathname === "/app" ? true : false}
              to="/app"
              onClick={() => setMobileMenu(false)}
            >
              All Recipes
            </SideNav.StyledLink>
            <SideNav.StyledLink
              $active={pathname === "/app/account" ? true : false}
              to="/app/account"
              onClick={() => setMobileMenu(false)}
            >
              My Recipes
            </SideNav.StyledLink>
            <SideNav.StyledLink
              $active={pathname === "/app/saved" ? true : false}
              to="/app/saved"
              onClick={() => setMobileMenu(false)}
            >
              Menus
            </SideNav.StyledLink>
            {/*<SideNav.StyledLink*/}
            {/*  $active={pathname === "/app/groups" ? true : false}*/}
            {/*  to="/app/groups"*/}
            {/*  onClick={() => setMobileMenu(false)}*/}
            {/*>*/}
            {/*  Groups*/}
            {/*</SideNav.StyledLink>*/}
          </SideNav.Wrapper>
        ) : null}
        <TopContainer>
          <LeftContainer>
            <Link to="/">
              <ReactSVG src={logoUrl} style={{ marginRight: "2em" }} />
            </Link>
            {!isMobile ? (
              <div>
                <StyledNavLink
                  $active={pathname === "/app" ? true : false}
                  to="/app"
                >
                  All Recipes
                </StyledNavLink>
                <StyledNavLink
                  $active={pathname === "/app/account" ? true : false}
                  to="/app/account"
                >
                  My Recipes
                </StyledNavLink>
                <StyledNavLink
                  $active={pathname === "/app/saved" ? true : false}
                  to="/app/saved"
                >
                  Menus
                </StyledNavLink>
                {/*<StyledNavLink*/}
                {/*  $active={pathname === "/app/groups" ? true : false}*/}
                {/*  to="/app/groups"*/}
                {/*>*/}
                {/*  Groups*/}
                {/*</StyledNavLink>*/}
              </div>
            ) : null}
          </LeftContainer>
          {isMobile ? (
            <>
              <FontAwesomeIcon
                icon={faGripLines}
                size={"lg"}
                color={DARK}
                onClick={() => setMobileMenu(!mobileMenu)}
              />
            </>
          ) : (
            <RightContainer>
              {user ? (
                <StyledProfileImage profileHighlightUrl={user.photoURL} />
              ) : null}
              <StyledCTA onClick={() => signOut()}>Sign Out</StyledCTA>
            </RightContainer>
          )}
        </TopContainer>
      </StyledHeader>
    );
  } else {
    return (
      <StyledHeader>
        <TopContainer>
          <Link to="/">
            <ReactSVG src={logoUrl} />
          </Link>
          <StyledLink to="/login">Log In</StyledLink>
        </TopContainer>
      </StyledHeader>
    );
  }
};

export default TopNav;
