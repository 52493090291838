//Algolia Globals
export const algoliaSearchDelay  = 1000;

// Drag and drop
export const ItemTypes = {
  RECIPE: "recipe",
};

//Image Resize Globals
export const imageWidth = 1000;
export const imageHeight = 1000;
export const format = "JPEG";
export const quality = 100;
export const save_folder_name = "Saved";



