import { doc } from "@firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import styled from "styled-components";

// Components
import Body from "../atoms/Body";
import LoadingSmall from "../atoms/LoadingSmall";

type Props = {
  id: string;
};

const CreatorBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin-right: 1.5em;
`;

const StyledProfileImage = styled.div.attrs((props: any) => ({
  profileHighlightUrl: props.profileHighlightUrl || null,
}))`
  background: ${(props) =>
    `url(${props.profileHighlightUrl}) no-repeat center center;`}
  background-size: 100%;
  height: 30px;
  min-width: 30px;
  border-radius: 30px;
  margin-right: 8px;
  margin-top: 8px;
`;

const CreatorRow = (props: Props) => {
  // React Fire User Record
  const userRef = doc(useFirestore(), "users", props.id);
  const { status, data } = useFirestoreDocData<any>(userRef);

  if (status === "loading") {
    return <LoadingSmall />;
  }

  return (
    <CreatorBlock>
      <StyledProfileImage profileHighlightUrl={data.profileUrl} />
      <Body>{data.name}</Body>
    </CreatorBlock>
  );
};

export default CreatorRow;
