import React, { useState, useEffect } from "react";

// Firebase
import { doc } from "@firebase/firestore";
import { useUser, useFirestore, useFirestoreDocData } from "reactfire";

// Components
import { Block, Columns } from "react-bulma-components";
import Button from "../../components/atoms/Button";
import Header from "../../components/atoms/Header";

import Group from "../../components/molecules/Group";

import CreateGroupModal from "./CreateGroupModal";
import {GroupsOnBoarding} from "../onboarding/groups/GroupsOnBoarding";

export default function MyGroups() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [groups, setGroups] = useState<Array<string>>([]);

  const firestore = useFirestore();
  const { data } = useUser();
  const userId = data!.uid;
  const userRef = doc(firestore, "users", userId);

  const { data: user } = useFirestoreDocData<any>(userRef);

  useEffect(() => {
    if (user) {
      if (user.joinedGroups) {
        let joinedGroups = user.joinedGroups;
        setGroups(joinedGroups);
      }
    }
  }, [data, user]);

  return (
    <Block>
        <GroupsOnBoarding run={true}/>
      <Block
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header margin={"0"} size={4}>
          My Groups
        </Header>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            setOpenModal(true)
          }
          primary
        >
          Start a new group
        </Button>
      </Block>
      <Columns>
        {groups.map((groupId: any) => (
          <Group id={groupId} key={groupId} />
        ))}
      </Columns>
      <CreateGroupModal
        onClose={() => setOpenModal(false)}
        onCreateGroup={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          setOpenModal(false)
        }
        openModal={openModal}
      />
    </Block>
  );
}
