import {Columns} from "react-bulma-components";
import Recipe from "./Recipe";
import Body from "../atoms/Body";
import {GREY} from "../../styles/colors";
import {connectHits, connectInfiniteHits} from "react-instantsearch-dom";
import React, {useEffect, useState} from "react";
import {save_folder_name} from "../../global";

type Props = {
    hits: any;
    hasMore: boolean;
    refineNext: () => void;
    onUserClick: (username: string) => void;
    onTagClick: (tag: string) => void;
    onSave: (id: string) => void;
    onShare: (id: string) => void;
    folderName: string;
    folderFilterList?: any;
    filter?: any;
    onRemoveFromFolder: (id: string) => void;
};

const HitsPublic = (props: Props) => {

    const [hits, setHits] = useState<object[]>([]);
    const [mode, setMode] = useState<string | undefined>("");
    let sentinel: any = null;

    useEffect(() => {
        setMode("search");
        const onSentinelIntersection = (entries: any) => {
            const hasMore = props.hasMore;
            const refineNext = props.refineNext;
            if (mode === "search") {
                entries.forEach((entry: any) => {
                    if (entry.isIntersecting && hasMore) {
                        refineNext();
                    }
                });
                setHits(props.hits);
                // console.log("logging table of hits")
                // console.table(hits)
                // console.log("folder filter list")
                // console.log(props.folderFilterList)
                // console.log("filter")
                // console.log(props.filter)


            }
        };
        if (mode === "search") {
            let observer = new IntersectionObserver(onSentinelIntersection);
            observer.observe(sentinel);
            return () => {
                observer.disconnect();
            };
        }
    }, [
        props.hasMore,
        props.hits,
        props.refineNext,
        sentinel,
        mode,
    ]);


    const onTagClick = (tag: string) => {
        props.onTagClick(tag);
        document.body.scrollTop = document.documentElement.scrollTop = 125;
    };

    const onUserClick = (userName: string) => {
        props.onUserClick(userName);
        document.body.scrollTop = document.documentElement.scrollTop = 125;
    };

    const onSave = (id: string) => {
        props.onSave(id);
    };

    const onShare = (id: string) => {
        props.onShare(id);
    };

    return props.filter != null ? (
        <Columns>
            {props.hits.map((recipe: any) =>
                //filter for "All"
                props.folderFilterList.includes(recipe.objectID) && props.filter === "All" ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}

                    />
                    //filter for All Saved (public + private)
                ) : props.folderFilterList.includes(recipe.objectID) && props.filter === save_folder_name ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== save_folder_name}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}
                    />
                    //filter for Public
                ) : recipe.completed && !recipe.private && props.folderFilterList.includes(recipe.objectID) && props.filter === "Public" ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}
                    />
                    //filter for private
                ) : recipe.completed && recipe.private && props.folderFilterList.includes(recipe.objectID) && props.filter === "Private" ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}

                    />
                    //filter for drafts
                ) : !recipe.completed && recipe.private && props.folderFilterList.includes(recipe.objectID) && props.filter === "Drafts" ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}

                    />
                ) : null
            )}
            <div
                className="ais-InfiniteHits-sentinel"
                ref={(c) => (sentinel = c)}
            />
        </Columns>
    ) : (props.hits.filter((item: any) => item.completed)
        .length > 0) && props.folderFilterList.length > 0 ? (
        <Columns>
            {props.hits.map((recipe: any) =>
                recipe.completed && props.folderFilterList.includes(recipe.objectID) ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}

                    />
                ) : null
            )}
        </Columns>
    ) : ((props.hits.filter((item: any) => item.completed && !item.private)
        .length > 0) && props.folderFilterList.length === 0 ? (
        <Columns>
            {props.hits.map((recipe: any) =>
                recipe.completed && !recipe.private ? (
                    <Recipe
                        onTagClick={(tag: string) => onTagClick(tag)}
                        onUserClick={(userName: string) => onUserClick(userName)}
                        id={recipe.objectID}
                        key={recipe.objectID}
                        onSave={(id: string) => onSave(id)}
                        onShare={(id: string) => onShare(id)}
                        folderView={props.folderName !== "My Recipes"}
                        onRemoveFromFolder={(id: string) => props.onRemoveFromFolder(id)}

                    />
                ) : null
            )}
        </Columns>
    ) : (
        <Body margin={"0 0 0 0"} color={GREY}>
            No public recipes yet
        </Body>
    ));
};
export default connectInfiniteHits(HitsPublic);
