import {Section} from 'react-bulma-components';

const Footer = () => {
  return(
    <Section textAlign='center'>
      Zesty © 2021
    </Section>
  )
}

export default Footer;