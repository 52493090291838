import React from 'react';
import styled from 'styled-components';

// Styles
import {GREY, LIGHT_YELLOW, YELLOW} from '../../styles/colors';

type Props = {
  children: any,
  onClick?: () => void;
}

const StyledTag = styled.div<Props>`
  background-color: ${LIGHT_YELLOW};
  border: 1px solid ${YELLOW};
  border-radius: 5px;
  color: ${GREY};
  font-family: 'Inter';
  padding: 4px 8px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.85em;
  :hover{
    opacity: .75;
    cursor: pointer;
  }
`

export default function Button(props: Props) {
  return (
    <StyledTag onClick={props.onClick}>
      {props.children}
    </StyledTag>
  )
}
