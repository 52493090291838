// Firebase
import { useSigninCheck } from "reactfire";
import { Switch } from "react-router-dom";
import { RouteWithSubRoutes } from "../route-config";

// Styles and Components
import { Section, Container } from "react-bulma-components";

// Atoms
import Loading from "../components/atoms/Loading";

import Login from "./login";

export default function Account({ routes }: any) {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (status === "loading") {
    return <Loading />;
  }

  if (signInCheckResult.signedIn === true) {
    return (
      <Section>
        <Container>
          <Switch>
            {routes.map((route: any, i: number) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Container>
      </Section>
    );
  } else {
    return <Login />;
  }
}
