import React, {useEffect, useState} from "react";
import Joyride, {CallBackProps} from "react-joyride";
import {RED} from "../../../styles/colors";
//firestore
import {useFirestore, useFirestoreDocData, useUser} from "reactfire";
import {
    doc,
    updateDoc,
} from "@firebase/firestore";
type Props = {
    run: boolean;
};

export const GroupsShareOnBoarding = (props: Props) => {
    const [steps, setSteps] = useState(
        [
            {
                target: '#root',
                content: 'You can share any recipe that you have personally created, liked, or saved, along with a comment. One nice thing about groups is that you can share private recipes that you are not ready to share publicly, such as a secret family recipe',
                disableBeacon: true,
                placement: 'center' as 'center',

            },
            {
                target: '#root',
                content: 'Once you post the recipe, you will see it show up under posts, along with the date it was created!',
                disableBeacon: true,
                placement: 'center' as 'center',
            },
        ]
    )

    const [onBoarding, setOnBoarding] = useState<boolean>(false);

    // Firebase
    const firestore = useFirestore();
    const { data } = useUser();
    const userId = data!.uid;
    const userRef = doc(firestore, "users", userId);
    const { data: user } = useFirestoreDocData<any>(userRef);

    useEffect(() => {
        if (user) {
            if (user.onBoarding) {
                let onBoardingStatus = user.onBoarding.groupsShare;
                setOnBoarding(onBoardingStatus)
            }else{
                setOnBoarding(true);
            }
        }
    }, [data, user]);


    const handleJoyrideCallback = (callback: CallBackProps) => {
        if (callback.action ===  'skip' || callback.action === 'reset') {
            setOnBoardingStatus().then(r => console.log(r))
        }
    };

    const setOnBoardingStatus = async () => {
        try {
            await updateDoc(userRef, {
                    "onBoarding.groupsShare": false,
                },
            );
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <Joyride
            callback={(callback) => handleJoyrideCallback(callback) }
            run={onBoarding}
            steps={steps}
            showSkipButton={true}
            continuous={true}
            showProgress={false}
            scrollToFirstStep={false}
            disableOverlay={false}
            styles={{
                options: {
                    primaryColor: RED,
                    zIndex: 10000,
                },
            }}
        />
    );
}
