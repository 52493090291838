import styled from 'styled-components';
import {Form} from 'react-bulma-components';

import {GREY, GREEN_INPUT_BORDER, LIGHT_GREEN} from '../../styles/colors';

type Props = {
  value: any,
  placeholder: string,
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
}

const StyledTextarea = styled(Form.Textarea)`
  font-family: Inter;
  background-color: ${LIGHT_GREEN};
  border-radius: 10px;
  height: 55px;
  border: 1px solid ${GREEN_INPUT_BORDER};
  color: ${GREY};
`

export default function Input(props: Props) {
  return (
    <StyledTextarea value={props.value} placeholder={props.placeholder} onChange={props.onChange} />
  )
}
