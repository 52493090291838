import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Firebase
import { useStorage } from "reactfire";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";

// Components
import { Block, Modal, Columns } from "react-bulma-components";

// Atoms
import Body from "../atoms/Body";
import Header from "../atoms/Header";
import Button from "../atoms/Button";
import LoadingSmall from "../atoms/LoadingSmall";
import StepNotes from "../atoms/StepNotes";
import Dropzone from "../atoms/Dropzone";

// Molecules
import FormTextarea from "../molecules/FormTextarea";
import IngredientRow from "../molecules/IngredientRow";
import FormField from "../molecules/FormField";

// Styles
import { RED, DARK_GREEN, DARK } from "../../styles/colors";
import ImageCrop from "./ImageCrop";
import { optimizeImage } from "../../utilities";

type Props = {
  recipeId: string;
  ingredients: Array<any>;
  openModal: boolean;
  onClose: () => void;
  addStep: (shownIngredients: Array<any>, stepObject: any) => void;
};

// Custom Components
const ButtonContainer = styled(Block)`
  display: flex;
  justify-content: space-between;
`;

const StyledIngredientsBlock = styled.div`
  border: 1px solid ${DARK};
  padding: 16px;
  border-radius: 1em;
  margin-bottom: 1em;
`;

const AddStepModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [recipeId, setRecipeId] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [notes, setNotes] = useState<Array<string>>([]);
  const [image, setImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [shownIngredients, setShownIngredients] = useState<Array<any>>([]);
  const [ingredients, setIngredients] = useState<Array<any>>([]);

  useEffect(() => {
    setRecipeId(props.recipeId);
    setShownIngredients(props.ingredients);
  }, [props.ingredients, props.recipeId]);

  // Initialize firestore storage
  const storage = useStorage();

  // Clear State
  const clearState = () => {
    setLoading(false);
    setRecipeId("");
    setDescription("");
    setImage(null);
    setImageUrl("");
    setIngredients([]);
  };

  // On image drop to drop zone
  const changeImage = (image: any, imageUrl: string) => {
    setImage(image);
    setImageUrl(imageUrl);
  };

  // Select an ingredient to use within the step
  const onIngredientSelect = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let ingredientsArray = [...ingredients];
    let ingredient = shownIngredients[i];
    ingredientsArray.push(ingredient);
    setIngredients(ingredientsArray);

    let shownIngredientsArray = [...shownIngredients];
    shownIngredientsArray.splice(i, 1);
    setShownIngredients(shownIngredientsArray);
  };

  // Delete an ingredient from the step
  const onStepIngredientDelete = (
    e: React.MouseEvent<SVGSVGElement>,
    i: number
  ) => {
    e.preventDefault();

    let shownIngredientsArray = [...shownIngredients];
    let ingredient = ingredients[i];
    shownIngredientsArray.push(ingredient);
    setShownIngredients(shownIngredientsArray);

    let ingredientsArray = [...ingredients];
    ingredientsArray.splice(i, 1);
    setIngredients(ingredientsArray);
  };

  // Upload Step Image image to Firebase storage
  const uploadRecipeImage = async () => {
    // Set loading true
    setLoading(true);

    const ext = image.type.substr(image.type.lastIndexOf("/") + 1);

    const stepImageUUID = uuidv4();

    // Initialize storage reference
    const storageRef = ref(
      storage,
      `recipes/${recipeId}/${stepImageUUID}.${ext}`
    );
    try {
      let imageTemp: any = null;
      await optimizeImage(image).then((result: any) => {
        imageTemp = result;
      });
      await uploadBytes(storageRef, imageTemp);
      let url = await getDownloadURL(storageRef);
      return url;
    } catch (e) {
      alert(e);
      return "";
    }
  };

  // Add Tips
  const onAddTip = (e: any) => {
    e.preventDefault();

    const notesArray = [...notes];
    notesArray.push(note);
    setNotes(notesArray);
    setNote("");
  };

  // Handle the tip input, add tip when the user hits "enter"
  const addTip = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onAddTip(e);
    } else {
      setNote(e.target.value);
    }
  };

  // Delete a note that was previously added
  const onDeleteNote = (i: number) => {
    let notesArray = [...notes];
    notesArray.splice(i, 1);
    setNotes(notesArray);
  };

  // Confirming adding a new step
  const onAddStep = async () => {
    // Set image url as current state
    let newUrl = "";

    // Check if user has changed the image for the step
    if (image != null) {
      newUrl = await uploadRecipeImage();
    }

    let stepObject = {
      description: description,
      imageUrl: newUrl,
      ingredients: ingredients,
      notes: notes,
    };

    clearState();
    props.addStep(shownIngredients, stepObject);
  };

  return (
    <Modal
      show={props.openModal}
      onClose={() => {
        props.onClose();
      }}
    >
      <Modal.Card>
        <Modal.Card.Body style={{ padding: "1.5em", borderRadius: 20 }}>
          <Header margin={"0 0 0.5em 0"} size={4}>
            Add new step
          </Header>
          <FormTextarea
            label="Step description"
            value={description}
            placeholder="The description of each step should be written as clearly as possible and should identify specific actions associated with the recipe. We recommend that you create separate steps based on timing and actions. For instance, here is the first step of a mushroom omelette. Step 1: heat the skillet on medium heat and then add butter. Once the butter has fully melted add chopped mushrooms and minced garlic and saute for 4-5 mins stirring frequently until cooked. Take off heat and let sit."
            onChange={(e: any) => setDescription(e.target.value)}
          />
          <FormField
            label="Any helpful tips you want to add?"
            text={`Make sure to press enter to add the tip.`}
            value={note}
            placeholder="Add any helpful tip you want the viewer to know about"
            onKeyDown={(e: any) => addTip(e)}
            onChange={(e: any) => addTip(e)}
          />
          {notes.length > 0 ? (
            <StepNotes
              onDelete={(i: number) => onDeleteNote(i)}
              deletable
              notes={notes}
            />
          ) : null}
          <Body bold margin={"1em 0 0.5em 0"} color={DARK_GREEN}>
            Add an image for the step
          </Body>
          <Body margin={"0.5em 0"} color={RED}>
            *Optional
          </Body>
          <Dropzone
            onFileAccept={(image: any, imageUrl: string) => {
              changeImage(image, imageUrl);
            }}
          />
          {imageUrl ? (
            <ImageCrop
              cropWidth={75}
              imageUrl={imageUrl}
              onFinishedCropping={(croppedFile: any) => {
                setImage(croppedFile);
              }}
            />
          ) : null}
          <Columns>
            <Columns.Column size="half">
              <Body margin={"0 0 1em 0"} color={DARK}>
                Available ingredients
              </Body>
              {shownIngredients.length > 0 ? (
                <StyledIngredientsBlock>
                  {shownIngredients.map((ingredient: any, i: number) => (
                    <IngredientRow
                      key={i}
                      onSelect={(e: React.MouseEvent<SVGSVGElement>) =>
                        onIngredientSelect(e, i)
                      }
                      ingredient={ingredient}
                      selectable
                    />
                  ))}
                </StyledIngredientsBlock>
              ) : null}
            </Columns.Column>
            <Columns.Column>
              <Body margin={"0 0 1em 0"} color={DARK}>
                Ingredients needed in this step
              </Body>
              {ingredients.map((ingredient: any, i: number) => (
                <IngredientRow
                  key={i}
                  onDelete={(e: React.MouseEvent<SVGSVGElement>) =>
                    onStepIngredientDelete(e, i)
                  }
                  ingredient={ingredient}
                  deletable
                />
              ))}
            </Columns.Column>
          </Columns>
          <ButtonContainer>
            <Button onClick={props.onClose}>Cancel</Button>
            {loading ? (
              <LoadingSmall />
            ) : (
              <Button
                disabled={description === "" ? true : false}
                primary
                onClick={() => onAddStep()}
              >
                Save
              </Button>
            )}
          </ButtonContainer>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};

export default AddStepModal;
