// React router
import { Switch } from "react-router-dom";
import { RouteWithSubRoutes } from "../../route-config";

export default function RecipeCreateSwitch({ routes }: any) {
  return (
    <>
      <Switch>
        {routes.map((route: any, i: number) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  );
}
