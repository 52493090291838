// React router
import { useSigninCheck } from "reactfire";

import RecipeGuestView from "./RecipeGuestView";
import RecipeView from "./RecipeView";

import Loading from "../../components/atoms/Loading";

export default function RecipeEditSwitch() {
  const { status, data: signinResult } = useSigninCheck();

  if (status === "loading") {
    return <Loading />;
  }

  const { signedIn } = signinResult;

  if (signedIn === true) {
    return <RecipeView />;
  } else {
    return <RecipeGuestView />;
  }
}
