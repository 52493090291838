import { doc } from "@firebase/firestore";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";

// Atoms
import Body from "../atoms/Body";
import LoadingSmall from "../atoms/LoadingSmall";

// Styes
import { DARK_GREEN, GREY } from "../../styles/colors";

type Props = {
  id: string;
  onClick: (e: React.MouseEvent<SVGSVGElement>) => void;
  active: boolean;
};

const GroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
`;

const GroupSelect = (props: Props) => {
  const groupRef = doc(useFirestore(), "groups", props.id);
  const { status, data } = useFirestoreDocDataOnce<any>(groupRef);

  if (status === "loading") {
    return <LoadingSmall />;
  }

  return (
    <GroupContainer>
      <Body>{data.name}</Body>
      {props.active ? (
        <FontAwesomeIcon
          onClick={props.onClick}
          color={DARK_GREEN}
          size={"lg"}
          icon={faDotCircle}
        />
      ) : (
        <FontAwesomeIcon
          onClick={props.onClick}
          color={GREY}
          size={"lg"}
          icon={faCircle}
        />
      )}
    </GroupContainer>
  );
};

export default GroupSelect;
